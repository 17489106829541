import { GlobalContext } from "components/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { formatDate } from "utilities/StringUtility";
import IFileBackupRecord, { IFileBackupRecordCreateOrUpdate } from "models/sql/IFileBackupRecord";
import FileBackupRecordService from "services/FileBackupRecordService";
import { downloadFile } from "utilities/BrowserUtility";
import { getBaseDomain } from "utilities/MiscUtilities";

interface IPurapikOrdersProps {
}

const PurapikOrdersLookup = (props: IPurapikOrdersProps) => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [fileUploadRecords, setFileUploadRecords] = useState<IFileBackupRecord[]>();
    const [accessToken, setAccessToken] = useState("");
    const [refresh, setRefresh] = useState<Date>(new Date());

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);
                const accessTokenResult = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(accessTokenResult);

                var result =
                    await FileBackupRecordService.GetAllAsync(
                        accessTokenResult,
                        0,
                        25,
                        {
                            types: ["PurapikOrders"],
                            sortBys: ["UploadDate"],
                            sortDescending: true
                        });

                if (result.records && result.records.length > 0) {
                    setFileUploadRecords(result.records);
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [refresh]);

    const markAsImportedAsync = async (entry: IFileBackupRecord) => {
        let updateEntry = fileUploadRecords?.find(e => e.fileId === entry.fileId);

        if (updateEntry) {
            globalContext.setLoading(true);
            const data: IFileBackupRecordCreateOrUpdate = {
                extraDataJSON: JSON.stringify({
                    imported: true,
                    importDate: formatDate(new Date()),
                    importedBy: globalContext.userName
                }),
                fileId: updateEntry.fileId,
                fileName: updateEntry.fileName,
                type: updateEntry.type,
                uploadDate: updateEntry.uploadDate,
                blobFileName: updateEntry.blobFileName
            };

            await FileBackupRecordService.CreateOrUpdateAsync(accessToken, data);
            globalContext.setLoading(false);
            setRefresh(new Date());
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <h1>Last 100 Uploaded files</h1>
                    <Table
                        striped
                        bordered
                        hover
                        size="sm"
                        className="lookup-table">
                        <thead>
                            <tr>
                                <th>Upload Time</th>
                                <th>File name</th>
                                <th>Status</th>
                                <th>Import Date</th>
                                <th>Imported By</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (fileUploadRecords || [])
                                    .map(
                                        (f, index) => {
                                            let imported = false;
                                            let importDate = "";
                                            let importedBy = "";

                                            try {
                                                if (f.extraDataJSON) {
                                                    const parsedData = JSON.parse(f.extraDataJSON);

                                                    if (parsedData.hasOwnProperty("imported")) {
                                                        imported = parsedData.imported;
                                                    }

                                                    if (parsedData.hasOwnProperty("importDate")) {
                                                        importDate = formatDate(parsedData.importDate);
                                                    }

                                                    if (parsedData.hasOwnProperty("importedBy")) {
                                                        importedBy = parsedData.importedBy;
                                                    }
                                                }
                                            }
                                            catch {

                                            }

                                            return (
                                                <tr key={index}>
                                                    <td>{formatDate(f.uploadDate)}</td>
                                                    <td>{f.fileName}</td>
                                                    <td>{imported ? <span style={{ color: "green", fontWeight: "bold" }}>IMPORTED</span> : <span>New</span>}</td>
                                                    <td>{importDate}</td>
                                                    <td>{importedBy}</td>
                                                    <td>
                                                        {
                                                            !imported &&
                                                            <Button
                                                                className="me-2"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    markAsImportedAsync(f);
                                                                }}>Mark as Imported</Button>
                                                        }
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                downloadFile(
                                                                    f.fileName,
                                                                    `///${getBaseDomain()}/api/FileBackupRecord/downloadFile?fileName=${f.blobFileName.split("/").splice(-1)[0]}`,
                                                                    accessToken
                                                                );

                                                            }}>Download</Button>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

export default PurapikOrdersLookup;
