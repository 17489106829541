/*
change 'closed' to status and allow multiple statuses like received, in process, closed, issue

need a public page that offers links to all public requests

add a setting to templates to remove the request from the listing page

when a form is created, make it so it's editable on our end. 
there should be a field so we can say who gets emailed when this form is submitted. 
if we want to do an r&d production request, maria would get the email
*/

import { GlobalContext } from 'components/GlobalContext';
import { ILabRequestEntryInitResult } from 'models/ILabRequestEntryInitResult';
import { useContext, useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LabRequestEntryService from 'services/LabRequestEntryService';
import LabRequestEntryChooseForm from './ChooseForm';
import LabRequestEntryDetail from './Detail';
import LabRequestEntrySubmissions from './Submissions';

import './styles/LabRequestEntry.scss';

const LabRequestEntryIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [baseData, setBaseData] = useState<ILabRequestEntryInitResult | undefined>();

    useEffect(() => {
        (async () => {
            globalContextRef.current.setLoading(true);
            const accessToken = await globalContextRef.current.getAccessTokenAsync();
            const result = await LabRequestEntryService.GetInitAsync(accessToken);

            if (result) {
                setBaseData({
                    ...result
                });
            }

            globalContextRef.current.setLoading(false);
        })();
    }, []);

    if (!baseData) {
        return (
            <>
            </>
        );
    }

    return (
        <>
            <div>
                <Routes>
                    <Route path={"Detail/:id"} element={<LabRequestEntryDetail baseData={baseData} />} />
                    <Route path={"Form/:formNumber/:page"} element={<LabRequestEntrySubmissions baseData={baseData} />} />
                    <Route path={"ChooseForm/:page"} element={<LabRequestEntryChooseForm baseData={baseData} />} />
                    <Route path={"ChooseForm"} element={<LabRequestEntryChooseForm baseData={baseData} />} />
                    <Route path={"/*"} element={<LabRequestEntryChooseForm baseData={baseData} />} />
                </Routes>
            </div>
        </>
    );
}

export default LabRequestEntryIndex;
