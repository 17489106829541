export interface ISelectOption {
    value: string,
    title: string
}

export enum DataType {
    String = 1,
    Number = 2,
    Boolean = 3,
    Date = 4
}