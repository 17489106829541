import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "components/GlobalContext";
import PanelService from "services/PanelService";
import IPanel from "models/sql/IPanel";
import { IPanelInitResult } from "models/IPanelInitResult";
import IPanelAquisition from "models/sql/IPanelAquisition";
import PanelAquisitionService from "services/PanelAquisitionService";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import IESynergySalesRep from "models/sql/IESynergySalesRep";
import { formatDate } from "utilities/StringUtility";

import "react-datepicker/dist/react-datepicker.css";

interface IPanelLookupProps {
    panelBaseData?: IPanelInitResult;
}

const CCCPanelLookupDetail = (props: IPanelLookupProps) => {
    const globalContext = useContext(GlobalContext);
    const { panelId }: any = useParams();
    const [panel, setPanel] = useState<IPanel>();
    const [salesPerson, setSalesPerson] = useState<IESynergySalesRep>();
    const [panelAquisition, setPanelAquisition] = useState<IPanelAquisition>();
    const [requiresSaving, setRequiresSaving] = useState(false);
    const navigate = useNavigate();
    const [editSales, setEditSales] = useState(false);
    const [editCustomer, setEditCustomer] = useState(false);
    const [editPanel, setEditPanel] = useState(false);

    const openImageInNewTab = (imageData: string) => {
        var image = new Image();
        image.src = imageData;
        const w = window.open("");

        if (w) {
            w.document.write(image.outerHTML);
        }
    };

    const saleToShipDays =
        panel && panel.couponOrder && panel.couponOrder.salesDate && panel.dateShipped ?
            Math.floor(moment.duration(moment(panel.dateShipped).diff(moment(panel.couponOrder.salesDate))).asDays()) :
            0;

    const shipToPlaceDays =
        panel && panel.dateShipped && panel.placedInServiceDate ?
            Math.floor(moment.duration(moment(panel.placedInServiceDate).diff(moment(panel.dateShipped))).asDays()) :
            0;
    const placedToRemovedDays =
        panel && panel.placedInServiceDate && panel.removedFromServiceDate ?
            Math.floor(moment.duration(moment(panel.removedFromServiceDate).diff(moment(panel.placedInServiceDate))).asDays()) :
            0;
    const removedToRecievedDays =
        panel && panel.removedFromServiceDate && panel.dateReceived ?
            Math.floor(moment.duration(moment(panel.dateReceived).diff(moment(panel.removedFromServiceDate))).asDays()) :
            0;
    const recievedToReportedDays =
        panel && panel.dateReceived && panel.reportDate ?
            Math.floor(moment.duration(moment(panel.reportDate).diff(moment(panel.dateReceived))).asDays()) :
            0;
    const totalTimelineDays =
        panel && panel.reportDate ?
            saleToShipDays + shipToPlaceDays + placedToRemovedDays + removedToRecievedDays + recievedToReportedDays + recievedToReportedDays :
            0;

    useEffect(
        () => {
            (async () => {
                globalContext.setLoading(true);

                const accessToken = await globalContext.getAccessTokenAsync();
                const panelResult = await PanelService.GetAsync(accessToken, panelId);

                if (panelResult.record) {
                    setPanel(panelResult.record);
                    const panelAquisition = await PanelAquisitionService.LookupByPanelIdAsync(accessToken, panelResult.record.panelId);

                    if (panelResult.record2) {
                        setSalesPerson(panelResult.record2);
                        console.log(panelResult);
                    }

                    if (panelAquisition) {
                        setPanelAquisition(panelAquisition);
                    }
                }
                else {
                    globalContext.showErrorMessage("Unable to load Panel data");
                }

                globalContext.setLoading(false);
            })();
        }, [panelId]);

    const customDateSpan = ({ value, onClick }: { value: string; onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) => (
        <span onClick={onClick} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{formatDate(new Date(value))}</span>
    );

    if (panel) {
        return (
            <>
                <h1 style={{ padding: "15px", textAlign: "center" }}><strong>Coupon #{panel.panelId}{parseInt(panel.classValue) === 4 ? " (GX)" : parseInt(panel.classValue) > 0 ? " (G" + panel.classValue + ")" : ""}</strong></h1>
                <Row>
                    <Col md={3} style={{ paddingRight: "0" }}>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Timeline</strong>
                                </Card.Title>
                                <Row>
                                    <Col sm={4} md={12}>
                                        <Row>
                                            <Col xl={6}>
                                                <strong>Sale</strong>
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    className="form-control edit-field"
                                                    selected={panel.couponOrder.salesDate ? new Date(panel.couponOrder.salesDate) : null}
                                                    onChange={() => { }}
                                                    customInput={React.createElement(customDateSpan)}

                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={12}>
                                        <Row>
                                            <Col xl={6}>
                                                <strong>Shipped{saleToShipDays > 0 && <i> (+{saleToShipDays})</i>}</strong>
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={panel.dateShipped != null ? new Date(panel.dateShipped) : null}
                                                    onChange={() => { }}
                                                    customInput={React.createElement(customDateSpan)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={12}>
                                        <Row>
                                            <Col xl={6}>
                                                <strong>Placed{shipToPlaceDays > 0 && <i> (+{shipToPlaceDays})</i>}</strong>
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={panel.placedInServiceDate ? new Date(panel.placedInServiceDate) : null}
                                                    customInput={React.createElement(customDateSpan)}
                                                    onChange={() => { }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={12}>
                                        <Row>
                                            <Col xl={6}>
                                                <strong>Removed{placedToRemovedDays > 0 && <i> (+{placedToRemovedDays})</i>}</strong>
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={panel.removedFromServiceDate ? new Date(panel.removedFromServiceDate) : null}
                                                    customInput={React.createElement(customDateSpan)}
                                                    onChange={() => { }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={12}>
                                        <Row>
                                            <Col xl={6}>
                                                <strong>Received{removedToRecievedDays > 0 && <i> (+{removedToRecievedDays})</i>}</strong>
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={panel.dateReceived ? new Date(panel.dateReceived) : null}
                                                    customInput={React.createElement(customDateSpan)}
                                                    onChange={() => { }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={12}>
                                        <Row>
                                            <Col xl={6}>
                                                <strong>Reported{recievedToReportedDays > 0 && <i> (+{recievedToReportedDays})</i>}</strong>
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={panel.reportDate ? new Date(panel.reportDate) : null}
                                                    customInput={React.createElement(customDateSpan)}
                                                    onChange={() => { }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    totalTimelineDays > 0 &&
                                    <Row className="text-center p-2">
                                        <h5><strong>Total Day(s): {totalTimelineDays}</strong></h5>
                                    </Row>
                                }
                                <Row>
                                    <Col className="text-center">
                                        <h3>Images</h3>
                                        {
                                            panel.panelImages.map(pi => <img className="p-2" style={{ width: "100%", cursor: "pointer", maxWidth: "200px" }} src={"data:image/jpeg;base64," + pi.imageData} onClick={() => { openImageInNewTab("data:image/jpeg;base64," + pi.imageData); }} />)
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={`${editSales ? "editing" : ""}`}>
                            <Card.Body>
                                <Card.Title>
                                    <strong>Sale</strong>
                                    {
                                        editSales &&
                                        <>
                                            <Button className="float-end ms-2" variant="danger" size="sm" onClick={() => setEditSales(false)}>Cancel</Button>
                                            <Button className="float-end" variant="success" size="sm" onClick={() => setEditSales(false)}>Save</Button>
                                        </>
                                    }
                                </Card.Title>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Sales Order</Form.Label>
                                            {
                                                !editSales ?
                                                    <div className="form-value editable" onClick={() => setEditSales(true)}><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{""}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={""}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Sales Person</Form.Label>
                                            {
                                                !editSales ?
                                                    <div onClick={() => setEditSales(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{""}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={""}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Reseller</Form.Label>
                                            {
                                                !editSales ?
                                                    <div onClick={() => setEditSales(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{""}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={""}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Branding</Form.Label>
                                            {
                                                !editSales ?
                                                    <div onClick={() => setEditSales(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.brandingCompany.translationPrefix}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.brandingCompany.translationPrefix}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Part Number</Form.Label>
                                            {
                                                !editSales ?
                                                    <div onClick={() => setEditSales(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.couponPartNumber}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.couponPartNumber}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className={`mt-2 ${editCustomer ? "editing" : ""}`}>
                            <Card.Body>
                                <Card.Title>
                                    <strong>Customer</strong>
                                    {
                                        editCustomer &&
                                        <>
                                            <Button className="float-end ms-2" variant="danger" size="sm" onClick={() => setEditCustomer(false)}>Cancel</Button>
                                            <Button className="float-end" variant="success" size="sm" onClick={() => setEditCustomer(false)}>Save</Button>
                                        </>
                                    }
                                </Card.Title>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Serial No</Form.Label>
                                            {
                                                !editCustomer ?
                                                    <div onClick={() => setEditCustomer(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.serialNo}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.serialNo}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>End User</Form.Label>
                                            {
                                                !editCustomer ?
                                                    <div onClick={() => setEditCustomer(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.endUser}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.endUser}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Company</Form.Label>
                                            {
                                                !editCustomer ?
                                                    <div onClick={() => setEditCustomer(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{""}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={""}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Location</Form.Label>
                                            {
                                                !editCustomer ?
                                                    <div onClick={() => setEditCustomer(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.location}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.location}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Address</Form.Label>
                                            {
                                                !editCustomer ?
                                                    <div onClick={() => setEditCustomer(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.address}</div> :
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.address}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className={`mt-2 ${editPanel ? "editing" : ""}`}>
                            <Card.Body>
                                <Card.Title>
                                    <strong>Panel</strong>
                                    {panel.emailLink && <> (<a href={panel.emailLink} target="_blank">Public Report</a>)</>}
                                    {
                                        editPanel &&
                                        <>
                                            <Button className="float-end ms-2" variant="danger" size="sm" onClick={() => setEditPanel(false)}>Cancel</Button>
                                            <Button className="float-end" variant="success" size="sm" onClick={() => setEditPanel(false)}>Save</Button>
                                        </>
                                    }
                                </Card.Title>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Panel Status</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{props.panelBaseData?.panelStatuses[panel.panelStatusId]}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={props.panelBaseData?.panelStatuses[panel.panelStatusId]}
                                                    />
                                            }

                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Days in Service</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.daysInService}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.daysInService}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>ChartSpeed</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.chartSpeed}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.chartSpeed}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Cu2S</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.cu2S}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.cu2S}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Cu2O</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.cu2O}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.cu2O}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>CuUnknown</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.cuUnknown}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.cuUnknown}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>AgCl</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.agCl}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.agCl}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Ag2S</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.ag2S}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.ag2S}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>AgUnknown</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.agUnknown}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.agUnknown}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>NOxPresent</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.noxPresent ? "True" : "False"}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.noxPresent ? "True" : "False"}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Gold Coupon</Form.Label>
                                            {
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{panel.goldCoupon}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={panel.goldCoupon}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Calculation</Form.Label>{
                                                !editPanel ?
                                                    <div onClick={() => setEditPanel(true)} className="form-value editable" ><FontAwesomeIcon icon={faPenToSquare as IconProp} />&nbsp;{props.panelBaseData?.calculationTypes.find(c => c.id === panel.calculationTypeId)?.name}</div> :
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                //setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                            }
                                                        }
                                                        value={props.panelBaseData?.calculationTypes.find(c => c.id === panel.calculationTypeId)?.name}
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {
                            <Card className={`mt-2 ${!panelAquisition ? "no-aquisition" : panelAquisition.approvalDate ? "aquisition-approved" : "aquisition"}`}>
                                <Card.Body>
                                    <Card.Title>
                                        <strong>Aquisition Data</strong>
                                    </Card.Title>
                                    <Row>
                                        {
                                            panelAquisition &&
                                            <>
                                                <h3>
                                                    {
                                                        panelAquisition.approvalDate ?
                                                            <>Approved</> :
                                                            <>NOT YET APPROVED</>
                                                    }
                                                </h3>
                                                <Button
                                                    style={{ width: "100%", height: "100px" }}
                                                    onClick={
                                                        () => {
                                                            navigate(`/CCCPanelAquisitionLookup/Detail/${panelAquisition.panelAquisitionId}`);
                                                        }}>Approve / Edit</Button>
                                            </>
                                        }
                                        {
                                            !panelAquisition &&
                                            <h3>
                                                No digital aquisition data available.<br />
                                                Please enter values manually above.
                                            </h3>
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                        }
                    </Col>
                </Row>
            </>
        );
    }
    else {
        return (
            <>

            </>
        );
    }
}

export default CCCPanelLookupDetail;
