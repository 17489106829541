import { GlobalContext } from "components/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import PanelAquisitionService from "services/PanelAquisitionService";
import { getStripBaseMaterialNameFromType, getStripMaterialNameFromType, getStripSizeNameFromType, IKioskSceneBaseProps, ITestData } from "../Index";
import useLocalStorage from 'react-use-localstorage';

const convertTestData = (data?: ITestData): string => {
    const result = [];
    if (data && data.strip1 && data.strip1.length > 0) {
        for (var i = 0; i < data?.strip1.length; i++) {
            result.push({
                t: data?.strip1[i].time,
                v1: data?.strip1[i].value,
                v2: data?.strip2[i].value
            })
        }
    }
    return JSON.stringify(result);
};

const Complete = (props: IKioskSceneBaseProps) => {
    const globalContext = useContext(GlobalContext);
    const [submitted, setSubmitted] = useState(false);
    const [submissionData, setSubmissionData] = useLocalStorage('submissionData', JSON.stringify({}));
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(
        () => {
            if (props.testData && props.testData.strip1 && props.testData.strip1.length > 0) {
                submitAsync();
            }
        }, []);

    const submitAsync = async () => {
        setSubmitted(false);
        setErrorMessage("");

        const newData: any = {
            PanelImage: props.photo ? props.photo : "",
            ResultJSON: convertTestData(props.testData),
            Strip1MaterialType: props.strip1MaterialType,
            Strip2MaterialType: props.strip2MaterialType,
            Date: new Date().toISOString(),
            OrderNumber: 0,
            PanelId: parseInt(props.couponNumber),
            Notes: "",
            Overrides: "",
            Strip1SizeType: props.strip1SizeType,
            Strip2SizeType: props.strip2SizeType,
            Strip1BaseMaterialType: props.strip1BaseMaterialType,
            Strip2BaseMaterialType: props.strip2BaseMaterialType
        };

        // store data before attempting submission
        setSubmissionData(JSON.stringify(newData));

        if (newData.PanelImage) {
            newData.PanelImage = newData.PanelImage.substring(23);
        }

        try {
            const accessToken = await globalContext.getAccessTokenAsync();
            await PanelAquisitionService.CreateOrUpdateAsync(accessToken, newData);
            setSubmitted(true);
            setSubmissionData(JSON.stringify({}));
        }
        catch (e: any) {
            setErrorMessage(e.message);
        }
    };

    return (
        <Row className="screen complete-screen">
            {
                props.testData && props.testData.strip1 && props.testData.strip1.length > 0 &&
                <>
                    {
                        !submitted && !errorMessage &&
                        <>
                            <h1>Submitting Data</h1>
                            <p>Please wait...</p>
                        </>
                    }
                    {
                        submitted &&
                        <>
                            <h1>Submitted!</h1>
                        </>
                    }
                    {
                        errorMessage &&
                        <div className="error">
                            <h1>There was an error</h1>
                            <p>
                                There was an error submitting the data, please notify Jeff or Erik and show them this message '{errorMessage}'
                            </p>
                        </div>
                    }
                    <p className="aquisition-information">
                        <strong>Coupon Number: </strong>{props.couponNumber}<br />
                        <strong>Strip 1: </strong>{getStripSizeNameFromType(props.strip1SizeType)} / {getStripMaterialNameFromType(props.strip1MaterialType)}<br />
                        <strong>Strip 2: </strong>{getStripSizeNameFromType(props.strip2SizeType)} / {getStripMaterialNameFromType(props.strip2MaterialType)}<br />
                        <strong>Base Material: </strong>{getStripBaseMaterialNameFromType(props.strip1BaseMaterialType)}<br />

                        <strong>Total Run Time: </strong> {Math.ceil(props.testData.strip1[props.testData.strip1.length - 1].time / 60)} minute(s)<br />
                        <strong>Total Reading(s): </strong> {props.testData.strip1.length}<br />

                        {
                            !errorMessage &&
                            <>
                                <strong>Photo Preview: </strong><br />
                                {
                                    props.photo &&
                                    <img className="photo-preview" alt="panel preview" src={props.photo} />
                                }
                                {
                                    !props.photo &&
                                    <strong>[ User will attach later ]</strong>
                                }
                            </>
                        }
                    </p>
                </>
            }
            {
                (!props.testData || !props.testData.strip1 || props.testData.strip1.length === 0) &&
                <>
                    <h1>Test Error</h1>
                    <p>
                        There is no test data to submit, please try running again and notify Erik or Jeff.
                    </p>
                </>
            }
            {
                <Button
                    className="done"
                    variant="success"
                    onClick={() => {
                        window.location.reload();
                    }}
                >{submitted ? "Start Over" : "Cancel"}</Button>
            }
            {
                errorMessage &&
                <Button
                    className="resubmit"
                    variant="warning"
                    onClick={() => submitAsync()}
                >Re-Submit Data</Button>
            }

        </Row >
    );
}

export default Complete
