import { GlobalContext } from "components/GlobalContext";
import { IPanelInitResult } from "models/IPanelInitResult";
import IPanel from "models/sql/IPanel";
import IPanelAquisition from "models/sql/IPanelAquisition";
import { useContext, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PanelService from "services/PanelService";
import { formatDate } from "utilities/StringUtility";
import { generatePagination } from "utilities/UIUtilities";

const RECORD_COUNT = 20;

interface IPanelLookupProps {
    panelBaseData?: IPanelInitResult;
}

const CCCPanelLookupLookup = (props: IPanelLookupProps) => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [panels, setPanels] = useState<IPanel[]>([]);
    const [panelAquisitions, setPanelAquisitions] = useState<IPanelAquisition[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);

                const accessToken = await globalContext.getAccessTokenAsync();
                const result = await PanelService.GetAllAsync(accessToken, page - 1, RECORD_COUNT);

                if (result.records != null) {
                    setPanels(result.records);
                    setTotalRecords(result.total);

                    if (result.records2) {
                        setPanelAquisitions(result.records2);
                    }
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load recent Panels");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page]);

    const changePage = (newPage: number) => {
        navigate(`/CCCPanelLookup/${newPage + 1}`);
    };

    return (
        <>
            <Table striped bordered hover size="sm" className="lookup-table">
                <thead>
                    <tr>
                        <th>Panel</th>
                        <th>Order</th>
                        <th>Status</th>
                        <th>Brand</th>
                        <th>Serial #</th>
                        <th>class Value</th>
                        <th>Aquisition Date</th>
                        <th>Approvale Date</th>
                        <th>Report</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        panels.map(
                            p => {
                                const aquisition = panelAquisitions.find(pa => pa.panelId === p.panelId);

                                return (
                                    <tr
                                        key={p.panelId}
                                        onClick={() => navigate(`/CCCPanelLookup/Detail/${p.panelId}`)}
                                        className={(aquisition && aquisition?.approvedBy || "").trim() ? "approved" : ""}
                                        style={{ cursor: "pointer" }}>
                                        <td>{p.panelId}</td>
                                        <td>{p.couponOrderId}</td>
                                        <td>{props.panelBaseData?.panelStatuses && props.panelBaseData?.panelStatuses[p.panelStatusId]}</td>
                                        <td>{props.panelBaseData?.brands && props.panelBaseData?.brands.find(b => b.id === p.brandingCompanyId)?.prefix || p.brandingCompanyId}</td>
                                        <td>{p.serialNo}</td>
                                        <td>{parseInt(p.classValue) === 4 ? "GX" : parseInt(p.classValue) > 0 ? "G" + p.classValue : "-"}</td>
                                        <td>{aquisition && formatDate(aquisition.date) || "-"}</td>
                                        <td>{aquisition && aquisition.approvalDate && formatDate(aquisition.approvalDate) || "-"}</td>
                                        <td>
                                            [
                                            {
                                                p.emailLink && p.classValue &&
                                                <>
                                                    <a
                                                        href={p.emailLink}
                                                        target="_blank"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                        }}
                                                    >Report</a>
                                                </>
                                            }
                                            {
                                                aquisition &&
                                                <>{p.emailLink && p.classValue && <>&nbsp;,&nbsp;</>}
                                                    <a
                                                        href={`/CCCPanelAquisitionLookup/Detail/${aquisition.panelAquisitionId}`}
                                                        target="_blank"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                        }}
                                                    >Aquisition</a>
                                                </>}
                                            ]
                                        </td>
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </Table>
            {generatePagination(changePage, page - 1, totalRecords, RECORD_COUNT)}
        </>
    );
}

export default CCCPanelLookupLookup;
