import { GlobalContext } from "components/GlobalContext";
import IPanelAquisition from "models/sql/IPanelAquisition";
import { useContext, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PanelAquisitionService from "services/PanelAquisitionService";
import { formatDate } from "utilities/StringUtility";
import { generatePagination } from "utilities/UIUtilities";

const RECORD_COUNT = 20;

const CCCPanelAquisitionLookupLookup = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [panelAquisitions, setPanelAquisitions] = useState<IPanelAquisition[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);
                const accessToken = await globalContext.getAccessTokenAsync();
                const result = await PanelAquisitionService.GetAllAsync(accessToken, page - 1, RECORD_COUNT);

                if (result.records != null) {
                    setPanelAquisitions(result.records);
                    setTotalRecords(result.total);
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load recent Panel Aquisitions");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page]);

    const changePage = (newPage: number) => {
        navigate(`/CCCPanelAquisitionLookup/${newPage + 1}`);
    };

    return (
        <>
            <Table striped bordered hover size="sm" className="lookup-table">
                <thead>
                    <tr>
                        <th>Panel Number</th>
                        {/*<th>Order Number</th>*/}
                        <th>Aquisition Date</th>
                        <th>Approval Date</th>
                        <th>Approved By</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        panelAquisitions.map(
                            pa =>
                                <tr
                                    key={pa.panelAquisitionId}
                                    onClick={() => navigate(`/CCCPanelAquisitionLookup/Detail/${pa.panelAquisitionId}`)}
                                    style={{ cursor: "pointer" }}
                                    className={(pa?.approvedBy || "").trim() ? "approved" : ""}>
                                    <td>{pa.panelId}</td>
                                    {/* <td>{pa.orderNumber}</td> */}
                                    <td>{formatDate(pa.date, true)}</td>
                                    <td>{pa.approvalDate ? formatDate(pa.approvalDate) : "Needs Approval"}</td>
                                    <td>{pa.approvedBy}</td>
                                    <td>{(pa?.notes || "").trim().length < 50 ? pa.notes : (pa?.notes || "").substring(0, 50) + "..."}</td>
                                </tr>)
                    }
                </tbody>
            </Table>
            {generatePagination(changePage, page - 1, totalRecords, RECORD_COUNT)}
        </>
    );
}

export default CCCPanelAquisitionLookupLookup;
