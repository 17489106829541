export const downloadFile = (fileName: string, url: string, accessToken?: string, fileHeader: string = "") => {
    let headers = new Headers();

    if (accessToken) {
        headers.append('Authorization', `Bearer ${accessToken}`);
    }

    fetch(url, { headers })
        .then(res => {
            return res.blob();
        })
        .then(blob => {
            const href = (window.URL ? URL : webkitURL).createObjectURL(blob);
            const a = document.createElement("a");
            a.download = fileName;
            a.href = href;
            a.click();
            a.href = "";
        })
        .catch(err => console.error(err));
};

export function sendFileToBrowser(dataURI: string, filename: string, fileHeader: string): void {
    const link = document.createElement("a");
    link.setAttribute("href", fileHeader + encodeURIComponent(dataURI));
    link.setAttribute("download", encodeURIComponent(filename));
    link.click();
    link.href = "";
}

export const getObjectFromQuerystring = (searchParams: URLSearchParams, hints?: any) => {
    const currentParams = {} as any;
    for (const entry of Array.from(searchParams.entries())) {
        if (entry[1] === undefined || entry[1] === null || entry[1] === "") {
            continue;
        }

        if (hints && hints.hasOwnProperty(entry[0])) {
            switch (hints[entry[0]]) {
                case "Array":
                    if (!currentParams[entry[0]]) {
                        currentParams[entry[0]] = [entry[1]];
                    }
                    else {
                        currentParams[entry[0]].push(entry[1]);
                    }
                    break;

                case "Boolean":
                    currentParams[entry[0]] = entry[1] === "true";
                    break;
                case "Number":
                    if (entry[1]) {
                        currentParams[entry[0]] = entry[1];
                    }
                    break;
            }
        }
        else {
            currentParams[entry[0]] = entry[1];
        }
    }
    return currentParams;
}

export const fallbackCopyTextToClipboard = (text: string): boolean => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const result = document.execCommand('copy');
        document.body.removeChild(textArea);
        return result;
    }
    catch (err) {
        document.body.removeChild(textArea);
        window.prompt("Unable to copy data, please do so manually", text);
        return false
    }
}

export const copyTextToClipboardAsync = async (text: string): Promise<boolean> => {
    let result = false;

    if (!navigator.clipboard) {
        result = fallbackCopyTextToClipboard(text);
    }
    else {
        try {
            await navigator.clipboard.writeText(text)
            result = true;
        }
        catch (e) {
            result = fallbackCopyTextToClipboard(text);
        }
    }

    return result;
}

export const printElement = (el: any) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const pri = iframe.contentWindow;

    if (pri) {
        pri.document.open();
        pri.document.append(el);
        pri.document.close();
        pri.focus();
        pri.print();
        pri.onafterprint = () => {
            document.body.removeChild(iframe);
        }
    }
}

export const exportTableToCsv = (tableId: string | string[], filename: string): void => {
    let headers: any = [];
    let rows: any = [];

    if (typeof tableId == "string") {
        const table = document.getElementById(tableId);
        if (table) {
            const tableRows = Array.from(table.querySelectorAll("tbody tr") || []);
            if (tableRows) {
                headers = (Array.from(table.querySelectorAll("tr th")) || []).map(t => t.textContent);
                rows = tableRows.map((r: any) => (Array.from(r.querySelectorAll("td")) || []).map((t: any) => t.textContent));
                exportDataToCsv(rows, headers, filename);
            }
        }
    }
    else {
        tableId.forEach(
            id => {
                const table = document.getElementById(id);

                if (table) {
                    const tableRows = Array.from(table.querySelectorAll("tbody tr") || []);
                    if (tableRows) {
                        if (headers.length === 0) {
                            headers = (Array.from(table.querySelectorAll("tr th")) || []).map(t => t.textContent);
                        }
                        rows = rows.concat(
                            tableRows.map((r: any) => (Array.from(r.querySelectorAll("td")) || []).map((t: any) => t.textContent)));
                    }
                }
            });

        if (headers.length > 0) {
            exportDataToCsv(rows, headers, filename);
        }
    }
}

export const exportDataToCsv = (rows: any[], headers: any[], filename: string): void => {
    const separator: string = ",";
    const keys: string[] = Object.keys(rows[0]);
    let columHearders: string[];

    if (headers) {
        columHearders = headers;
    }
    else {
        columHearders = keys;
    }

    const csvContent =
        "sep=,\n" +
        columHearders.join(separator) +
        '\n' +
        rows.map((row: any) => {
            return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];

                cell = cell instanceof Date
                    ? cell.toLocaleString()
                    : cell.toString().replace(/"/g, '""');

                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }

                return cell;
            }).join(separator);
        }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}