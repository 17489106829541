import 'styles/App.scss';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from 'react-bootstrap';
import GlobalContextWrapper, { GlobalContext } from 'components/GlobalContext';
import Directory from './Directory';
import CCCKioskIndex from './CCCKiosk/Index';
import CCCPanelAquisitionLookupIndex from './CCCPanelAquisitionLookup/Index';
import CCCPanelLookupIndex from './CCCPanelLookup/Index';
import LabRequestEntryIndex from './LabRequestEntry/Index';
import LabRequestTemplateIndex from './LabRequestTemplate/Index';
import LabRequestDirectoryIndex from './LabRequestDirectory/Index';
import DeviceLogIndex from './DeviceLog/Index';
import { getCSSEnvironment } from 'utilities/MiscUtilities';
import { useContext, useRef } from 'react';
import PurapikOrdersIndex from './PurapikOrders/Index';
import KioskIndex from './Kiosk/Index';
import ShortenedUrlsIndex from './ShortenedUrls/Index';
import SystemDataIndex from './SystemData/Index';

const App = () => {
  // https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
  return (
    <>
      <BrowserRouter>
        <Container role={`main`} className={getCSSEnvironment()}>
          <GlobalContextWrapper>
            <AppContent />
          </GlobalContextWrapper>
        </Container>
        <Container style={{ position: "relative" }}>
          © {(new Date()).getFullYear()} PURAFIL, INC. ALL RIGHTS RESERVED.
          <a className="feedback-link no-print" href="https://purafilsystems.com/Public/Form?form=PFL05" target="_blank">+ Feedback</a>
        </Container>
      </BrowserRouter>
    </>
  );
}

const AppContent = () => {
  const globalContext = useContext(GlobalContext);
  const globalContextRef = useRef(globalContext);
  globalContextRef.current = globalContext;

  return (
    <Routes>
      {
        globalContext.roles.some(r => r === "Web.CCCKiosk") &&
        <Route path="/CCCKiosk/*" element={<CCCKioskIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.CCCAquisitionResults") &&
        <Route path="/CCCPanelAquisitionLookup/*" element={<CCCPanelAquisitionLookupIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.CCCPanelResults") &&
        <Route path="/CCCPanelLookup/*" element={<CCCPanelLookupIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.EFormWorkflows") &&
        <>
          <Route path="/FormSubmissions/*" element={<LabRequestEntryIndex />} />
          <Route path="/FormDirectory/*" element={<LabRequestDirectoryIndex />} />
        </>
      }
      {
        globalContext.roles.some(r => r === "Web.EFormTemplates") &&
        <Route path="/FormTemplates/*" element={<LabRequestTemplateIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.ProductionDeviceLogging") &&
        <Route path="/DeviceLog/*" element={<DeviceLogIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.PurapikOrders") &&
        <Route path="/PurapikOrders/*" element={<PurapikOrdersIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.ShortenedUrls") &&
        <Route path="/ShortenedUrls/*" element={<ShortenedUrlsIndex />} />
      }
      {
        globalContext.roles.some(r => r === "Web.SystemDataManagement") &&
        <Route path="/SystemData/*" element={<SystemDataIndex />} />
      }
      <Route path="/Kiosk/:id" element={<KioskIndex />} />
      <Route path="*" element={<Directory />} />
    </Routes>
  )
}

export default App;
