
import { GlobalContext } from 'components/GlobalContext';
import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './styles/Kiosk.scss';

const KioskIndex = () => {
    const { id }: any = useParams();
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;

    useEffect(() => {

    }, []);

    return (
        <>
            <div>
                <h1>KIOSK '{id}'</h1>
                <h2>Rnd '{Math.random() * 999999999}'</h2>
            </div>
        </>
    );
}

export default KioskIndex;
