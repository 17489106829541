import IAPIResult from "models/IAPIResult";
import { ILoggingDeviceDataInitResult } from "models/ILoggingDeviceDataInitResult";
import ILoggingDeviceDatum, { ILoggingDeviceDatumFilters } from "models/sql/ILoggingDeviceDatum";
import ILoggingDeviceMeasurement from "models/sql/ILoggingDeviceMeasurement";
import { fetchGetAsync, fetchPostAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "DeviceLog";

export default class DeviceLogService {
    static async GetAllAsync(accessToken: string, start: number, count: number, filters: ILoggingDeviceDatumFilters): Promise<{ total: number, records: ILoggingDeviceDatum[] | null, records2: ILoggingDeviceMeasurement[] | null }> {
        return await APIService.GetAllAsync<ILoggingDeviceDatum, ILoggingDeviceMeasurement>(accessToken, SERVICE_PATH, start, count, filters);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: ILoggingDeviceDatum | null, record2: null }> {
        return await APIService.GetAsync<ILoggingDeviceDatum, null>(accessToken, SERVICE_PATH, id);
    }

    static async GetInitAsync(accessToken: string): Promise<ILoggingDeviceDataInitResult | null> {
        const result: IAPIResult<ILoggingDeviceDataInitResult> =
            await fetchGetAsync<IAPIResult<ILoggingDeviceDataInitResult>>(
                `/api/${SERVICE_PATH}/init`,
                {},
                accessToken);

        return result.success ? result.data : null;
    }

    static async UploadExcelLog(accessToken: string, formData: FormData): Promise<{ time: string, batchCount: number, recordCount: number, measurements: number } | null> {
        const result: IAPIResult<{ time: string, batchCount: number, recordCount: number, measurements: number }> =
            await fetchPostAsync<IAPIResult<{ time: string, batchCount: number, recordCount: number, measurements: number }>>(
                `/api/${SERVICE_PATH}/uploadExcelLog`,
                formData,
                accessToken,
                {},
                true,
                true,
                null);

        return result.success ? result.data : null;
    }
}