import IAPIResult from "models/IAPIResult";
import { ISystemDatumInitResult } from "models/ISystemDatumInitResult";
import ISystemDatum, { ISystemDatumCreateOrUpdate } from "models/sql/ISystemDatum";
import { fetchGetAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "SystemData";

export default class SystemDataService {
    static async GetAllAsync(accessToken: string, start: number, count: number): Promise<{ total: number, records: ISystemDatum[] | null, records2: null[] | null }> {
        return await APIService.GetAllAsync<ISystemDatum, null>(accessToken, SERVICE_PATH, start, count);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: ISystemDatum | null, record2: null }> {
        return await APIService.GetAsync<ISystemDatum, null>(accessToken, SERVICE_PATH, id);
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async CreateOrUpdateAsync(accessToken: string, entry: ISystemDatumCreateOrUpdate): Promise<boolean> {
        return APIService.CreateOrUpdate<ISystemDatumCreateOrUpdate>(accessToken, SERVICE_PATH, entry);
    }

    static async GetInitAsync(accessToken: string): Promise<ISystemDatumInitResult | null> {
        const result: IAPIResult<ISystemDatumInitResult> =
            await fetchGetAsync<IAPIResult<ISystemDatumInitResult>>(
                `/api/${SERVICE_PATH}/init`,
                {},
                accessToken);

        return result.success ? result.data : null;
    }
}