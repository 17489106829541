import IAPIResult from "models/IAPIResult";
import { fetchGetAsync, fetchPostAsync } from "utilities/Fetch";

export default class APIService {
    static async GetAllAsync<T, U>(accessToken: string, path: string, start: number, count: number, filters?: any): Promise<{ total: number, records: T[] | null, records2: U[] | null }> {
        const result: IAPIResult<{ total: number, records: T[], records2: U[] }> =
            await fetchPostAsync<IAPIResult<{ total: number, records: T[], records2: U[] }>>(
                `/api/${path}/getAll?start=${start}&count=${count}`,
                filters,
                accessToken);

        return result.success ? { total: result.data.total, records: result.data.records, records2: result.data.records2 } : { total: 0, records: null, records2: null };
    }

    static DownloadExcelFileAsync = async (accessToken: string, path: string, fileName: string, filters?: any) => {
        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let headers: any = {
            'Accept': contentType,
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        };
        const result =
            await fetch(
                `/api/${path}/downloadexcelfile?filename=${fileName}`,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    redirect: "follow",
                    referrer: "no-referrer",
                    headers: headers,
                    body: JSON.stringify(filters)
                });
        saveAs(
            new Blob([await result.blob()], { type: contentType }),
            fileName);
    };
    static async GetAsync<T, U>(accessToken: string, path: string, id: number): Promise<{ record: T | null, record2: U | null }> {
        const result: IAPIResult<{ record: T, record2: U }> =
            await fetchGetAsync<IAPIResult<{ record: T, record2: U }>>(
                `/api/${path}/get`,
                { id },
                accessToken);

        return result.success ? { record: result.data.record, record2: result.data.record2 } : { record: null, record2: null };
    }

    static async DeleteAsync<T, U>(accessToken: string, path: string, id: number) {
        const result: IAPIResult<null> =
            await fetchGetAsync<IAPIResult<null>>(
                `/api/${path}/delete`,
                { id },
                accessToken);
    }

    static async CustomAsync<T>(accessToken: string, path: string, action: string, data?: any): Promise<T | null> {
        const result: IAPIResult<T> =
            await fetchGetAsync<IAPIResult<T>>(
                `/api/${path}/${action}`,
                data,
                accessToken);

        return result.success ? result.data : null;
    }

    static async CreateOrUpdate<T>(accessToken: string, path: string, entry: T): Promise<boolean> {
        const result: IAPIResult<boolean> =
            await fetchPostAsync<IAPIResult<boolean>>(
                `/api/${path}/createOrUpdate`,
                { ...entry },
                accessToken);

        return result.success;
    }

    static async DeletePost(accessToken: string, path: string, id: number): Promise<boolean> {
        const result: IAPIResult<boolean> = await fetchPostAsync<IAPIResult<boolean>>(
            `/api/${path}/delete`,
            { id },
            accessToken);

        return result.success;
    }
}

function saveAs(arg0: Blob, fileName: string) {
    throw new Error("Function not implemented.");
}
