import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { appStates, IKioskSceneBaseProps } from "../Index";
import Webcam from "react-webcam";
import { GlobalContext } from "components/GlobalContext";
import { rotateImage } from "utilities/MiscUtilities";

const Photo = (props: IKioskSceneBaseProps) => {
    const globalContext = useContext(GlobalContext);
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    useEffect(
        () => {
            props.changeAllLedColors(0, 0, 0, 255);
            return () => {
                props.changeAllLedColors(0, 0, 0, 0);
            }
        }, []);

    const capture = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = // @ts-ignore
                webcamRef.current.getScreenshot({
                    height: 720,
                    width: 1280
                });

            rotateImage(
                imageSrc,
                90,
                (rotatedImageSrc: any) => {
                    setImgSrc(rotatedImageSrc);
                });
        }
    }, [webcamRef, setImgSrc]);

    return (
        <Row className="screen photo-screen">
            <h1>
                <Button
                    className="back"
                    onClick={() => window.location.reload()}
                >Back</Button>
                Photo Setup</h1>

            {
                !imgSrc &&
                <>
                    <h2>Setup Photo</h2>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1}
                        videoConstraints={{
                            height: 720,
                            width: 1280
                        }}
                        minScreenshotHeight={720}
                        minScreenshotWidth={1280}
                    />
                    <Button
                        className="take-shot"
                        onClick={() => {
                            setTimeout(() => { capture(); }, 300);
                            setTimeout(() => { props.changeAllLedColors(0, 0, 0, 0); }, 1000);
                        }}
                    >Take Shot</Button><br />
                    <Button
                        className="skip"
                        variant="warning"
                        onClick={() => {
                            if (props.strip1MaterialType || props.strip2MaterialType) {
                                globalContext.showErrorMessage("");
                                props.setAppState(appStates.SetupCouponCode);
                            }
                            else {
                                globalContext.showErrorMessage("You must select atleast one strip");
                            }
                        }}
                    >Skip Photo</Button>
                </>
            }

            {
                imgSrc &&
                <>
                    <h2>Photo Preview</h2>
                    {
                        imgSrc &&
                        <>
                            <img src={imgSrc!} alt="Panel Preview" /><br />
                        </>
                    }
                    <Button
                        className="try-again"
                        variant="danger"
                        onClick={() => setImgSrc(null)}
                    >Try Again</Button><br />
                    <Button
                        className="accept"
                        variant="success"
                        onClick={() => {
                            props.setPhoto(imgSrc);
                            props.setAppState(appStates.SetupCouponCode);
                        }}
                    >Accept Photo</Button>
                </>}
        </Row>
    );
}

export default Photo