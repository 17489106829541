import './styles/CCCKiosk.scss';

import React, { useContext, useEffect, useRef, useState } from "react";
import Complete from "./scenes/Complete";
import Idle from "./scenes/Idle";
import Photo from "./scenes/Photo";
import { Container } from 'react-bootstrap';
import SetupCouponCode from './scenes/SetupCouponCode';
import SetupStrips from './scenes/SetupStrips';
import Confirm from './scenes/Confirm';
import Running from './scenes/Running';
import io, { Socket } from 'socket.io-client';
import { GlobalContext } from 'components/GlobalContext';

/*
    idle screen
        - start new test
            - take a photo
                - check photo for qr code
                - manually enter code
                    - run test until levels seem to not be changing much on the volt axis and notify that the test seems like it's complete
                        - submit results to backend so they can be looked up on the other app
*/

export enum appStates {
    Idle = 0,
    Photo,
    SetupCouponCode,
    SetupStrips,
    Confirm,
    Running,
    Complete
};

export interface IKioskSceneBaseProps {
    setAppState: (newState: appStates) => void;
    appState: appStates;
    setPhoto: (newPhoto: any) => void;
    photo: any;
    setCouponNumber: (newCouponNumber: string) => void;
    couponNumber: string;
    setTestData: (testData: ITestData) => void;
    testData: ITestData | undefined;

    setStrip1SizeType: (type: number) => void;
    strip1SizeType: number;
    setStrip2SizeType: (type: number) => void;
    strip2SizeType: number;

    strip1BaseMaterialType: number;
    setStrip1BaseMaterialType: (type: number) => void;
    strip2BaseMaterialType: number;
    setStrip2BaseMaterialType: (type: number) => void;

    setStrip1MaterialType: (type: number) => void;
    strip1MaterialType: number;
    setStrip2MaterialType: (type: number) => void;
    strip2MaterialType: number;

    changeLedColor: (index: number, r: number, g: number, b: number, w: number) => void;
    changeAllLedColors: (r: number, g: number, b: number, w: number) => void;
    relayOn: (which: number) => void;
    relayOff: (which: number) => void;
    startADCReads: () => void;
    stopADCReads: () => void;
    logMessage: (message: string) => void;
    confirmHardware: (whichSocket?: any) => void;
}

export const getStripMaterialNameFromType = (type: number): string => {
    switch (type) {
        case 0:
            return "None";
        case 1:
            return "Silver";
        case 2:
            return "Copper";
        default:
            return "";
    }
}

export const getStripMaterialTypeFromName = (name: string): number => {
    switch (name) {
        case "None":
            return 0;
        case "Silver":
            return 1;
        case "Copper":
            return 2;
        default:
            return 99;
    }
}

export const getStripSizeTypeFromName = (size: string): number => {
    switch (size) {
        case "Whole":
            return 0;
        case "Half":
            return 1;
        default:
            return 99;
    }
}

export const getStripSizeNameFromType = (type: number): string => {
    switch (type) {
        case 0:
            return "Whole";
        case 1:
            return "Half";
        default:
            return "";
    }
}

export const getStripBaseMaterialNameFromType = (type: number): string => {
    switch (type) {
        case 0:
            return "Glass";
        case 1:
            return "Metal";
        default:
            return "";
    }
}

export const getStripBaseMaterialTypeFromName = (name: string): number => {
    switch (name) {
        case "Glass":
            return 0;
        case "Metal":
            return 1;
        default:
            return 99;
    }
}

export interface IStripData {
    time: number;
    value: number;
};

export interface ITestData {
    strip1: IStripData[];
    strip2: IStripData[];
}

let adcReadStart = 0;

const CCCKioskIndex = () => {
    const globalContext = useContext(GlobalContext);
    const [appState, setAppState] = useState(appStates.Idle);
    const [couponNumber, setCouponNumber] = useState("");
    const [photo, setPhoto] = useState<any>();
    const [testData, setTestData] = useState<ITestData>();
    const testDataRef = useRef(testData);
    testDataRef.current = testData;

    const [strip1MaterialType, setStrip1MaterialType] = useState(2);
    const [strip2MaterialType, setStrip2MaterialType] = useState(1);

    const [strip1SizeType, setStrip1SizeType] = useState(0);
    const [strip2SizeType, setStrip2SizeType] = useState(0);

    const [strip1BaseMaterialType, setStrip1BaseMaterialType] = useState(1);
    const [strip2BaseMaterialType, setStrip2BaseMaterialType] = useState(1);

    const [socket, setSocket] = useState<Socket>();
    const [serverConnected, setServerConnected] = useState(false);

    useEffect(() => {
        const newSocket = io('http://localhost:5100');
        setSocket(newSocket);

        newSocket.on(
            'confirm',
            (message) => {
                console.log("Recieved confirmation");
            });

        newSocket.on(
            'pong',
            (message) => {
                setServerConnected(true);
                setTimeout(() => newSocket.emit("ledsetall", { data: "255,255,255,255" }), 300);
                setTimeout(() => newSocket.emit("ledsetall", { data: "0,0,0,0" }), 600);
                setTimeout(() => newSocket.emit("ledsetall", { data: "255,255,255,255" }), 900);
                setTimeout(() => newSocket.emit("ledsetall", { data: "0,0,0,0" }), 1200);
                console.log("PingPong OK");
            });

        newSocket.on('adcreads', (message) => {
            const chunks = message.data.split(",");
            const strip1 = [...(testDataRef.current?.strip1 || [])];
            const strip2 = [...(testDataRef.current?.strip2 || [])];
            const time = ((Date.now() - adcReadStart) / 1000);

            strip1.push({ time: time, value: parseFloat(chunks[1]) });
            strip2.push({ time: time, value: parseFloat(chunks[3]) });

            setTestData({ strip1, strip2 });
        });

        setTimeout(
            () => {
                confirmHardware(newSocket);
            },
            500);

        return () => { newSocket.close(); }
    }, [setSocket]);

    const confirmHardware = (whichSocket?: any) => {
        const targetSocket = whichSocket ?? socket;
        if (targetSocket) {
            console.log("Looking for server");
            setServerConnected(false);
            targetSocket.emit('ping', "ok");
        }
    };

    const changeLedColor = (index: number, r: number, g: number, b: number, w: number) => {
        if (socket && serverConnected) {
            socket.emit(
                "ledset",
                { data: `${index},${r},${g},${b},${w}` });
        }
    };

    const changeAllLedColors = (r: number, g: number, b: number, w: number) => {
        if (socket && serverConnected) {
            socket.emit(
                "ledsetall",
                { data: `${r},${g},${b},${w}` });
        }
    };

    const relayOn = (which: number) => {
        if (socket && serverConnected) {
            socket.emit(
                "relayon",
                { data: `${which}` });
        }
    };

    const relayOff = (which: number) => {
        if (socket && serverConnected) {
            socket.emit(
                "relayoff",
                { data: `${which}` });
        }
    };

    const startADCReads = () => {
        if (socket && serverConnected) {
            socket.emit(
                "startreads",
                { data: "" });

            adcReadStart = Date.now();
        }
    };

    const stopADCReads = () => {
        if (socket && serverConnected) {
            socket.emit(
                "endreads",
                { data: "" });
        }
    };

    const logMessage = (message: string) => {
        if (socket && serverConnected) {
            socket.emit(
                "log",
                { data: message });
        }
    }

    const renderScene = (): JSX.Element => {
        switch (appState) {
            case appStates.Idle:
                return <Idle setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
            case appStates.SetupCouponCode:
                return <SetupCouponCode setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
            case appStates.SetupStrips:
                return <SetupStrips setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
            case appStates.Photo:
                return <Photo setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
            case appStates.Confirm:
                return <Confirm setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
            case appStates.Complete:
                return <Complete setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
            case appStates.Running:
                return <Running setAppState={setAppState} appState={appState} setPhoto={setPhoto} photo={photo} setCouponNumber={setCouponNumber} couponNumber={couponNumber} setTestData={setTestData} testData={testData} strip1MaterialType={strip1MaterialType} setStrip1MaterialType={setStrip1MaterialType} strip2MaterialType={strip2MaterialType} setStrip2MaterialType={setStrip2MaterialType} changeLedColor={changeLedColor} changeAllLedColors={changeAllLedColors} startADCReads={startADCReads} stopADCReads={stopADCReads} logMessage={logMessage} confirmHardware={confirmHardware} strip1SizeType={strip1SizeType} setStrip1SizeType={setStrip1SizeType} strip2SizeType={strip2SizeType} setStrip2SizeType={setStrip2SizeType} strip1BaseMaterialType={strip1BaseMaterialType} setStrip1BaseMaterialType={setStrip1BaseMaterialType} strip2BaseMaterialType={strip2BaseMaterialType} setStrip2BaseMaterialType={setStrip2BaseMaterialType} relayOn={relayOn} relayOff={relayOff} />
        }

        return <span></span>
    }

    return (
        <Container className="CCCKiosk">
            {
                serverConnected && globalContext.userName &&
                renderScene()
            }
            {
                !serverConnected &&
                <>
                    <br /><br /><br />
                    <div className="not-connected">
                        <h1>Hardware driver<br />not available.</h1>
                        <p>Please unplug and re-plugin device and notify Erik or Jeff.</p>
                    </div >
                </>
            }
        </Container>
    );
}

export default CCCKioskIndex;
