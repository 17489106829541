import { GlobalContext } from "components/GlobalContext";
import TouchToggle from "components/touch/TouchToggle";
import { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { appStates, getStripBaseMaterialNameFromType, getStripBaseMaterialTypeFromName, getStripMaterialNameFromType, getStripMaterialTypeFromName, getStripSizeNameFromType, getStripSizeTypeFromName, IKioskSceneBaseProps, } from "../Index";

const SetupStrips = (props: IKioskSceneBaseProps) => {
    const globalContext = useContext(GlobalContext);
    const stripMaterialOptions = [getStripMaterialNameFromType(0), getStripMaterialNameFromType(1), getStripMaterialNameFromType(2)];
    const stripTypeOptions = [getStripBaseMaterialNameFromType(0), getStripBaseMaterialNameFromType(1)];
    const stripSizeOptions = [getStripSizeNameFromType(0), getStripSizeNameFromType(1)];

    const strip1ToggleChange = (data: string) => {
        props.setStrip1MaterialType(getStripMaterialTypeFromName(data));
    };

    const strip2ToggleChange = (data: string) => {
        props.setStrip2MaterialType(getStripMaterialTypeFromName(data));
    };

    const strip1SizeTypeToggle = (data: string) => {
        props.setStrip1SizeType(getStripSizeTypeFromName(data));
    };

    const strip2SizeTypeToggle = (data: string) => {
        props.setStrip2SizeType(getStripSizeTypeFromName(data));
    };

    const stripTypeToggle = (data: string) => {
        props.setStrip1BaseMaterialType(getStripBaseMaterialTypeFromName(data));
        props.setStrip2BaseMaterialType(getStripBaseMaterialTypeFromName(data));
    };

    return (
        <Row className="screen setup-strips-screen">
            <h1>
                <Button
                    className="back"
                    onClick={() => props.setAppState(appStates.SetupCouponCode)}
                >Back</Button>
                Setup Strips</h1>

            <Row className="strip-entry">
                <h2>Strip 1</h2>
                <TouchToggle
                    changeCallback={strip1ToggleChange}
                    default={getStripMaterialNameFromType(props.strip1MaterialType)}
                    options={stripMaterialOptions} />
                {
                    props.strip1MaterialType > 0 &&
                    <TouchToggle
                        changeCallback={strip1SizeTypeToggle}
                        default={getStripSizeNameFromType(props.strip1SizeType)}
                        options={stripSizeOptions} />
                }
            </Row>
            <Row className="strip-entry">
                <h2>Strip 2</h2>
                <TouchToggle
                    changeCallback={strip2ToggleChange}
                    default={getStripMaterialNameFromType(props.strip2MaterialType)}
                    options={stripMaterialOptions} />
                {
                    props.strip2MaterialType > 0 &&
                    <TouchToggle
                        changeCallback={strip2SizeTypeToggle}
                        default={getStripSizeNameFromType(props.strip2SizeType)}
                        options={stripSizeOptions} />
                }
            </Row>
            <Row className="strip-entry pt-5">
                <Col>
                    <h2>Base<br />Material?</h2>
                </Col>
                <Col>
                    <TouchToggle
                        changeCallback={stripTypeToggle}
                        default={getStripBaseMaterialNameFromType(props.strip1BaseMaterialType)}
                        options={stripTypeOptions} />
                </Col>
            </Row>

            <Button
                className="accept"
                variant="success"
                onClick={() => {
                    if (props.strip1MaterialType || props.strip1MaterialType) {
                        globalContext.showErrorMessage("");
                        props.setAppState(appStates.Confirm);
                    }
                    else {
                        globalContext.showErrorMessage("You must select atleast one strip");
                    }
                }}
            >Confirm</Button>
        </Row>
    );
}

export default SetupStrips