
import { GlobalContext } from 'components/GlobalContext';
import { useContext, useEffect, useRef } from 'react';
import { Navbar } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import LabRequestDirectoryLookup from './Lookup';
import './styles/LabRequestDirectory.scss';

const LabRequestDirectoryIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;

    useEffect(() => {
        globalContextRef.current.setSecondaryNav(<>
            <Navbar.Brand href="/FormDirectory"><strong>Forms Directory</strong></Navbar.Brand>
        </>);
    }, []);

    return (
        <>
            <div>
                <Routes>
                    <Route path={"/:page"} element={<LabRequestDirectoryLookup />} />
                    <Route path={"/*"} element={<LabRequestDirectoryLookup />} />
                </Routes>
            </div>
        </>
    );
}

export default LabRequestDirectoryIndex;
