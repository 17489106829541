import { GlobalContext } from "components/GlobalContext";
import SortableTable from "components/SortableTable";
import { ILabRequestEntryInitResult } from "models/ILabRequestEntryInitResult";
import ILabRequestEntry from "models/sql/ILabRequestEntry";
import ILabRequestTemplate, { ICustomWorkflowData } from "models/sql/ILabRequestTemplate";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Nav, Navbar, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LabRequestEntryService from "services/LabRequestEntryService";
import LabRequestTemplateService from "services/LabRequestTemplateService";
import { formatDate } from "utilities/StringUtility";

const RECORD_COUNT = 20;

interface ILabRequestEntryChooseFormProps {
    baseData?: ILabRequestEntryInitResult;
}

const LabRequestEntryChooseForm = (props: ILabRequestEntryChooseFormProps) => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [templates, setTemplates] = useState<ILabRequestTemplate[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);
    const [lookupEntries, setLookupEntries] = useState<ILabRequestEntry[]>();

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setSecondaryNav(<>
                    <Navbar.Brand href="/FormSubmissions"><strong>Select Form</strong></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Button
                                style={{ marginRight: "10px" }}
                                onClick={
                                    () => {
                                        globalContext.showModal({
                                            title: "Lookup by Submission Code",
                                            formElements: [{
                                                key: "code",
                                                type: "text",
                                                title: "Submission Code (Whole or Partial)",
                                                value: ""
                                            }],
                                            content: <></>,
                                            size: "lg",
                                            okButtonTitle: "Find Submissions",
                                            okCallback: async (data: any) => {
                                                globalContext.setLoading(true);
                                                const accessToken = await globalContext.getAccessTokenAsync();
                                                const entrys = await LabRequestEntryService.LookupByCodeAsync(accessToken, data[0].value);
                                                globalContext.setLoading(false);
                                                setLookupEntries(entrys || []);
                                            }
                                        });
                                    }}>Lookup by Code</Button>
                            <Button onClick={
                                () => {
                                    globalContext.showModal({
                                        title: "Lookup by phrase in data",
                                        formElements: [{
                                            key: "code",
                                            type: "text",
                                            title: "Phrase in submitted data, log or required status fields (Whole or Partial)",
                                            value: ""
                                        }],
                                        content: <></>,
                                        size: "lg",
                                        okButtonTitle: "Find Submissions",
                                        okCallback: async (data: any) => {
                                            globalContext.setLoading(true);
                                            const accessToken = await globalContext.getAccessTokenAsync();
                                            const entrys = await LabRequestEntryService.LookupBySearchAllAsync(accessToken, data[0].value);
                                            globalContext.setLoading(false);
                                            setLookupEntries(entrys || []);
                                        }
                                    });
                                }}>Lookup by phrase in data</Button>
                        </Nav>
                    </Navbar.Collapse>
                </>);
            })();
        },
        []);

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);
                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                const result = await LabRequestTemplateService.GetAllAsync(accessToken, page - 1, RECORD_COUNT);

                if (result.records != null) {
                    setTemplates(result.records);
                    setTotalRecords(result.total);
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load recent Templates");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page]);

    useEffect(
        () => {
            const defaultStatusPhrases = ["New", "In Progress", "Internal Issue", "External Issue", "Complete"];

            if (lookupEntries && lookupEntries.length > 0) {
                globalContext.showModal(
                    {
                        title: "Submission Lookup",
                        content: <>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <td>Form</td>
                                        <td>Submission Date</td>
                                        <td>Status</td>
                                        <td>Close Date</td>
                                        <td>Submission Code</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lookupEntries.map(
                                        (entry, index) => {

                                            let statusName = defaultStatusPhrases[entry.status];

                                            if (entry.labRequestTemplate.customWorkflowDataJson) {
                                                const workflowdata: ICustomWorkflowData = JSON.parse(entry.labRequestTemplate.customWorkflowDataJson);
                                                statusName = workflowdata.statuses[entry.status];
                                            }

                                            return (
                                                <tr
                                                    key={index}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        navigate(`/FormSubmissions/Detail/${entry.labRequestEntryId}`);
                                                        globalContext.hideModal();
                                                    }}>
                                                    <td>{`${entry.labRequestTemplate.title} (${entry.labRequestTemplate.formNumber} Ver. ${entry.labRequestTemplate.version})`}</td>
                                                    <td>{formatDate(new Date(entry.submissionDate))}</td>
                                                    <td>{statusName}</td>
                                                    <td>{entry.closeDate ? formatDate(new Date(entry.submissionDate)) : "Still Open"}</td>
                                                    <td>{entry.submissionCode}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </ Table>
                        </>
                    });
            }
            else {
                if (lookupEntries && lookupEntries.length === 0) {
                    globalContext.showModal(
                        {
                            title: "Submission Lookup",
                            content: <><strong>No submissions found</strong></>
                        });
                }
            }
        },
        [lookupEntries]);

    return (
        <>
            <h5>Select Form Type</h5>
            <SortableTable<ILabRequestTemplate, null>
                className="lookup-table"
                changePageCallback={(newPage) => navigate(`/FormSubmissions/ChooseForm/${newPage + 1}`)}
                page={page}
                totalRecords={totalRecords}
                recordCount={templates.length}
                records={templates}
                rowClickCallback={(data) => navigate(`/FormSubmissions/Form/${data.formNumber}/1`)}
                extraPreRowsRenderer={() => [
                    /*<tr key="allentry"
                        onClick={() => { navigate(`/FormSubmissions/Form/all/1`) }}
                        style={{ cursor: "pointer" }}>
                        <td className="text-center" colSpan={3}><strong>- View submissions from all forms -</strong></td>
                    </tr>*/
                ]}
                rowClassRenderer={(data) => !data.active ? "innactive" : ""}
                columnDefinitions={[
                    {
                        propName: "title",
                        title: "Title"
                    },
                    {
                        propName: "formNumber",
                        title: "Form Number"
                    },
                    {
                        propName: "version",
                        title: "Version"
                    }
                ]}
            />
        </>
    );
}

export default LabRequestEntryChooseForm;
