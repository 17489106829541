import { GlobalContext } from 'components/GlobalContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import SystemDataLookup from './Lookup';
import SystemDataDetail from './Detail';

import './styles/SystemData.scss';

const SystemDataIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;

    useEffect(() => {
        (async () => {
        })();
    }, []);

    return (
        <>
            <div>
                <Routes>
                    <Route path={"Detail/:id"} element={<SystemDataDetail />} />
                    <Route path={"Lookup/:page"} element={<SystemDataLookup />} />
                    <Route path={"Lookup"} element={<SystemDataLookup />} />
                    <Route path={"/*"} element={<SystemDataLookup />} />
                </Routes>
            </div>
        </>
    );
}

export default SystemDataIndex;
