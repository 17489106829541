import { useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from "components/GlobalContext";
import IPanelAquisition from "models/sql/IPanelAquisition";
import PanelAquisitionService from "services/PanelAquisitionService";
import { fetchGetAsync } from "utilities/Fetch";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { formatDate } from "utilities/StringUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleInfo, faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const DEFAULT_COPPER_UKNOWN_1_START: number = 0;
const DEFAULT_COPPER_UKNOWN_1_END: number = 0.5;
const DEFAULT_COPPER_OXIDE_START: number = 0.51;
const DEFAULT_COPPER_OXIDE_END: number = 0.59;
const DEFAULT_COPPER_UNKNOWN_2_START: number = 0.6;
const DEFAULT_COPPER_UNKNOWN_2_END: number = 0.9;
const DEFAULT_COPPER_SULFIDE_START: number = 1.03;
const DEFAULT_COPPER_SULFIDE_END: number = 1.13;
const DEFAULT_SILVER_CHLORINE_START: number = 0;
const DEFAULT_SILVER_CHLORINE_END: number = 0.1;
const DEFAULT_SILVER_SULPHOR_START: number = 0.5;
const DEFAULT_SILVER_SULPHOR_END: number = 1.10;
const DEFAULT_TIME_VALUE_ADJUST: number = 1.18; // a line is 1 minute and 40 seconds in time, but counts for '1' as a value

const CANVAS_WIDTH = 2000;
const CANVAS_HEIGHT = 1700;

const ORIGINAL_LINE_THICKNESS: number = 1;
const FILTERED_LINE_THICKNESS: number = 6;
const SILVER_Y_OFFSET: number = 30;
const SILVER_V_MULTIPLY: number = 1.1;
const INF_CHANGE_TOLERANCE: number = 1;
const INF_POINT_SIZE: number = 20;
const SIMPLIFY_TOLERANCE: number = 0.05;
const FILTER_AMOUNT: number = 20;

let COPPER_UKNOWN_1_START: number = DEFAULT_COPPER_UKNOWN_1_START;
let COPPER_UKNOWN_1_END: number = DEFAULT_COPPER_UKNOWN_1_END;
let COPPER_OXIDE_START: number = DEFAULT_COPPER_OXIDE_START;
let COPPER_OXIDE_END: number = DEFAULT_COPPER_OXIDE_END;
let COPPER_UNKNOWN_2_START: number = DEFAULT_COPPER_UNKNOWN_2_START;
let COPPER_UNKNOWN_2_END: number = DEFAULT_COPPER_UNKNOWN_2_END;
let COPPER_SULFIDE_START: number = DEFAULT_COPPER_SULFIDE_START;
let COPPER_SULFIDE_END: number = DEFAULT_COPPER_SULFIDE_END;
let SILVER_CHLORINE_START: number = DEFAULT_SILVER_CHLORINE_START;
let SILVER_CHLORINE_END: number = DEFAULT_SILVER_CHLORINE_END;
let SILVER_SULPHOR_START: number = DEFAULT_SILVER_SULPHOR_START;
let SILVER_SULPHOR_END: number = DEFAULT_SILVER_SULPHOR_END;

const CCCPanelAquisitionLookupDetail = () => {
    const [canvas, setCanvas] = useState<HTMLCanvasElement>();
    const [ctx, setCTX] = useState<CanvasRenderingContext2D>();
    const globalContext = useContext(GlobalContext);
    const { aquisitionId }: any = useParams();
    const [panelAquisition, setPanelAquisition] = useState<IPanelAquisition>();
    const [panelAquisitionData, setPanelAquisitionData] = useState<any>();
    const [copperValues, setCopperValues] = useState<number[]>([]);
    const [silverValues, setSilverValues] = useState<number[]>([]);
    const [testData1, setTestData1] = useState<{ time: number, v: number }[]>([]);
    const [testData2, setTestData2] = useState<{ time: number, v: number }[]>([]);
    const [timeOffset] = useState(140);
    const [filterOffset] = useState(40);
    const [voltageScale, setVoltageScale] = useState(0.1);
    const [timeScale, setTimeScale] = useState(0.1);
    const [voltageDivisor, setVoltageDivisor] = useState(0);// 0.27
    const [numberBumps, setNumberBumps] = useState<number[]>([0, 0, 0, 0, 0, 0]);
    const panelAquisitionDataRef = useRef(panelAquisitionData);
    const [manuallyCalculated, setManuallyCalculated] = useState(false);
    const [manuallyCalculatedCuU, setManuallyCalculatedCuU] = useState(0);
    const [manuallyCalculatedCu2O, setManuallyCalculatedCu2O] = useState(0);
    const [manuallyCalculatedCu2S, setManuallyCalculatedCu2S] = useState(0);
    const [manuallyCalculatedAgCL, setManuallyCalculatedAgCL] = useState(0);
    const [manuallyCalculatedAg2S, setManuallyCalculatedAg2S] = useState(0);

    panelAquisitionDataRef.current = panelAquisitionData;

    let filteredData1: { time: number, v: number }[] = [];
    let filteredData2: { time: number, v: number }[] = [];
    let v1Points: { index: number, polarity: boolean, diff: number, angle: number }[] = [];
    let v2Points: { index: number, polarity: boolean, diff: number, angle: number }[] = [];
    let lastFilterAmount = 0;
    let lastSimplificationTolerance = 0;
    let lastInflectionChangeTolerance = 0;
    let lastVoltageDivisor = 0;
    let lastVX = 0;
    let lastVY = CANVAS_HEIGHT;
    let highestV1 = Number.NEGATIVE_INFINITY;
    let lowestV1 = Number.POSITIVE_INFINITY;
    let highestV2 = Number.NEGATIVE_INFINITY;
    let lowestV2 = Number.POSITIVE_INFINITY;
    let highestTime = Number.NEGATIVE_INFINITY;
    let copperVals: number[] = [];
    let silverVals: number[] = [];

    const processAquisition = (data: { t: string, v1: string, v2: string }[]) => {
        const tempTestData1: { time: number, v: number }[] = [];
        const tempTestData2: { time: number, v: number }[] = [];
        filteredData1 = [];
        filteredData2 = [];
        lastFilterAmount = 0;
        lastSimplificationTolerance = 0;
        lastInflectionChangeTolerance = 0;
        lastVoltageDivisor = 0;
        lastVX = 0;
        lastVY = CANVAS_HEIGHT;
        copperVals = [];
        silverVals = [];

        let timeOffset = 0;

        data.forEach(
            line => {
                if (testData1.length === 0) {
                    timeOffset = parseFloat(line.t) * -1;
                }

                tempTestData1.push({
                    time: parseFloat(line.t) + timeOffset,
                    v: Math.floor(parseFloat(line.v1) * voltageScale)
                });

                tempTestData2.push({
                    time: parseFloat(line.t) + timeOffset,
                    v: Math.floor(parseFloat(line.v2) * voltageScale)
                });

                highestV1 = Math.max(highestV1, parseFloat(line.v1));
                highestV2 = Math.max(highestV2, parseFloat(line.v2));
                lowestV1 = Math.min(lowestV1, parseFloat(line.v1));
                lowestV2 = Math.min(lowestV2, parseFloat(line.v2));
                highestTime = Math.max(highestTime, parseFloat(line.t));
            });

        // scale everything to fit based on highest and lowest
        console.log("highestTime " + highestTime);

        if (voltageScale < 1) {
            setVoltageScale(CANVAS_WIDTH / (highestV1 * 1.2));
        }

        if (timeScale < 1) {
            setTimeScale(CANVAS_HEIGHT / (highestTime * 1.2));
        }

        setTestData1(tempTestData1);
        setTestData2(tempTestData2);
    };

    /* deprecated for now
        const reduceData = (data: { time: number, v: number }[]) => {
            const result = [data[0]];
            let originalLength = data.length;
    
            if (data.length > 2) {
                lastVX = data[1].v;
                lastVY = data[1].time;
                let lastAngle = getAngle(data[0].v, data[0].time, data[1].v, data[1].time);
    
                for (let i = 2; i < data.length; i++) {
                    const newAngle = getAngle(data[i - 1].v, data[i - 1].time, data[i].v, data[i].time);
    
                    if (Math.abs(newAngle - lastAngle) >= SIMPLIFY_TOLERANCE) {
                        result.push(data[i]);
                    }
    
                    lastAngle = newAngle;
                }
    
            }
    
            console.log(`Done reducing data from ${originalLength} to ${result.length}`);
            return result;
        }
    */

    /* deprecated for now
    const getAngle = (x1: number, y1: number, x2: number, y2: number) => {
        return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    }
    */

    const getCopperVals = (filteredData: { time: number, v: number }[]) => {
        const unknown1Center =
            (COPPER_UKNOWN_1_START +
                ((COPPER_UKNOWN_1_END - COPPER_UKNOWN_1_START) / 2) + voltageDivisor) *
            voltageScale;

        const oxideCenter =
            (COPPER_OXIDE_START +
                ((COPPER_OXIDE_END - COPPER_OXIDE_START) / 2) + voltageDivisor) *
            voltageScale;

        const unknown2Center =
            (COPPER_UNKNOWN_2_START +
                ((COPPER_UNKNOWN_2_END - COPPER_UNKNOWN_2_START) / 2) + voltageDivisor) *
            voltageScale;

        const sulfideCenter =
            (COPPER_SULFIDE_START +
                ((COPPER_SULFIDE_END - COPPER_SULFIDE_START) / 2) + voltageDivisor) *
            voltageScale;

        //console.log("Getting copper vals", voltageDivisor, unknown1Center, oxideCenter, unknown2Center, sulfideCenter);

        let closestU1Distance = Number.POSITIVE_INFINITY;
        let u1Index = 0;
        let closestU2Distance = Number.POSITIVE_INFINITY;
        let u2Index = 0;
        let closestOxDistance = Number.POSITIVE_INFINITY;
        let oxIndex = 0;
        let closestSuDistance = Number.POSITIVE_INFINITY;
        let suIndex = 0;

        filteredData.forEach(
            (entry, index) => {
                if (Math.abs(entry.v - unknown1Center) < closestU1Distance) {
                    closestU1Distance = Math.abs(entry.v - unknown1Center);
                    u1Index = index;
                }

                if (Math.abs(entry.v - unknown2Center) < closestU2Distance) {
                    closestU2Distance = Math.abs(entry.v - unknown2Center);
                    u2Index = index;
                }

                if (Math.abs(entry.v - oxideCenter) < closestOxDistance) {
                    closestOxDistance = Math.abs(entry.v - oxideCenter);
                    oxIndex = index;
                }

                if (Math.abs(entry.v - sulfideCenter) < closestSuDistance) {
                    closestSuDistance = Math.abs(entry.v - sulfideCenter);
                    suIndex = index;
                }
            });

        return [u1Index, oxIndex, u2Index, suIndex];
    }

    const getSilverVals = (filteredData: { time: number, v: number }[]) => {
        const chlorineCenter =
            (SILVER_CHLORINE_START +
                ((SILVER_CHLORINE_END - SILVER_CHLORINE_START) / 2)) *
            voltageScale + voltageDivisor;

        const sulphorCenter =
            (SILVER_SULPHOR_START +
                ((SILVER_SULPHOR_END - SILVER_SULPHOR_START) / 2)) *
            voltageScale + voltageDivisor;

        let closestChDistance = Number.POSITIVE_INFINITY;
        let chIndex = 0;
        let closestSUDistance = Number.POSITIVE_INFINITY;
        let suIndex = 0;

        filteredData.forEach(
            (entry, index) => {
                if (Math.abs(entry.v - chlorineCenter) < closestChDistance) {
                    closestChDistance = Math.abs(entry.v - chlorineCenter);
                    chIndex = index;
                }

                if (Math.abs(entry.v - sulphorCenter) < closestSUDistance) {
                    closestSUDistance = Math.abs(entry.v - sulphorCenter);
                    suIndex = index;
                }
            });

        return [chIndex, suIndex];
    }

    const drawCopperVals = (filteredData: { time: number, v: number }[], values: number[]) => {
        if (ctx) {
            ctx.font = '35px Arial';
            ctx.textAlign = 'left';
            values.forEach(
                (entry: number, index: number) => {
                    ctx.strokeStyle = "white";

                    ctx.fillText(
                        index === 0 ? "CuU1" :
                            index === 1 ? "Cu2O" :
                                index === 2 ? "CuU2" :
                                    "Cu2S",
                        getXPosition(filteredData[entry].v, false) - 40,
                        getYPosition(filteredData[entry].time, false) - 30);

                    drawX(
                        getXPosition(filteredData[entry].v, false),
                        getYPosition(filteredData[entry].time, false),
                        INF_POINT_SIZE);
                });
        }
    };

    const drawSilverVals = (filteredData: { time: number, v: number }[], values: number[]) => {
        if (ctx) {
            ctx.font = '35px Arial';
            ctx.textAlign = 'left';
            values.forEach(
                (entry: number, index: number) => {
                    ctx.strokeStyle = "white";
                    ctx.fillText(
                        index === 0 ? "AgCL" :
                            "Ag2S",
                        getXPosition(filteredData[entry].v, true) - 40,
                        getYPosition(filteredData[entry].time, true) - 30);

                    drawX(
                        getXPosition(filteredData[entry].v, true),
                        getYPosition(filteredData[entry].time, true),
                        INF_POINT_SIZE);
                });
        }
    };

    const enableManualCalculation = () => {
        if (!manuallyCalculated) {
            setManuallyCalculatedCuU(getCalculatedCuU());
            setManuallyCalculatedCu2O(getCalculatedCu2O());
            setManuallyCalculatedCu2S(getCalculatedCu2S());
            setManuallyCalculatedAgCL(getCalculatedAgCL());
            setManuallyCalculatedAg2S(getCalculatedAg2S());
            setManuallyCalculated(true);
        }
    };

    const disableManualCalculation = () => {
        setManuallyCalculated(false);
    }

    const drawGrid = () => {
        if (ctx) {
            ctx.font = '35px Arial';
            ctx.textAlign = 'left';

            let lineCount = 0;
            let boldEvery = 5;

            for (var seconds = 0; seconds <= (CANVAS_HEIGHT / timeScale); seconds += 85) { // time line is 1 minute 40 seconds
                if (lineCount === boldEvery) {
                    lineCount = 0;
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
                    ctx.lineWidth = 8;

                    ctx.fillText(
                        Math.floor(seconds / 60).toFixed(2) + " Min.",
                        55,
                        getYPosition(seconds) + 40);
                }
                else {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
                    ctx.lineWidth = 2;
                }

                ctx.beginPath();
                ctx.moveTo(
                    0,
                    getYPosition(seconds));
                ctx.lineTo(
                    CANVAS_WIDTH,
                    getYPosition(seconds));
                ctx.stroke();

                lineCount++;
            }

            const voltMax = 2;
            const voltIncrement = 0.02;
            lineCount = 0;
            boldEvery = 50;

            for (var volts = 0; volts <= voltMax * voltageScale; volts += (voltIncrement * voltageScale)) {
                const linePercentage = Math.floor(((((volts / voltageScale) * 2) * 100) / 4));

                if (lineCount === boldEvery) {
                    lineCount = 0;
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
                    ctx.lineWidth = 8;

                    ctx.strokeStyle = "white";
                    ctx.fillText(
                        linePercentage.toString(),
                        getXPosition(volts, false, false) + 10,
                        30);

                    ctx.fillText(
                        linePercentage.toString(),
                        getXPosition(volts, false, false) + 10,
                        CANVAS_HEIGHT - 10);
                }
                else if (lineCount % 5 === 0) {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
                    ctx.lineWidth = 2;

                    ctx.strokeStyle = "white";
                    ctx.fillText(
                        linePercentage.toString(),
                        getXPosition(volts, false, false) + 10,
                        30);

                    ctx.fillText(
                        linePercentage.toString(),
                        getXPosition(volts, false, false) + 10,
                        CANVAS_HEIGHT - 10);
                }
                else {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
                    ctx.lineWidth = 1;
                }

                ctx.beginPath();
                ctx.moveTo(
                    getXPosition(volts, false, false),
                    0);
                ctx.lineTo(
                    getXPosition(volts, false, false),
                    CANVAS_HEIGHT);
                ctx.stroke();

                lineCount++;
            }
            /*
                    // copper
                    if (DRAW_COPPER_RANGES) {
                        // UNKNOWN 1
                        ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
                        ctx.fillRect(
                            getXPosition(COPPER_UKNOWN_1_START * voltageScale, false, false),
                            0,
                            getXPosition(COPPER_UKNOWN_1_END * voltageScale, false, false) - getXPosition(COPPER_UKNOWN_1_START * voltageScale, false, false),
                            CANVAS_HEIGHT);
            
                        // Oxide Cu20
                        ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
                        ctx.fillRect(
                            getXPosition(COPPER_OXIDE_START * voltageScale, false, false),
                            0,
                            getXPosition(COPPER_OXIDE_END * voltageScale, false, false) - getXPosition(COPPER_OXIDE_START * voltageScale, false, false),
                            CANVAS_HEIGHT);
            
                        // UNKNOWN 2
                        ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
                        ctx.fillRect(
                            getXPosition(COPPER_UNKNOWN_2_START * voltageScale, false, false),
                            0,
                            getXPosition(COPPER_UNKNOWN_2_END * voltageScale, false, false) - getXPosition(COPPER_UNKNOWN_2_START * voltageScale, false, false),
                            CANVAS_HEIGHT);
            
                        // sulfide Cu2S
                        ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
                        ctx.fillRect(
                            getXPosition(COPPER_SULFIDE_START * voltageScale, false, false),
                            0,
                            getXPosition(COPPER_SULFIDE_END * voltageScale, false, false) - getXPosition(COPPER_SULFIDE_START * voltageScale, false, false),
                            CANVAS_HEIGHT);
                    }
            
                    // silver
                    if (DRAW_SILVER_RANGES) {
                        // Chlorine AgCl
                        ctx.fillStyle = "rgba(0, 255, 0, 0.1)";
                        ctx.fillRect(
                            getXPosition(SILVER_CHLORINE_START * voltageScale, false, false),
                            0,
                            getXPosition(SILVER_CHLORINE_END * voltageScale, false, false) - getXPosition(SILVER_CHLORINE_START * voltageScale, false, false),
                            CANVAS_HEIGHT);
            
                        // Sulphor Ag2S
                        ctx.fillStyle = "rgba(0, 255, 0, 0.1)";
                        ctx.fillRect(
                            getXPosition(SILVER_SULPHOR_START * voltageScale, false, false),
                            0,
                            getXPosition(SILVER_SULPHOR_END * voltageScale, false, false) - getXPosition(SILVER_SULPHOR_START * voltageScale, false, false),
                            CANVAS_HEIGHT);
                    }
                    */
        }
    }

    /* deprecated for now
        const getInflectionPointIndices = (data: { time: number, v: number }[]) => {
            // TODO: This needs to average the angle over some number of points so we're not getting
            // tripped up by really quick jerks between points
            const result = [];
            let polarity = true;
            let prevDiff = 0;
            let prevAngle = 0;
            let wasPositive = true;
    
            // first pass 
            let firstPass = [];
    
            for (var i = 1; i < data.length; i++) {
                const sampledValue = data[i].v;
                const lastSampledValue = data[i - 1].v;
    
                if (Math.floor(sampledValue) === Math.floor(lastSampledValue)) {
                    continue
                }
    
                const valDiff = sampledValue - lastSampledValue;
                const newAngle = getAngle(lastSampledValue, data[i - 1].time, sampledValue, data[i].time);
                const newDiff = prevAngle - newAngle;
    
                if (Math.floor(newDiff) === 0) {
                    continue
                }
    
                if (Math.abs(prevDiff - newDiff) > INF_CHANGE_TOLERANCE) {
    
                    if (newDiff < 0 && wasPositive) {
                        // currently ignoring negative changes
                        
                        // firstPass.push({
                        //     index: i - 1,
                        //     polarity: false,
                        //     diff: valDiff,
                        //     angle: newDiff
                        // });
        
                        // console.log("-", Math.floor(newDiff), Math.floor(newAngle), Math.floor(prevAngle));
                        
                        wasPositive = false;
                    }
                    else if (newDiff > 0 && !wasPositive) {
                        firstPass.push({
                            index: i - 1,
                            polarity: true,
                            diff: valDiff,
                            angle: newDiff
                        });
    
                        wasPositive = true;
                    }
    
                    prevDiff = newDiff;
                    prevAngle = newAngle;
                }
            }
    
            return firstPass;
        }
    */

    const drawX = (x: number, y: number, size: number) => {
        if (ctx) {
            ctx.beginPath();

            ctx.moveTo(
                x - size,
                y - size);
            ctx.lineTo(
                x + size,
                y + size);

            ctx.moveTo(
                x + size,
                y - size);
            ctx.lineTo(
                x - size,
                y + size);

            ctx.stroke();
        }
    }

    const drawInflectionPoints = (inflectionPointIndices: { index: number, polarity: boolean }[], actualData: { time: number, v: number }[], isSilver = false) => {
        if (ctx) {
            ctx.lineWidth = 2;
            inflectionPointIndices.forEach(
                entry => {
                    ctx.strokeStyle = entry.polarity ? "green" : "red";

                    drawX(
                        getXPosition(actualData[entry.index].v, isSilver),
                        getYPosition(actualData[entry.index].time, isSilver),
                        INF_POINT_SIZE);
                });
        }
    }

    const getXPosition = (val: number, isSilver = false, honorDivisor = true) => {
        return (val - ((honorDivisor ? voltageDivisor : 0) * voltageScale)) * (isSilver ? SILVER_V_MULTIPLY : 1) + filterOffset;
    }

    const getYPosition = (val: number, isSilver = false) => {
        return CANVAS_HEIGHT - ((val * timeScale) + timeOffset + (isSilver ? SILVER_Y_OFFSET : 0));
    }

    const getXSampleAverage = (data: any[], sampleCount: number, propertyKey: string, startIndex: number) => {
        let runningValue = 0;

        if (startIndex < Math.floor(sampleCount / 2)) {
            return 0;
        }

        for (var i = startIndex - Math.floor(sampleCount / 2); i < startIndex + Math.floor(sampleCount / 2) && i < data.length; i++) {
            runningValue += data[i][propertyKey];
        }

        return runningValue / sampleCount;
    }

    const generateFilteredArray = (data: { time: number, v: number }[]) => {
        const result = [{
            time: data[0].time,
            v: data[0].v
        }];

        for (var i = Math.floor(FILTER_AMOUNT / 2); i < data.length - Math.floor(FILTER_AMOUNT / 2); i++) {
            result.push({
                time: data[i].time,
                v: getXSampleAverage(data, FILTER_AMOUNT, "v", i)
            });
        }

        result.push({
            time: data[data.length - 1].time,
            v: data[data.length - 1].v
        });

        return result;
        //        return reduceData(result);
    }

    const drawIndividualGraph = (data: { time: number, v: number }[], color: string, offset = false) => {
        if (ctx) {
            data.forEach(
                (entry, index) => {
                    if (index === 0) {
                        lastVX = 0;
                        lastVY = 0;
                    }

                    //var speed = Math.floor((entry.v - lastVX));
                    ctx.strokeStyle = color; //speed > 3 ? "white" : color;

                    ctx.beginPath();
                    ctx.moveTo(
                        getXPosition(lastVX, offset),
                        getYPosition(lastVY, offset));
                    ctx.lineTo(
                        getXPosition(entry.v, offset),
                        getYPosition(entry.time, offset));
                    ctx.stroke();

                    lastVX = entry.v;
                    lastVY = entry.time;
                });

            // draw final line back to zero
            ctx.strokeStyle = color;
            ctx.beginPath();
            ctx.moveTo(
                getXPosition(lastVX, offset),
                getYPosition(lastVY, offset));
            ctx.lineTo(
                getXPosition(0, offset),
                getYPosition(lastVY, offset));
            ctx.stroke();
        }
    }

    const drawGraphs = () => {
        if (ctx) {
            ctx.lineWidth = ORIGINAL_LINE_THICKNESS;

            // silver
            if (testData1.length > 0) {
                drawIndividualGraph(
                    testData1,
                    "white",
                    true);
            }

            // copper
            if (testData2.length > 0) {
                drawIndividualGraph(
                    testData2,
                    "white",
                    false);
            }

            ctx.lineWidth = FILTERED_LINE_THICKNESS;

            if (
                testData1.length + testData2.length > 0 && (
                    FILTER_AMOUNT !== lastFilterAmount ||
                    SIMPLIFY_TOLERANCE !== lastSimplificationTolerance ||
                    INF_CHANGE_TOLERANCE !== lastInflectionChangeTolerance ||
                    voltageDivisor !== lastVoltageDivisor)) {
                console.log("Settings changed, regenerating filtered data");

                if (testData1.length > 0) {
                    filteredData1 = generateFilteredArray(testData1);
                    //v1Points = getInflectionPointIndices(filteredData1);

                    silverVals = getSilverVals(filteredData1);
                    setSilverValues(silverVals.map(v => filteredData1[v].time));
                }

                if (testData2.length > 0) {
                    filteredData2 = generateFilteredArray(testData2);
                    //v2Points = getInflectionPointIndices(filteredData2);

                    copperVals = getCopperVals(filteredData2);
                    setCopperValues(copperVals.map(v => filteredData2[v].time));
                }

                lastFilterAmount = FILTER_AMOUNT;
                lastSimplificationTolerance = SIMPLIFY_TOLERANCE;
                lastInflectionChangeTolerance = INF_CHANGE_TOLERANCE;
                lastVoltageDivisor = voltageDivisor;
            }

            ctx.lineWidth = FILTERED_LINE_THICKNESS;

            // silver
            if (filteredData1.length > 0) {
                drawIndividualGraph(filteredData1, "green", true);

                if (v1Points.length > 0) {
                    //drawInflectionPoints(v1Points, filteredData1, true);
                }

                if (silverVals.length > 0) {
                    drawSilverVals(filteredData1, silverVals);
                }
            }

            // copper
            if (filteredData2.length > 0) {
                drawIndividualGraph(filteredData2, "red", false);

                if (v2Points.length > 0) {
                    //drawInflectionPoints(v2Points, filteredData2, false);
                }

                if (copperVals.length > 0) {
                    drawCopperVals(filteredData2, copperVals);
                }
            }

            ctx.lineWidth = 4;
        }
    }

    const getCalculatedCuU = () => {
        if (copperValues && copperValues.length === 4) {
            return roundTo3Digits(((copperValues[0] + copperValues[2]) * DEFAULT_TIME_VALUE_ADJUST) / 100);
        }

        return 0;
    };

    const getCalculatedCu2O = () => {
        if (copperValues && copperValues.length === 4) {
            return roundTo3Digits(((copperValues[1] - copperValues[0]) * DEFAULT_TIME_VALUE_ADJUST) / 100);
        }

        return 0;
    };

    const getCalculatedCu2S = () => {
        if (copperValues && copperValues.length === 4) {
            return roundTo3Digits(((copperValues[3] - copperValues[2]) * DEFAULT_TIME_VALUE_ADJUST) / 100);
        }

        return 0;
    };

    const getCalculatedAgCL = () => {
        if (copperValues && copperValues.length === 4) {
            return roundTo3Digits((silverValues[0] * DEFAULT_TIME_VALUE_ADJUST) / 100);
        }

        return 0;
    };

    const getCalculatedAg2S = () => {
        if (copperValues && copperValues.length === 4) {
            return roundTo3Digits(((silverValues[1] - silverValues[0]) * DEFAULT_TIME_VALUE_ADJUST) / 100);
        }

        return 0;
    };

    useEffect(
        () => {
            (async () => {
                const stageCanvas = document.getElementById("canvas") as HTMLCanvasElement;
                stageCanvas.width = CANVAS_WIDTH;
                stageCanvas.height = CANVAS_HEIGHT;
                const stageCTX = stageCanvas.getContext("2d");

                if (stageCTX) {
                    stageCTX.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
                    stageCTX.fillStyle = "white";
                    stageCTX.strokeStyle = "white";
                    setCanvas(stageCanvas);
                    setCTX(stageCTX);

                    /*
                    let previewFullscreen = false;
                    stageCanvas.addEventListener(
                        "click",
                        () => {
                            // if (previewFullscreen) {
                            //     stageCanvas.style.width = `${window.innerWidth}px`;
                            //     stageCanvas.style.height = `${window.innerHeight}px`;
                            //     stageCanvas.style.position = "absolute";
                            //     stageCanvas.style.top = "0";
                            //     stageCanvas.style.left = "0";
                            //     stageCanvas.style.zIndex = "99999";
                            // }
                            // else {
                            //     stageCanvas.style.width = `100%`;
                            //     stageCanvas.style.height = `inherit`;
                            //     stageCanvas.style.position = "relative";
                            //     stageCanvas.style.top = "inherit";
                            //     stageCanvas.style.left = "inherit";
                            //     stageCanvas.style.zIndex = "0";
                            // }

                            // drawGrid();
                            // drawGraphs();

                            if (previewFullscreen) {
                                // @ts-ignore
                                stageCanvas.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                                // @ts-ignore
                                //stageCanvas.mozRequestFullScreen();
                            }
                            else {
                                // @ts-ignore
                                document.webkitCancelFullScreen();
                                // @ts-ignore
                                //document.mozCancelFullScreen();
                            }
                            previewFullscreen = !previewFullscreen;
                        });
                        */
                }

                globalContext.setLoading(true);
                const accessToken = await globalContext.getAccessTokenAsync();
                const result = await PanelAquisitionService.GetAsync(accessToken, aquisitionId);
                let aquisitionData: any | undefined = undefined;

                if (result && result.record) {
                    result.record.notes = result.record.notes.trim();
                    setPanelAquisition(result.record);

                    try {
                        const bumpData = JSON.parse((result.record.overrides as any)).numberBumps;
                        console.log("bumpData is ", bumpData);
                        setNumberBumps(bumpData);
                        COPPER_UKNOWN_1_START = COPPER_UKNOWN_1_START + bumpData[0];
                        COPPER_UKNOWN_1_END = COPPER_UKNOWN_1_END + bumpData[0];
                        COPPER_OXIDE_START = COPPER_OXIDE_START + bumpData[1];
                        COPPER_OXIDE_END = COPPER_OXIDE_END + bumpData[1];
                        COPPER_UNKNOWN_2_START = COPPER_UNKNOWN_2_START + bumpData[2];
                        COPPER_UNKNOWN_2_END = COPPER_UNKNOWN_2_END + bumpData[2];
                        COPPER_SULFIDE_START = COPPER_SULFIDE_START + bumpData[3];
                        COPPER_SULFIDE_END = COPPER_SULFIDE_END + bumpData[3];
                        SILVER_CHLORINE_START = SILVER_CHLORINE_START + bumpData[4];
                        SILVER_CHLORINE_END = SILVER_CHLORINE_END + bumpData[4];
                        SILVER_SULPHOR_START = SILVER_SULPHOR_START + bumpData[5];
                        SILVER_SULPHOR_END = SILVER_SULPHOR_END + bumpData[5];
                    }
                    catch (e) {
                        console.log("Unable to constitute number bump data.", e);
                    }

                    try {
                        const graphData = JSON.parse((result.record.overrides as any)).graphData;
                        console.log("graphData is ", graphData);

                        if (graphData.voltageDivisor) {
                            setVoltageDivisor(graphData.voltageDivisor);
                        }

                        if (graphData.voltageScale) {
                            setVoltageScale(graphData.voltageScale);
                        }

                        if (graphData.timeScale) {
                            setTimeScale(graphData.timeScale);
                        }
                    }
                    catch (e) {
                        console.log("Unable to constitute graph data.", e);
                    }

                    try {
                        const finalValues = JSON.parse((result.record.overrides as any)).finalValues;
                        console.log("finalValues is ", finalValues);

                        if (finalValues) {
                            if (finalValues.manuallyCalculated) {
                                setManuallyCalculatedCuU(finalValues.manuallyCalculatedCuU);
                                setManuallyCalculatedCu2S(finalValues.manuallyCalculatedCu2S);
                                setManuallyCalculatedCu2O(finalValues.manuallyCalculatedCu2O);
                                setManuallyCalculatedAgCL(finalValues.manuallyCalculatedAgCL);
                                setManuallyCalculatedAg2S(finalValues.manuallyCalculatedAg2S);
                                setManuallyCalculated(finalValues.manuallyCalculated);
                            }
                        }
                    }
                    catch (e) {
                        console.log("Unable to constitute final data");
                    }

                    aquisitionData = await fetchGetAsync<any>(result.record.resultJsonurl.trim(), {}, undefined, {}, true);
                }
                else {
                    globalContext.showErrorMessage("Unable to load Panel Aquisition data");
                }

                globalContext.setLoading(false);

                if (aquisitionData) {
                    setPanelAquisitionData(aquisitionData);
                }

                window.setTimeout(function () {
                    // @ts-ignore
                    document.getElementById("voltageScalePlus").click();
                }, 300);

                window.setTimeout(function () {
                    // @ts-ignore
                    document.getElementById("voltageScaleMinus").click();
                }, 500);
            })();
        }, []);

    useEffect(
        () => {
            console.log("voltageScale " + voltageScale);
            console.log("timeScale " + timeScale);
            if (panelAquisitionData) {
                processAquisition(panelAquisitionData);
            }
        },
        [panelAquisitionData, voltageScale]);

    useEffect(
        () => {
            if (ctx) {
                ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

                if (testData1.length + testData2.length > 0) {
                    console.log("Redrawing");
                    drawGrid();
                    drawGraphs();
                }
            }
        },
        [ctx, testData1, testData2, timeOffset, filterOffset, voltageDivisor, voltageScale, timeScale, numberBumps]);

    const roundTo3Digits = (value: number) => {
        return Math.round((value + Number.EPSILON) * 1000) / 1000;
    }

    const numberBumpHandler = (event: React.KeyboardEvent, index: number) => {
        const NUMBER_BUMP_AMOUNT = 0.01;

        if (event.key === "ArrowDown") {
            numberBumps[index] -= NUMBER_BUMP_AMOUNT;
        }
        else if (event.key === "ArrowUp") {
            numberBumps[index] += NUMBER_BUMP_AMOUNT;
        }

        switch (index) {
            case 0:
                COPPER_UKNOWN_1_START = DEFAULT_COPPER_UKNOWN_1_START + numberBumps[0];
                COPPER_UKNOWN_1_END = DEFAULT_COPPER_UKNOWN_1_END + numberBumps[0];
                break;

            case 1:
                COPPER_OXIDE_START = DEFAULT_COPPER_OXIDE_START + numberBumps[1];
                COPPER_OXIDE_END = DEFAULT_COPPER_OXIDE_END + numberBumps[1];
                break;

            case 2:
                COPPER_UNKNOWN_2_START = DEFAULT_COPPER_UNKNOWN_2_START + numberBumps[2];
                COPPER_UNKNOWN_2_END = DEFAULT_COPPER_UNKNOWN_2_END + numberBumps[2];
                break;

            case 3:
                COPPER_SULFIDE_START = DEFAULT_COPPER_SULFIDE_START + numberBumps[3];
                COPPER_SULFIDE_END = DEFAULT_COPPER_SULFIDE_END + numberBumps[3];
                break;

            case 4:
                SILVER_CHLORINE_START = DEFAULT_SILVER_CHLORINE_START + numberBumps[4];
                SILVER_CHLORINE_END = DEFAULT_SILVER_CHLORINE_END + numberBumps[4];
                break;

            case 5:
                SILVER_SULPHOR_START = DEFAULT_SILVER_SULPHOR_START + numberBumps[5];
                SILVER_SULPHOR_END = DEFAULT_SILVER_SULPHOR_END + numberBumps[5];
                break;
        }

        setNumberBumps([...numberBumps]);
    }

    return (
        <Container>
            <Row>
                <h2>Aquisition for Panel number {panelAquisition?.panelId} [{(panelAquisition?.approvedBy || "").trim() ? <span style={{ backgroundColor: "rgba(0,255,0,0.4)" }}><FontAwesomeIcon icon={faCircleCheck as IconProp} /> APPROVED</span> : <span style={{ backgroundColor: "rgba(255,0,0,0.4)" }}>NEEDS APPROVAL</span>}]</h2>
            </Row>
            <Row>
                <Col >
                    <canvas id="canvas" width="800" height="600" style={{ backgroundColor: "#000", width: "100%" }}></canvas><br />
                    {
                        // voltageScale, timeScale
                    }
                    <Row className="pb-4">
                        <Col className="text-center">
                            Voltage Scale<br />
                            <Button id="voltageScalePlus" className="m-1" onClick={() => { console.log("clicked"); setVoltageScale(voltageScale + 50); }}><FontAwesomeIcon icon={faCirclePlus as IconProp} /></Button>
                            <Button id="voltageScaleMinus" className="m-1" onClick={() => { if (voltageScale > 50) { setVoltageScale(voltageScale - 50) } }}><FontAwesomeIcon icon={faCircleMinus as IconProp} /></Button>
                        </Col>
                        <Col className="text-center">
                            Time Scale<br />
                            <Button className="m-1" onClick={() => setTimeScale(timeScale + 0.2)}><FontAwesomeIcon icon={faCirclePlus as IconProp} /></Button><Button className="m-1" onClick={() => { if (timeScale > 0.2) { setTimeScale(timeScale - 0.2) } }}><FontAwesomeIcon icon={faCircleMinus as IconProp} /></Button>
                        </Col>
                        <Col className="text-center">
                            Voltage Shift<br />
                            <Button className="m-1" onClick={() => setVoltageDivisor(voltageDivisor - 0.01)}><FontAwesomeIcon icon={faCirclePlus as IconProp} /></Button><Button className="m-1" onClick={() => { setVoltageDivisor(voltageDivisor + 0.01) }}><FontAwesomeIcon icon={faCircleMinus as IconProp} /></Button>
                        </Col>
                    </Row>
                    {
                        (panelAquisition?.panelImageUrl || "").trim() &&
                        <img alt="Panel Image" src={panelAquisition?.panelImageUrl} style={{ width: "100%" }} />
                    }
                    {
                        !(panelAquisition?.panelImageUrl || "").trim() &&
                        <p style={{ width: "100%", textAlign: "center", fontSize: "2em", fontWeight: "bold" }}>[ User will attach image later ]</p>
                    }
                </Col>
                <Col xs={4}>
                    <Alert key="dark" variant="dark" className="quick-tip">
                        <FontAwesomeIcon icon={faCircleInfo as IconProp} /> Click inside a raw value box and press up or down keys to bump the graph marker and recalculate.
                    </Alert>
                    <div className="raw-values">
                        <h2>Raw Values</h2>
                        <Form>
                            <h3>Copper</h3>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Unknown 1 (CuU1)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={copperValues && copperValues.length > 0 ? roundTo3Digits((copperValues[0] * DEFAULT_TIME_VALUE_ADJUST) / 100) : "?"}
                                            onKeyDown={(event: React.KeyboardEvent) => numberBumpHandler(event, 0)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Oxide (Cu2O)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={copperValues && copperValues.length > 1 ? roundTo3Digits((copperValues[1] * DEFAULT_TIME_VALUE_ADJUST) / 100) : "?"}
                                            onKeyDown={(event: React.KeyboardEvent) => numberBumpHandler(event, 1)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Unknown 2 (CuU2)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={copperValues && copperValues.length > 2 ? roundTo3Digits((copperValues[2] * DEFAULT_TIME_VALUE_ADJUST) / 100) : "?"}
                                            onKeyDown={(event: React.KeyboardEvent) => numberBumpHandler(event, 2)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Sulphide (Cu2S)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={copperValues && copperValues.length > 3 ? roundTo3Digits((copperValues[3] * DEFAULT_TIME_VALUE_ADJUST) / 100) : "?"}
                                            onKeyDown={(event: React.KeyboardEvent) => numberBumpHandler(event, 3)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr />

                            <h3>Silver</h3>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Chlorine (AgCL)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={silverValues && silverValues.length > 0 ? roundTo3Digits((silverValues[0] * DEFAULT_TIME_VALUE_ADJUST) / 100) : "?"}
                                            onKeyDown={(event: React.KeyboardEvent) => numberBumpHandler(event, 4)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Sulphide (Ag2S)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={silverValues && silverValues.length > 1 ? roundTo3Digits((silverValues[1] * DEFAULT_TIME_VALUE_ADJUST) / 100) : "?"}
                                            onKeyDown={(event: React.KeyboardEvent) => numberBumpHandler(event, 5)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className={`final-values ${manuallyCalculated ? "manually-calculated" : ""}`}>
                        <h2>{manuallyCalculated ? "Manually " : ""}Calculated in CM.</h2>
                        {
                            manuallyCalculated &&
                            <Button
                                className="mb-3"
                                variant="primary"
                                size="lg"
                                onClick={() => disableManualCalculation()}
                            >Clear Manual Calculations</Button>
                        }
                        <Form>
                            <h3>Copper</h3>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Unknown</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                manuallyCalculated ?
                                                    manuallyCalculatedCuU :
                                                    getCalculatedCuU() || "?"
                                            }
                                            onChange={
                                                event => {
                                                    enableManualCalculation();
                                                    setManuallyCalculatedCuU(parseFloat(event.target.value || "0"));
                                                }
                                            } />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Oxide (Cu2O)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                manuallyCalculated ?
                                                    manuallyCalculatedCu2O :
                                                    getCalculatedCu2O() || "?"
                                            }
                                            onChange={
                                                event => {
                                                    enableManualCalculation();
                                                    setManuallyCalculatedCu2O(parseFloat(event.target.value || "0"));
                                                }
                                            } />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Sulphide (Cu2S)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                manuallyCalculated ?
                                                    manuallyCalculatedCu2S :
                                                    getCalculatedCu2S() || "?"
                                            }
                                            onChange={
                                                event => {
                                                    enableManualCalculation();
                                                    setManuallyCalculatedCu2S(parseFloat(event.target.value || "0"));
                                                }
                                            } />
                                    </Form.Group>
                                </Col>
                                <Col>&nbsp;</Col>
                            </Row>

                            <hr />
                            <h3>Silver</h3>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Chlorine (AgCL)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                manuallyCalculated ?
                                                    manuallyCalculatedAgCL :
                                                    getCalculatedAgCL() || "?"
                                            }
                                            onChange={
                                                event => {
                                                    enableManualCalculation();
                                                    setManuallyCalculatedAgCL(parseFloat(event.target.value || "0"));
                                                }
                                            } />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Sulphide (Ag2S)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                manuallyCalculated ?
                                                    manuallyCalculatedAg2S :
                                                    getCalculatedAg2S() || "?"
                                            }
                                            onChange={
                                                event => {
                                                    enableManualCalculation();
                                                    setManuallyCalculatedAg2S(parseFloat(event.target.value || "0"));
                                                }
                                            } />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <hr />
                        <h2>Approval</h2>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Initials</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    // @ts-ignore
                                                    setPanelAquisition({ ...panelAquisition, approvedBy: e.target.value });
                                                }
                                            }
                                            value={(panelAquisition?.approvedBy || "").trim()}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approval Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    // @ts-ignore
                                                    setPanelAquisition({ ...panelAquisition, approvalDate: e.target.value });
                                                }
                                            }
                                            value={formatDate(panelAquisition?.approvalDate || new Date())}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" >
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                            // @ts-ignore
                                            setPanelAquisition({ ...panelAquisition, notes: e.target.value });
                                        }
                                    }
                                    value={(panelAquisition?.notes || "")}
                                />
                            </Form.Group>
                            <Button
                                variant="primary"
                                size="lg"
                                onClick={() => {
                                    (async () => {
                                        if (panelAquisition) {
                                            globalContext.setLoading(true);
                                            const accessToken = await globalContext.getAccessTokenAsync();

                                            const data: any = {
                                                PanelAquisitionId: panelAquisition?.panelAquisitionId,
                                                ApprovalDate: (new Date(panelAquisition?.approvalDate) || new Date()).toISOString(),
                                                ApprovedBy: (panelAquisition?.approvedBy || "").trim(),
                                                Notes: (panelAquisition?.notes || "").trim(),
                                                Overrides: JSON.stringify({
                                                    numberBumps,
                                                    graphData: {
                                                        voltageDivisor,
                                                        voltageScale,
                                                        timeScale
                                                    },
                                                    finalValues: {
                                                        manuallyCalculated,
                                                        manuallyCalculatedCuU,
                                                        manuallyCalculatedCu2O,
                                                        manuallyCalculatedCu2S,
                                                        manuallyCalculatedAgCL,
                                                        manuallyCalculatedAg2S,
                                                        calculatedCuU: getCalculatedCuU(),
                                                        calculatedCu2O: getCalculatedCu2O(),
                                                        calculatedCu2S: getCalculatedCu2S(),
                                                        calculatedAgCL: getCalculatedAgCL(),
                                                        getCalculatedAg2S: getCalculatedAg2S()
                                                    }
                                                })
                                            };

                                            await PanelAquisitionService.ApproveAsync(accessToken, data);
                                            globalContext.setLoading(false);
                                            //window.location.reload();
                                            //history.push("/CCCPanelAquisitionLookup/");
                                            document.location.href = "/CCCPanelAquisitionLookup/";
                                        }
                                    })();
                                }}>
                                {(panelAquisition?.approvedBy || "").trim() ? "Save Changes" : "Save and Approve"}
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container >
    );
}

export default CCCPanelAquisitionLookupDetail;
