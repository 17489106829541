import "../styles/CCCKiosk.scss";

import { useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { appStates, IKioskSceneBaseProps } from "../Index";
import useLocalStorage from "react-use-localstorage";

const Idle = (props: IKioskSceneBaseProps) => {
    const [submissionData, setSubmissionData] = useLocalStorage('submissionData', JSON.stringify({}));

    const convertToTestData = (data?: any) => {
        const resultStrip1 = [];
        const resultStrip2 = [];
        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                resultStrip1.push({
                    time: data[i].t,
                    value: data[i].v1
                });

                resultStrip2.push({
                    time: data[i].t,
                    value: data[i].v2
                });
            }
        }

        return {
            strip1: resultStrip1,
            strip2: resultStrip2
        };
    };

    useEffect(
        () => {
            if (submissionData) {
                const parsedSubmissionData = JSON.parse(submissionData);
                if (parsedSubmissionData.hasOwnProperty("ResultJSON")) {
                    if (window.confirm("There appears to be unsubmitted test data, resubmit now?\r\n(Caution: Cancelling will throw out the data.)")) {
                        try {
                            props.setPhoto(parsedSubmissionData.PanelImage);
                            props.setStrip1MaterialType(parsedSubmissionData.Strip1MaterialType);
                            props.setStrip1MaterialType(parsedSubmissionData.Strip1MaterialType);
                            props.setCouponNumber(parsedSubmissionData.PanelId);
                            props.setStrip1SizeType(parsedSubmissionData.Strip1SizeType);
                            props.setStrip2SizeType(parsedSubmissionData.Strip2SizeType);
                            props.setStrip1BaseMaterialType(parsedSubmissionData.Strip1BaseMaterialType);
                            props.setStrip2BaseMaterialType(parsedSubmissionData.Strip2BaseMaterialType);
                            props.setTestData(
                                convertToTestData(
                                    JSON.parse(parsedSubmissionData.ResultJSON)));
                            props.setAppState(appStates.Complete);
                        }
                        catch (e: any) {
                            window.alert("Unable to unpack previous test data, abandoning. Please notify Jeff or Erik.");
                            setSubmissionData(JSON.stringify({}));
                        }
                    }
                    else {
                        setSubmissionData(JSON.stringify({}));
                    }
                }
            }
        }, [submissionData]);

    return (
        <Row className="screen idle-screen">
            <Button
                className="begin"
                onClick={() => {
                    props.setAppState(appStates.Photo);
                }}>TOUCH TO<br />BEGIN</Button>
        </Row>
    );
}

export default Idle