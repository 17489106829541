
import { Button, Container } from "react-bootstrap";
import logo from 'assets/images/Purafil-Systems.png';
import { IPublicClientApplication } from "@azure/msal-browser";

export interface LoggedOutContentProps {
    handleLogin: (any);
    instance: IPublicClientApplication;
}

const LoggedOutContent = (props: LoggedOutContentProps) => {
    return (<>
        <Container className="p-5 mb-4  text-center login-container">
            <h1 className="header login-header">
                <img
                    src={logo}
                    alt="Purafil"
                    width="250" />
            </h1><br /><br />
            <h2 className="login-content">
                Welcome, please <Button variant="secondary" className="ml-auto" onClick={() => props.handleLogin(props.instance)}>Sign in</Button>
            </h2>
        </Container>
    </>);
}

export default LoggedOutContent;
