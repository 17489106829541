
import { GlobalContext } from 'components/GlobalContext';
import { useContext, useEffect, useRef } from 'react';
import { Navbar } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import PurapikOrdersLookup from './Lookup';
import './styles/PurapikOrders.scss';

const PurapikOrdersIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;

    useEffect(() => {
        globalContextRef.current.setSecondaryNav(<>
            <Navbar.Brand href="/PurapikOrders"><strong>Purapik Orders</strong></Navbar.Brand>
        </>);
    }, []);

    return (
        <>
            <div>
                <Routes>
                    <Route path={"/:page"} element={<PurapikOrdersLookup />} />
                    <Route path={"/*"} element={<PurapikOrdersLookup />} />
                </Routes>
            </div>
        </>
    );
}

export default PurapikOrdersIndex;
