import React, { useEffect } from "react";
import { Pagination } from "react-bootstrap";

export const DEFAULT_TABLE_RECORD_COUNT = 20;

export const generatePagination = (changePageCallback: (newPage: number) => void, page: number, totalRecords: number, recordPerPage: number, itemCount: number = 10): React.ReactElement => {
    const targetPosition = Math.floor(itemCount / 2);
    const pageCount = Math.ceil(totalRecords / recordPerPage);
    var lowPage = Math.max(page - targetPosition, 0);
    var highPage = lowPage + itemCount;

    const reduction = highPage - pageCount;

    if (reduction > 0) {
        lowPage -= reduction;
    }

    const pages =
        Array
            .from(Array(itemCount).keys())
            .map(i => i + lowPage);

    return (
        <Pagination size="sm" className="justify-content-center m-0">
            {
                pageCount > 0 &&
                <>
                    <Pagination.First disabled={page === 0} onClick={() => changePageCallback(0)} />
                    {pageCount > 1 && <Pagination.Prev disabled={pageCount < 1 || page < 1} onClick={() => changePageCallback(page - 1)} />}
                    {/*
                    {page > 1 && <Pagination.Item onClick={() => changePageCallback(0)}>{1}</Pagination.Item>}
                    {page > 4 && <Pagination.Ellipsis />}
                    */}

                    {pages.filter(p => p >= 0 && p < pageCount).map(p => <Pagination.Item key={`page${p}`} active={p === page} onClick={() => changePageCallback(p)}>{p + 1}</Pagination.Item>)}

                    {/*
                    {pageCount > maxNumber && page < pageCount - maxNumber && <Pagination.Ellipsis />}
                    {pageCount > itemCount && <Pagination.Item onClick={() => changePageCallback(pageCount - 1)}>{pageCount}</Pagination.Item>}
                    */}
                    {pageCount > 1 && <Pagination.Next disabled={pageCount < 1 || page >= pageCount} onClick={() => changePageCallback(page + 1)} />}
                    <Pagination.Last disabled={page === pageCount - 1} onClick={() => changePageCallback(pageCount)} />
                </>
            }
        </Pagination>
    );
}

export const useBeforeUnload = (when: boolean, message: string) => {
    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            event.preventDefault()
            event.returnValue = message
            return message
        }

        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload)
        }

        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [when, message])
}