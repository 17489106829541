import ILabRequestEntry from "models/sql/ILabRequestEntry";
import { ILabRequestEntryLogEntry } from "models/sql/ILabRequestTemplate";
import { Table } from "react-bootstrap";
import { formatDateTime } from "utilities/StringUtility";
import parse from 'html-react-parser';

export const typesToSkip = ["blockadd", "javascript", "style", "header", "rowstart", "rowend", "colstart", "colend", "textblock", "tablestart", "tableend", "trstart", "trend", "hidden"]

export const addNewLogEntry = (time: Date, message: string, user: string, type: number, entry: ILabRequestEntry) => {
    const updateEntry: ILabRequestEntry = { ...entry };

    if (!updateEntry.systemDataJson) {
        updateEntry.systemDataJson = "{}";
    }

    const parsedSystemData = JSON.parse(updateEntry.systemDataJson);
    let logMessages: ILabRequestEntryLogEntry[] = parsedSystemData?.logMessages || [];
    logMessages.push({ time, message, user, type });
    updateEntry.systemDataJson = JSON.stringify({ ...parsedSystemData, ...{ logMessages } });

    return updateEntry;
};

export const generateSubmissionResults = (submission: ILabRequestEntry) => {
    let parsedData = JSON.parse(submission.dataJson);
    let finalMarkup = `<div class="row lab-request-form"><div class="col">`;
    let rowStarted = false;
    let colStarted = false;
    let trStarted = false;
    let tableStarted = false;
    let tableWidth = 0;
    let tableIndex = 0;

    const tdcolCheckStart = (data: any) => {
        let markup = "";
        if (data.asTD !== "true") {
            if (data.asCol !== "true") {
                markup += `<div class="row align-items-end">`;
            }

            markup += `<div class="col-sm"><div class="mb-3">`;

            if (data.title) {
                markup += `<label class="form-label">${data.title}</label>`;
            }
        }
        else {
            markup += `<td>`;
        }

        return markup;
    }

    const tdcolCheckEnd = (data: any) => {
        let markup = "";
        if (data.asTD !== "true") {
            if (data.asCol !== "true") {
                markup += `</div>`;
            }

            markup += `</div></div>`;
        }
        else {
            markup += `</td>`;
        }

        return markup;
    }

    const endAll = () => {
        let markup = "";

        if (colStarted) {
            markup += `</div>`;
            colStarted = false;
        }

        if (rowStarted) {
            markup += `</div>`;
            rowStarted = false;
        }

        if (trStarted) {
            markup += `</tr>`;
            trStarted = false;
        }

        if (tableStarted) {
            markup += `</table></div></div>`;
            tableStarted = false;
            tableWidth = 0;
        }

        return markup;
    };

    const getMarkupFromNode = (key: string, parsedData: any, skipBlocks: boolean = true): string => {
        let finalMarkup = "";

        if (skipBlocks && parsedData[key].hasOwnProperty("blockIndex")) {
            return "";
        }

        if (parsedData[key].hideOnDashboard) {
            return "";
        }

        switch (parsedData[key].type) {
            case "header":
                finalMarkup += `<div class="card-title h4 purafilBlueText"><strong>${parsedData[key].title}</strong></div>`;
                break;

            case "rowstart":
                if (colStarted) {
                    finalMarkup += `</div>`;
                    colStarted = false;
                }

                if (rowStarted) {
                    finalMarkup += `</div>`;
                    rowStarted = false;
                }

                rowStarted = true;
                finalMarkup += `<div class="row align-items-end">`;

                // if this is a block then find all matching entries and inject them here
                if (
                    parsedData[key].hasOwnProperty("blockName") &&
                    !parsedData[key].hasOwnProperty("blockIndex")) {
                    const blockName = parsedData[key].blockName;
                    let blockIndex = 0;
                    let found = false;

                    do {
                        found = false;
                        const keys = Object.keys(parsedData);

                        for (var i = 0; i < keys.length; i++) {
                            const key2 = keys[i];
                            if (
                                key2 != key &&
                                parsedData[key2].hasOwnProperty("blockName") &&
                                parsedData[key2].blockName === blockName &&
                                parsedData[key2].hasOwnProperty("blockIndex") &&
                                parsedData[key2].blockIndex === blockIndex.toString()) {
                                found = true;
                                finalMarkup += getMarkupFromNode(key2, parsedData, false);
                            }
                        }
                        blockIndex++;
                    } while (found === true);

                    finalMarkup += endAll();
                }

                break;

            case "rowend":
                rowStarted = false;
                finalMarkup += `</div>`;
                break;

            case "colstart":
                if (colStarted) {
                    finalMarkup += `</div>`;
                    colStarted = false;
                }

                colStarted = true;
                finalMarkup += `<div class="col">`;
                break;

            case "colend":
                colStarted = false;
                finalMarkup += `</div>`;
                break;

            case "tablestart":
                if (tableStarted) {
                    finalMarkup += `</table></div></div>`;
                    tableStarted = false;
                    tableWidth = 0;
                }

                finalMarkup += `<div class="row align-items-end mb-3">`;
                finalMarkup += `<div class="col table-col">`;
                finalMarkup += `<table style="width: 100%;"><thead>`;

                parsedData[key].value.forEach(
                    (entry: string) => {
                        finalMarkup += `<td class="text-center"><strong>${entry}</strong></td>`;
                    });

                tableStarted = true;
                tableWidth = parsedData[key].value.length;
                finalMarkup += `</thead>`;
                break;

            case "tableend":
                finalMarkup += endAll();
                break;

            case "trstart":
                if (trStarted) {
                    finalMarkup += `</tr>`;
                }

                trStarted = true;
                finalMarkup += `<tr class="align-top">`;

                // if this is a block then find all matching entries and inject them here
                if (
                    parsedData[key].hasOwnProperty("blockName") &&
                    !parsedData[key].hasOwnProperty("blockIndex")) {
                    const blockName = parsedData[key].blockName;
                    let blockIndex = 0;
                    let found = false;

                    do {
                        found = false;
                        const keys = Object.keys(parsedData);

                        for (var i = 0; i < keys.length; i++) {
                            const key2 = keys[i];
                            if (
                                key2 != key &&
                                parsedData[key2].hasOwnProperty("blockName") &&
                                parsedData[key2].blockName === blockName &&
                                parsedData[key2].hasOwnProperty("blockIndex") &&
                                parsedData[key2].blockIndex === blockIndex.toString()) {
                                found = true;
                                finalMarkup += getMarkupFromNode(key2, parsedData, false);
                            }
                        }
                        blockIndex++;
                    } while (found === true)

                    finalMarkup += endAll();
                }
                break;

            case "trend":
                if (trStarted) {
                    trStarted = false;
                    tableIndex = 0;
                    finalMarkup += `</tr>`;
                }
                break;

            case "textblock":
            case "rawhtml":
                if (tableStarted) {
                    tableIndex++;
                    if (tableIndex > tableWidth) {
                        finalMarkup += `</tr><tr class="align-top">`;
                        tableIndex = 1;
                    }
                }

                finalMarkup += tdcolCheckStart(parsedData[key]);
                finalMarkup += `<div class="">${parsedData[key].value}</div>`;
                finalMarkup += tdcolCheckEnd(parsedData[key]);
                break;

            case "text":
            case "secret":
            case "phone":
            case "email":
            case "options":
            case "select":
            case "date":
            case "time":
            case "rsm":
            case "employee":
            case "checkbox":
            case "textarea":
                if (tableStarted) {
                    tableIndex++;
                    if (tableIndex > tableWidth) {
                        finalMarkup += `</tr><tr class="align-top">`;
                        tableIndex = 1;
                    }
                }

                finalMarkup += tdcolCheckStart(parsedData[key]);
                finalMarkup += `<span class="form-control" style="min-height: 38px;" data-original-id="${key}" data-original-type="${parsedData[key].type}">${parsedData[key].value}</span>`;
                finalMarkup += tdcolCheckEnd(parsedData[key]);
                break;
        }

        return finalMarkup;
    }

    // re-align objects based on original form order
    const sortedParsedData =
        Object.keys(parsedData)
            .sort(
                (a, b) => {
                    const aIndex = parseInt(parsedData[a].index || 0);
                    const bIndex = parseInt(parsedData[b].index || 0);

                    if (aIndex < bIndex) {
                        return -1;
                    }
                    else if (aIndex > bIndex) {
                        return 1;
                    }

                    return 0;
                }
            )
            .reduce(
                (obj: any, key) => {
                    obj[key] = parsedData[key];
                    return obj;
                },
                {}
            );

    parsedData = sortedParsedData;

    Object.keys(parsedData).forEach(
        key => {
            finalMarkup += getMarkupFromNode(key, parsedData);
        });

    finalMarkup += "</div></div>";
    return <>{parse(finalMarkup)}</>;
}

export const generateSubmissionLog = (submission: ILabRequestEntry) => {
    if (!submission.systemDataJson) {
        submission.systemDataJson = "{}";
    }

    const parsedSystemData = JSON.parse(submission.systemDataJson);
    let logMessages: ILabRequestEntryLogEntry[] = parsedSystemData?.logMessages || [];

    return (
        <>
            {
                logMessages.length < 1 &&
                <h2>No log data</h2>
            }
            {
                logMessages.length > 0 &&
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <td>Time / User</td>
                            <td>Event</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            logMessages.map(
                                (messageData, index) =>
                                    <tr>
                                        <td>
                                            {formatDateTime(messageData.time)}<br />
                                            {messageData.user}
                                        </td>
                                        <td>
                                            {parse(messageData.message)}
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                </Table>
            }
        </>
    )
}
