import IAPIResult from "models/IAPIResult";
import { IShortenedUrlInitResult } from "models/IShortenedUrlInitResult";
import IShortenedUrl, { IShortenedUrlCreateOrUpdate, IShortenedUrlFilters } from "models/sql/IShortenedUrl";
import ILabRequestTemplate from "models/sql/ILabRequestTemplate";
import { fetchGetAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "URLShortener";

export default class LabRequestEntryService {
    static async GetAllAsync(accessToken: string, start: number, count: number, filters: IShortenedUrlFilters): Promise<{ total: number, records: IShortenedUrl[] | null, records2: ILabRequestTemplate[] | null }> {
        return await APIService.GetAllAsync<IShortenedUrl, ILabRequestTemplate>(accessToken, SERVICE_PATH, start, count, filters);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: IShortenedUrl | null, record2: null }> {
        return await APIService.GetAsync<IShortenedUrl, null>(accessToken, SERVICE_PATH, id);
    }

    static async LookupByCodeAsync(accessToken: string, code: string): Promise<IShortenedUrl[] | null> {
        return await APIService.CustomAsync<IShortenedUrl[]>(accessToken, SERVICE_PATH, "lookupByCode", { code });
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async CreateOrUpdateAsync(accessToken: string, entry: IShortenedUrlCreateOrUpdate): Promise<boolean> {
        return APIService.CreateOrUpdate<IShortenedUrlCreateOrUpdate>(accessToken, SERVICE_PATH, entry);
    }

    static async GetInitAsync(accessToken: string): Promise<IShortenedUrlInitResult | null> {
        const result: IAPIResult<IShortenedUrlInitResult> =
            await fetchGetAsync<IAPIResult<IShortenedUrlInitResult>>(
                `/api/${SERVICE_PATH}/init`,
                {},
                accessToken);

        return result.success ? result.data : null;
    }
}