import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import React, { useEffect } from "react";

export interface IQRCodeReaderProps {
    fps?: number;
    qrbox?: number;
    verbose?: boolean;
    disableFlip?: boolean;
    successCallback: (result: any) => void;
}

const QRCodeReader = (props: IQRCodeReaderProps) => {
    const qrCodeSuccessCallback = (decodedText: any, decodedResult: any) => {
        props.successCallback(decodedResult);
    };

    const qrCodeErrorCallback = (d1: any) => {
        //console.log("QR ERROR: ", d1);
        //debugger;
    };

    useEffect(
        () => {
            const html5QrcodeScanner = new Html5QrcodeScanner(
                "html5qr-code-full-region",
                {
                    fps: props.fps || 15,
                    qrbox: props.qrbox || 700,
                    disableFlip: props.disableFlip || false,
                    supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
                    rememberLastUsedCamera: true,
                    videoConstraints: {
                        width: 1280,
                        height: 720,
                    }
                },
                props.verbose);

            html5QrcodeScanner.render(
                qrCodeSuccessCallback,
                qrCodeErrorCallback);

            return () => {
                html5QrcodeScanner.clear().catch((error: any) => {
                    console.error("Failed to clear html5QrcodeScanner. ", error);
                });
            }
        }, []);

    return (
        <div id="html5qr-code-full-region" />
    );
}

export default QRCodeReader;
