import IAPIResult from "models/IAPIResult";
import IFileBackupRecord, { IFileBackupRecordCreateOrUpdate, IFileBackupRecordFilters } from "models/sql/IFileBackupRecord";
import ILoggingDeviceMeasurement from "models/sql/ILoggingDeviceMeasurement";
import { fetchPostAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "FileBackupRecord";

export default class FileBackupRecordService {
    static async GetAllAsync(accessToken: string, start: number, count: number, filters: IFileBackupRecordFilters): Promise<{ total: number, records: IFileBackupRecord[] | null, records2: ILoggingDeviceMeasurement[] | null }> {
        return await APIService.GetAllAsync<IFileBackupRecord, ILoggingDeviceMeasurement>(accessToken, SERVICE_PATH, start, count, filters);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: IFileBackupRecord | null, record2: null }> {
        return await APIService.GetAsync<IFileBackupRecord, null>(accessToken, SERVICE_PATH, id);
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async CreateOrUpdateAsync(accessToken: string, entry: IFileBackupRecord): Promise<boolean> {
        return APIService.CreateOrUpdate<IFileBackupRecordCreateOrUpdate>(accessToken, SERVICE_PATH, entry);
    }

    static async UploadFileAsync(accessToken: string, formData: FormData, filename: string, type: string, extraDataJSON: string): Promise<IFileBackupRecord | null> {
        const result: IAPIResult<IFileBackupRecord> =
            await fetchPostAsync<IAPIResult<IFileBackupRecord>>(
                `/api/${SERVICE_PATH}/uploadFile?filename=${filename}&type={type}&extraDataJSON=${extraDataJSON}`,
                formData,
                accessToken,
                {},
                true,
                true,
                null);

        return result.success ? result.data : null;
    }
}