
import { GlobalContext } from 'components/GlobalContext';
import { ILoggingDeviceDataInitResult } from 'models/ILoggingDeviceDataInitResult';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import DeviceLogService from 'services/DeviceLogService';
import DeviceLogLookup from './Lookup';
import './styles/DeviceLog.scss';

const DeviceLogIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [baseData, setbaseData] = useState<ILoggingDeviceDataInitResult>();

    useEffect(() => {
        globalContextRef.current.setSecondaryNav(<>
            <Navbar.Brand href="/DeviceLog"><strong>Production Device Logs</strong></Navbar.Brand>
        </>);

        (async () => {
            globalContextRef.current.setLoading(true);
            const accessToken = await globalContextRef.current.getAccessTokenAsync();
            const result = await DeviceLogService.GetInitAsync(accessToken);

            if (result) {
                setbaseData(result);
            }

            globalContextRef.current.setLoading(false);
        })();
    }, []);

    return (
        <>
            <div>
                <Routes>
                    <Route path={"/:page"} element={<DeviceLogLookup baseData={baseData} />} />
                    <Route path={"/*"} element={<DeviceLogLookup baseData={baseData} />} />
                </Routes>
            </div>
        </>
    );
}

export default DeviceLogIndex;
