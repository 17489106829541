import IAggregateBatchDatum, { IAggregateBatchDataFilters } from "models/sql/IAggregateBatchDatum";
import ILoggingDeviceMeasurement from "models/sql/ILoggingDeviceMeasurement";
import APIService from "./APIService";

const SERVICE_PATH = "AggregateBatchData";

export default class AggregateBatchDataService {
    static async GetAllAsync(accessToken: string, start: number, count: number, filters: IAggregateBatchDataFilters): Promise<{ total: number, records: IAggregateBatchDatum[] | null, records2: ILoggingDeviceMeasurement[] | null }> {
        return await APIService.GetAllAsync<IAggregateBatchDatum, ILoggingDeviceMeasurement>(accessToken, SERVICE_PATH, start, count, filters);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: IAggregateBatchDatum | null, record2: null }> {
        return await APIService.GetAsync<IAggregateBatchDatum, null>(accessToken, SERVICE_PATH, id);
    }
}