import "./styles/TouchKeyboard.scss";

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export enum KeyboardMode {
    Digits = 0,
    LettersAndDigits
};

export interface ITouchKeyboardProps {
    okCallback: (data: string) => void;
    changeCallback?: (data: string) => void;
    cancelCallback: () => void;
    mode?: KeyboardMode;
};

const TouchKeyboard = (props: ITouchKeyboardProps) => {
    const [phrase, setPhrase] = useState("");

    useEffect(
        () => {
        }, []);

    const geDigitsKeyboard = () => {
        return <>
            {
                ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "CLR"].map(
                    value =>
                        <Button
                            key={value}
                            className="key"
                            onClick={() => {
                                if (value === "CLR") {
                                    setPhrase("");
                                    if (props.changeCallback) {
                                        props.changeCallback("");
                                    }
                                }
                                else {
                                    setPhrase(phrase + value);
                                    if (props.changeCallback) {
                                        props.changeCallback(phrase + value);
                                    }
                                }
                            }}
                        >{value}</Button>
                )
            }
        </>
    };

    const getLettersAndDigits = () => {
        return <></>
    };

    const getKeypadContent = () => {
        switch (props.mode) {
            case KeyboardMode.Digits:
                return geDigitsKeyboard();
            default:
            case KeyboardMode.LettersAndDigits:
                return getLettersAndDigits();
        }
    };

    return (
        <div className="touch-keyboard">
            <div className="key-container">
                {
                    getKeypadContent()
                }
            </div>

            <Button
                className="cancel"
                variant="danger"
                onClick={() => {
                    props.cancelCallback();
                }}>
                Cancel
            </Button>
            <Button
                className="accept"
                variant="success"
                onClick={() => {
                    props.okCallback(phrase);
                }}>
                Accept
            </Button>
        </div>
    );
}

export default TouchKeyboard;
