import moment from 'moment';

export const formatDate = (date: Date | string | undefined, utcShift: boolean = false) => {
    if (date) {
        if (typeof date === "string") {
            date = new Date(date);
        }

        return (utcShift ? moment(date).utc() : moment(date)).format('MM/DD/YYYY');
    }
    else {
        return "";
    }
}

export const formatDateTime = (date: Date | string | undefined, utcShift: boolean = false) => {
    if (date) {
        if (typeof date === "string") {
            date = new Date(date);
        }

        return (utcShift ? moment(date).utc() : moment(date)).format('MM/DD/YYYY hh:mm A');
    }
    else {
        return "";
    }
}

export const formatTime = (date: Date | string | undefined, utcShift: boolean = false) => {
    if (date) {
        if (typeof date === "string") {
            date = new Date(date);
        }

        return (utcShift ? moment(date).utc() : moment(date)).format('hh:mm A');
    }
    else {
        return "";
    }
}

export const limitLength = (phrase: string, maxLength: number, keepAtEnd: number) => {
    if (phrase.length > maxLength) {
        phrase = `${phrase.slice(0, (maxLength - keepAtEnd - 3))}...${phrase.slice(keepAtEnd * -1)}`;
    }
    return phrase;
}

export const camelCaseToProper = (phrase: string): string => {
    if (!phrase) {
        return "";
    }

    const result = phrase.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const isoDateTimeStringWithoutTimeZone = (date: Date | string) => {
    if (typeof date === "string") {
        date = new Date(date);
    }

    var timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
    var correctDate = new Date(timestamp);
    return correctDate.toISOString();
}

export const isoDateStringWithoutTimeZone = (date: Date | string) => {
    if (typeof date === "string") {
        date = new Date(date);
    }

    var timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
    var correctDate = new Date(timestamp);
    correctDate.setUTCHours(0, 0, 0, 0);
    return correctDate.toISOString();
}

export const numberWithCommas = (value: number) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const roundToDigits = (num: number, digits: number) => {
    return num.toFixed(digits);
}