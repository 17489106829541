/*
panel
    need to show confirmation before printing labels

    add printer column on the first, clicking the printer selects all

    need to get industry to show on the screen

    need to add industry to the lookup screen
*/

import { GlobalContext } from 'components/GlobalContext';
import { IPanelInitResult } from 'models/IPanelInitResult';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Nav, Navbar } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PanelService from 'services/PanelService';
import CCCPanelLookupDetail from './Detail';
import CCCPanelLookupLookup from './Lookup';
import './styles/CCCPanelLookup.scss';

const CCCPanelLookupIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const navigate = useNavigate();
    const [showLookup, setShowLookup] = useState(false);
    const [panelId, setPanelId] = useState<string>();
    const [panelBaseData, setPanelBaseData] = useState<IPanelInitResult>();

    useEffect(() => {
        globalContext.setSecondaryNav(<>
            <Navbar.Brand href="/CCCPanelLookup"><strong>Panel Lookup</strong></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/CCCPanelLookup">Recent</Nav.Link>
                    <Nav.Link onClick={() => showLookupModal()}>Lookup</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </>);

        (async () => {
            globalContextRef.current.setLoading(true);
            const accessToken = await globalContext.getAccessTokenAsync();
            const result = await PanelService.GetPanelInitAsync(accessToken);

            if (result) {
                setPanelBaseData(result);
            }

            globalContextRef.current.setLoading(false);
        })();
    }, []);

    const showLookupModal = () => {
        setShowLookup(true);
    };

    const hideLookupModal = () => {
        setShowLookup(false);
    }

    const lookupPanelDataAsync = async () => {
        hideLookupModal();

        if (panelId) {
            globalContext.setLoading(true);
            const accessToken = await globalContext.getAccessTokenAsync();
            const panel = await PanelService.LookupByPanelIdAsync(accessToken, parseInt(panelId));

            if (panel) {
                navigate(`/CCCPanelLookup/Detail/${panel.panelId}`)
            }
            else {
                globalContext.showErrorMessage(`Couldn't find data for Panel Number ${panelId}`, 10000);
            }

            globalContext.setLoading(false);
        }
    }

    if (!panelBaseData) {
        return (
            <>
            </>
        );
    }

    return (
        <>
            <div>
                <Routes>
                    <Route path={"Detail/:panelId"} element={<CCCPanelLookupDetail panelBaseData={panelBaseData} />} />
                    <Route path={"/:page"} element={<CCCPanelLookupLookup panelBaseData={panelBaseData} />} />
                    <Route path={"/*"} element={<CCCPanelLookupLookup panelBaseData={panelBaseData} />} />
                </Routes>
            </div>

            <Modal show={showLookup} onHide={hideLookupModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Lookup Panel Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="panelId">
                            <Form.Label>Please provide Panel Number</Form.Label>
                            <Form.Control type="text" placeholder="Panel Id" value={panelId} onChange={(e) => setPanelId(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideLookupModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={lookupPanelDataAsync}>
                        Find Panel Data
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CCCPanelLookupIndex;
