import { Button, Container, Row } from "react-bootstrap";
import { appStates, getStripBaseMaterialNameFromType, getStripMaterialNameFromType, getStripSizeNameFromType, IKioskSceneBaseProps } from "../Index";

const Confirm = (props: IKioskSceneBaseProps) => {
    return (
        <Row className="screen confirm-screen">
            <h1>
                <Button
                    className="back"
                    onClick={() => props.setAppState(appStates.SetupStrips)}
                >Back</Button>
                Confirm Setup</h1>
            <Container>
                <Row>
                    <p>
                        <strong>Coupon Number: </strong>{props.couponNumber}
                    </p>
                    <p>
                        <strong>Strip 1: </strong>{getStripSizeNameFromType(props.strip1SizeType)} / {getStripMaterialNameFromType(props.strip1MaterialType)}
                    </p>
                    <p>
                        <strong>Strip 2: </strong>{getStripSizeNameFromType(props.strip2SizeType)} / {getStripMaterialNameFromType(props.strip2MaterialType)}
                    </p>
                    <p>
                        <strong>Base Material: </strong>{getStripBaseMaterialNameFromType(props.strip1BaseMaterialType)}
                    </p>
                    <p>
                        <strong>Photo Preview</strong><br />
                        {
                            props.photo &&
                            <img className="photo-preview" alt="panel preview" src={props.photo} />
                        }
                        {
                            !props.photo &&
                            <strong>[ User will attach later ]</strong>
                        }
                    </p>

                    <Button
                        className={`accept ${props.photo ? "" : "no-photo"}`}
                        onClick={() => props.setAppState(appStates.Running)}
                    >Begin Test</Button>
                </Row>
            </Container>
        </Row>
    );
}

export default Confirm