export async function fetchPostAsync<T>(url: string, data?: any, accessToken?: string, options?: any, asJson = true, asFormData = false, contentType: string | null = 'application/json'): Promise<T> {
    return await new Promise<T>((resolve, reject) => {
        let headers: any = {
            'Accept': 'application/json'
        };

        if (contentType) {
            headers['Content-Type'] = contentType;
        }

        if (accessToken) {
            // @ts-ignore
            headers.Authorization = `Bearer ${accessToken}`;
        }

        fetch(
            url,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                redirect: "follow",
                referrer: "no-referrer",
                headers: headers,
                body: data ? (asFormData ? data : JSON.stringify(data)) : undefined,
                ...options
            })
            .then(response => resolve(asJson ? response.json() : response.text()))
            .catch(error => reject(error));
    });
}

export async function fetchGetAsync<T>(url: string, data?: any, accessToken?: string, options?: any, asJson = true): Promise<T> {
    const parameters = objectToGetParameters(data);
    const index = url.indexOf("?");

    if (index === -1) {
        url = `${url}?${parameters}`;
    }
    else if (index === url.length - 1) {
        url = `${url}${parameters}`;
    }
    else {
        url = `${url}&${parameters}`;
    }

    let headers = {};

    if (accessToken) {
        // @ts-ignore
        headers.Authorization = `Bearer ${accessToken}`;
    }

    return await new Promise<T>((resolve, reject) => {
        fetch(
            url,
            {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                redirect: "error",
                referrer: "no-referrer",
                headers: headers,
                ...options
            })
            .then(response => resolve(asJson ? response.json() : response.text()))
            .catch(error => reject(error));
    });
}

export function objectToGetParameters(source: any, includeEmptyValues = false) {
    if (!source) {
        return "";
    }

    return (
        Object
            .keys(source)
            .filter(
                key => includeEmptyValues || (!!source[key] || source[key] === false || source[key] === 0))
            .map(
                key => {
                    if (Array.isArray(source[key])) {
                        const arrayParts = [];
                        for (let i = 0; i < source[key].length; i++)
                            arrayParts.push(
                                encodeURIComponent(key + '[]') + '=' + encodeURIComponent(source[key][i]));
                        return arrayParts.join('&');
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(source[key]);
                })
            .join('&'));
}