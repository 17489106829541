import { Button, Container, Navbar, Row } from "react-bootstrap";
import logo from 'assets/images/Purafil-Systems.png';
import { IPublicClientApplication } from "@azure/msal-browser";

export interface LoggedInContentProps {
    title: string;
    displayName: string;
    handleLogout: (any);
    instance: IPublicClientApplication;
    children: React.ReactNode;
    secondaryNav?: React.ReactElement;
}

const LoggedInContent = (props: LoggedInContentProps) => {
    return (<>
        <Container>
            <Row className="no-print">
                <Navbar bg="light" expand="lg" className="nav">
                    <Navbar.Brand href="/" style={{ padding: "0" }}>
                        <img
                            src={logo}
                            width="250"
                            className="d-inline-block align-top"
                            alt="Purafil"
                        />
                    </Navbar.Brand>
                    <Navbar.Text className="ms-auto nav-title">
                        {props.title && <strong>{props.title}</strong>}
                    </Navbar.Text>
                    <Navbar.Text className="ms-auto nav-logout">
                        {props.displayName && <strong>{props.displayName}</strong>}&nbsp;<Button variant="secondary" className="ml-auto" onClick={() => props.handleLogout(props.instance)}>Sign out</Button>
                    </Navbar.Text>
                </Navbar>
            </Row>
            {
                props.secondaryNav &&
                <Row className="no-print">
                    <Navbar bg="light" expand="sm" className="secondary-nav">
                        {
                            props.secondaryNav
                        }
                    </Navbar>
                </Row>
            }
        </Container>
        <Container role="content" >
            {props.children && props.children}
        </Container>
    </>);
}

export default LoggedInContent;
