import IAPIResult from "models/IAPIResult";
import { ILabRequestTemplateInitResult } from "models/ILabRequestTemplateInitResult";
import ILabRequestTemplate, { ILabRequestTemplateCreateOrUpdate } from "models/sql/ILabRequestTemplate";
import { fetchGetAsync, fetchPostAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "LabRequestTemplate";

export default class LabRequestTemplateService {
    static async GetAllAsync(accessToken: string, start: number, count: number): Promise<{ total: number, records: ILabRequestTemplate[] | null, records2: null[] | null }> {
        return await APIService.GetAllAsync<ILabRequestTemplate, null>(accessToken, SERVICE_PATH, start, count);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: ILabRequestTemplate | null, record2: null }> {
        return await APIService.GetAsync<ILabRequestTemplate, null>(accessToken, SERVICE_PATH, id);
    }

    static async CloneAsync(accessToken: string, tid: number, title: string): Promise<null> {
        await fetchGetAsync<IAPIResult<ILabRequestTemplateInitResult>>(
            `/api/${SERVICE_PATH}/clone`,
            { tid, title },
            accessToken);

        return null;
    }

    static async IncrementalUpdateAsync(accessToken: string, entry: ILabRequestTemplateCreateOrUpdate): Promise<boolean> {
        const result: IAPIResult<boolean> =
            await fetchPostAsync<IAPIResult<boolean>>(
                `/api/${SERVICE_PATH}/incrementalUpdate`,
                { ...entry },
                accessToken);

        return result.success;
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async GetInitAsync(accessToken: string): Promise<ILabRequestTemplateInitResult | null> {
        const result: IAPIResult<ILabRequestTemplateInitResult> =
            await fetchGetAsync<IAPIResult<ILabRequestTemplateInitResult>>(
                `/api/${SERVICE_PATH}/init`,
                {},
                accessToken);

        return result.success ? result.data : null;
    }

    static async getByFormNumber(accessToken: string, formNumber: string): Promise<ILabRequestTemplate | null> {
        const result: IAPIResult<ILabRequestTemplate> =
            await fetchGetAsync<IAPIResult<ILabRequestTemplate>>(
                `/api/${SERVICE_PATH}/getByFormNumber`,
                {formNumber},
                accessToken);

        return result.success ? result.data : null;
    }

    static async EmailLinkAsync(accessToken: string, id: number, emailAddress: string, url: string): Promise<ILabRequestTemplateInitResult | null> {
        const result: IAPIResult<ILabRequestTemplateInitResult> =
            await fetchGetAsync<IAPIResult<ILabRequestTemplateInitResult>>(
                `/api/${SERVICE_PATH}/emailLink`,
                { id, emailAddress, url },
                accessToken);

        return result.success ? result.data : null;
    }

    static async CreateOrUpdateAsync(accessToken: string, entry: ILabRequestTemplateCreateOrUpdate): Promise<boolean> {
        return APIService.CreateOrUpdate<ILabRequestTemplateCreateOrUpdate>(accessToken, SERVICE_PATH, entry);
    }
}