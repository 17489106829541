export const getBaseDomain = () => {
    if (window.location.toString().indexOf("purafilsystems.com") > -1) {
        return "www.purafilsystems.com";
    }
    else if (window.location.toString().indexOf("localhost") > -1) {
        return "localhost:3000";
    }
    else {
        return "pflits2-pflitsqa.azurewebsites.net"
    }
}

export const rotateImage = (imageBase64: any, rotation: number, cb: Function) => {
    var img = new Image();
    img.src = imageBase64;
    img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.setTransform(1, 0, 0, 1, img.width / 2, img.height / 2);
            ctx.rotate(rotation * (Math.PI / 180));
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            cb(canvas.toDataURL("image/jpeg"))
        }
    };
};

export const getCSSEnvironment = () =>
    window.location.toString().indexOf("localhost") > -1 ?
        "DEV" :
        window.location.toString().indexOf("pflits2-pflitsqa.azurewebsites.net") > -1 ?
            "QA" :
            "";