import { GlobalContext } from "components/GlobalContext";
import SortableTable, { IColumnDefinition } from "components/SortableTable";
import { IShortenedUrlInitResult } from "models/IShortenedUrlInitResult";
import IShortenedUrl, { IShortenedUrlCreateOrUpdate, IShortenedUrlFilters } from "models/sql/IShortenedUrl";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ShortenedUrlService from "services/ShortenedUrlService";
import { getObjectFromQuerystring } from "utilities/BrowserUtility";
import { DEFAULT_TABLE_RECORD_COUNT } from "utilities/UIUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IShortenedUrlSubmissionsProps {
    baseData?: IShortenedUrlInitResult;
}

const ShortenedUrlsLookup = (props: IShortenedUrlSubmissionsProps) => {
    const getSearchParams = (params: URLSearchParams) => ({
        ...{
            sortBys: ["Created"],
            sortDescending: true
        } as IShortenedUrlFilters,
        ...getObjectFromQuerystring(
            params,
            {
                sortBys: "Array",
                sortDescending: "Boolean"
            })
    });

    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [searchParams, setSearchParams] = useSearchParams();
    const [entries, setEntries] = useState<IShortenedUrl[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);
    const { search } = useLocation();
    const [filter, setFilter] = useState<IShortenedUrlFilters>(getSearchParams(searchParams));
    const [refresh, setRefresh] = useState<Date>(new Date());
    const [accessToken, setAccessToken] = useState("");

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setSecondaryNav(<>
                    <Navbar.Brand href="/ShortenedUrls"><strong>Shortened Urls</strong></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Button
                                style={{ marginRight: "10px" }}
                                onClick={
                                    () => {
                                        globalContext.showModal({
                                            title: "Add a new Shortened Url",
                                            formElements: [{
                                                key: "url",
                                                type: "text",
                                                title: "The full url this is redirecting to",
                                                value: "Https://www.google.com"
                                            }],
                                            content: <></>,
                                            size: "lg",
                                            okButtonTitle: "Create",
                                            okCallback: async (data: any) => {
                                                globalContext.setLoading(true);
                                                const accessToken = await globalContext.getAccessTokenAsync();
                                                var entry = {
                                                    url: data[0].value
                                                };
                                                await ShortenedUrlService.CreateOrUpdateAsync(accessToken, entry);
                                                globalContext.setLoading(false);
                                                setRefresh(new Date());
                                            }
                                        });
                                    }}>Add New</Button>
                        </Nav>
                    </Navbar.Collapse>
                </>);

                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(accessToken);
            })();
        }, []);

    useEffect(
        () => {
            (async () => {
                //@ts-ignore
                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                globalContextRef.current.setLoading(true);

                const result =
                    await ShortenedUrlService.GetAllAsync(
                        accessToken,
                        page - 1,
                        DEFAULT_TABLE_RECORD_COUNT,
                        filter);

                if (result.records != null) {
                    setEntries(result.records);
                    setTotalRecords(result.total);
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load shortened Urls");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page, filter, refresh]);

    useEffect(
        () => {
            const searchSettings = getSearchParams(searchParams);
            const reducedSearchSettings = JSON.stringify(searchSettings);
            if (JSON.stringify(filter) !== reducedSearchSettings) {
                setFilter(searchSettings);
            }
        },
        [search]);

    const updateSearchParams = (newParams: any) => {
        setSearchParams(newParams);
        setFilter(newParams);
    };

    const saveEntryUpdate = async (entry: IShortenedUrl) => {
        globalContext.setLoading(true);
        const data: IShortenedUrlCreateOrUpdate = {
            created: entry.created,
            redirectId: entry.redirectId,
            code: entry.code,
            hits: entry.hits,
            url: entry.url
        };

        await ShortenedUrlService.CreateOrUpdateAsync(accessToken, data);
        globalContext.setLoading(false);
    }

    const getColumns = (): IColumnDefinition<IShortenedUrl, IShortenedUrlFilters>[] => {
        return [
            {
                propName: "redirectId",
                title: "Actions",
                customRenderer: (value, data) => {
                    return (<td key={`align-top actions${value}`}>
                        <span
                            className="quick-action"
                            title="delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                (async () => {
                                    if (window.confirm("Are you SURE you want to delete this?")) {
                                        // @ts-ignore
                                        ShortenedUrlService.DeleteAsync(accessToken, data.redirectId);
                                        setRefresh(new Date());
                                    }
                                })();
                            }}><FontAwesomeIcon icon={faTrash as IconProp} /></span>
                    </td>)
                },
                sortable: false
            },
            {
                propName: "code",
                title: "Short Url",
                customRenderer: (value, data) => <td className="align-top" key={`code${value}`}>
                    <a href={`https://purafil.io/r/${value}`} target="_blank">https://purafil.io/r/{value}</a>
                </td>,
                sortable: true
            },
            {
                propName: "url",
                title: "Target Url",
                sortable: true
            },
            {
                propName: "hits",
                title: "Hits",
                sortable: true
            }
        ];
    }

    return (
        <SortableTable<IShortenedUrl, IShortenedUrlFilters>
            changePageCallback={(newPage) => navigate(`/ShortendUrls/${newPage + 1}${window.location.search}`)}
            page={page}
            className="lookup-table"
            totalRecords={totalRecords}
            recordCount={entries.length}
            records={entries}
            setFilter={updateSearchParams}
            filter={filter}
            rowClickCallback={(data) => { console.log("row click"); }}
            rowClassRenderer={(data) => {
                return ``;
            }}
            tools={[
            ]}
            columnDefinitions={getColumns()}
        />
    );
}

export default ShortenedUrlsLookup;