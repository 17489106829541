import { GlobalContext } from "components/GlobalContext";
import SortableTable, { IColumnDefinition } from "components/SortableTable";
import ISystemDatum, { ISystemDatumFilters } from "models/sql/ISystemDatum";
import { useContext, useEffect, useRef, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import SystemDataService from "services/SystemDataService";
import { getObjectFromQuerystring } from "utilities/BrowserUtility";
import { DEFAULT_TABLE_RECORD_COUNT } from "utilities/UIUtilities";

interface ISystemDataSubmissionsProps {
}

const SystemDataLookup = (props: ISystemDataSubmissionsProps) => {
    const getSearchParams = (params: URLSearchParams) => ({
        ...{
        } as ISystemDatumFilters,
        ...getObjectFromQuerystring(
            params,
            {
            })
    });

    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [searchParams, setSearchParams] = useSearchParams();
    const [entries, setEntries] = useState<ISystemDatum[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);
    const { search } = useLocation();
    const [filter, setFilter] = useState<ISystemDatumFilters>(getSearchParams(searchParams));
    const [refresh, setRefresh] = useState<Date>(new Date());
    const [accessToken, setAccessToken] = useState("");

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setSecondaryNav(<>
                    <Navbar.Brand href="/SystemData"><strong>Global System Data</strong></Navbar.Brand>
                </>);

                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(accessToken);
            })();
        }, []);

    useEffect(
        () => {
            (async () => {
                //@ts-ignore
                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                globalContextRef.current.setLoading(true);

                const result =
                    await SystemDataService.GetAllAsync(
                        accessToken,
                        page - 1,
                        DEFAULT_TABLE_RECORD_COUNT);

                if (result.records != null) {
                    setEntries(result.records);
                    setTotalRecords(result.total);
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load System Data");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page, filter, refresh]);

    useEffect(
        () => {
            const searchSettings = getSearchParams(searchParams);
            const reducedSearchSettings = JSON.stringify(searchSettings);
            if (JSON.stringify(filter) !== reducedSearchSettings) {
                setFilter(searchSettings);
            }
        },
        [search]);

    const updateSearchParams = (newParams: any) => {
        setSearchParams(newParams);
        setFilter(newParams);
    };

    const getColumns = (): IColumnDefinition<ISystemDatum, ISystemDatumFilters>[] => {
        return [
            {
                propName: "title",
                title: "Title"
            },
            {
                propName: "description",
                title: "Description"
            }
        ];
    }

    return (
        <SortableTable<ISystemDatum, ISystemDatumFilters>
            changePageCallback={(newPage) => navigate(`/SystemData/${newPage + 1}${window.location.search}`)}
            page={page}
            className="lookup-table"
            totalRecords={totalRecords}
            recordCount={entries.length}
            records={entries}
            setFilter={updateSearchParams}
            filter={filter}
            rowClickCallback={(data) => { navigate(`/SystemData/Detail/${data.systemDataId}`) }}
            columnDefinitions={getColumns()}
        />
    );
}

export default SystemDataLookup;