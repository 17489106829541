import { GlobalContext } from "components/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Nav, Navbar } from "react-bootstrap";
import { Route, Routes, useNavigate } from "react-router-dom";
import PanelAquisitionService from "services/PanelAquisitionService";
import CCCPanelAquisitionLookupDetail from "./Detail";
import CCCPanelAquisitionLookupLookup from "./Lookup";

import './styles/CCCPanelAquisitionLookup.scss';

const CCCPanelAquisitionLookupIndex = () => {
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);
    const [showLookup, setShowLookup] = useState(false);
    const [panelId, setPanelId] = useState<string>();

    useEffect(() => {
        globalContext.setSecondaryNav(<>
            <Navbar.Brand href="/CCCPanelAquisitionLookup"><strong>Aquisition Data Lookup</strong></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/CCCPanelAquisitionLookup">Recent</Nav.Link>
                    <Nav.Link onClick={() => showLookupModal()}>Lookup</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </>);
    }, []);

    const showLookupModal = () => {
        setShowLookup(true);
    };

    const hideLookupModal = () => {
        setShowLookup(false);
    }

    const lookupAquisitionDataAsync = async () => {
        hideLookupModal();

        if (panelId) {
            globalContext.setLoading(true);
            const accessToken = await globalContext.getAccessTokenAsync();
            const panelAquisition = await PanelAquisitionService.LookupByPanelIdAsync(accessToken, parseInt(panelId));

            if (panelAquisition) {
                navigate(`/CCCPanelAquisitionLookup/Detail/${panelAquisition.panelAquisitionId}`)
            }
            else {
                globalContext.showErrorMessage(`Couldn't find Aquisition Data for Panel Number ${panelId}`, 10000);
            }

            globalContext.setLoading(false);
        }
    }

    return (
        <>
            <div>
                <Routes>
                    <Route path={"Detail/:aquisitionId"} element={<CCCPanelAquisitionLookupDetail />} />
                    <Route path={"/:page"} element={<CCCPanelAquisitionLookupLookup />} />
                    <Route path={"/*"} element={<CCCPanelAquisitionLookupLookup />} />
                </Routes>
            </div>

            <Modal show={showLookup} onHide={hideLookupModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Lookup Aquisition Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="panelId">
                            <Form.Label>Please provide Panel Number</Form.Label>
                            <Form.Control type="text" placeholder="Panel Id" value={panelId} onChange={(e) => setPanelId(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideLookupModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={lookupAquisitionDataAsync}>
                        Find Aquisition Data
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CCCPanelAquisitionLookupIndex;
