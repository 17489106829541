import { GlobalContext } from "components/GlobalContext";
import { ILabRequestTemplateInitResult } from "models/ILabRequestTemplateInitResult";
import ILabRequestTemplate from "models/sql/ILabRequestTemplate";
import { useContext, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LabRequestTemplateService from "services/LabRequestTemplateService";
import { formatDate } from "utilities/StringUtility";
import { generatePagination } from "utilities/UIUtilities";

const RECORD_COUNT = 20;

interface ILabRequestTemplateProps {
    baseData?: ILabRequestTemplateInitResult;
}

const LabRequestTemplateLookup = (props: ILabRequestTemplateProps) => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [templates, setTemplates] = useState<ILabRequestTemplate[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);

                const accessToken = await globalContext.getAccessTokenAsync();
                const result = await LabRequestTemplateService.GetAllAsync(accessToken, page - 1, RECORD_COUNT);

                if (result.records != null) {
                    setTemplates(result.records);
                    setTotalRecords(result.total);
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load recent Templates");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page]);

    const changePage = (newPage: number) => {
        navigate(`/FormTemplates/${newPage + 1}`);
    };

    return (
        <>
            <Table striped bordered hover size="sm" className="lookup-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Form Number</th>
                        <th>Version</th>
                        <th>Category</th>
                        <th>Active</th>
                        <th>Public Link</th>
                        <th>Public Access Key</th>
                        <th>Grouping</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        templates.map(
                            t => {
                                return (
                                    <tr
                                        key={t.labRequestTemplateId}
                                        onClick={() => navigate(`/FormTemplates/Detail/${t.labRequestTemplateId}`)}
                                        className={!t.active ? "innactive" : ""}
                                        style={{ cursor: "pointer" }}>
                                        <td>{t.title}</td>
                                        <td>{t.formNumber}</td>
                                        <td>{t.version}</td>
                                        <td>{t.categoryId != undefined ? props.baseData?.categoryNames[t.categoryId] : ""}</td>
                                        <td>{t.active ? "True" : "False"}</td>
                                        <td>
                                            <a
                                                onClick={(e) => { e.stopPropagation(); return false; }}
                                                href={`/Public/Form?form=${t.formNumber}`}
                                                target="_blank">Public Link</a></td>
                                        <td>{t.accessKey}</td>
                                        <td>{t.grouping}</td>
                                        <td>{formatDate(new Date(t.lastUpdated))}</td>
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </Table>
            {generatePagination(changePage, page - 1, totalRecords, RECORD_COUNT)}
        </>
    );
}

export default LabRequestTemplateLookup;
