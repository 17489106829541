import IAPIResult from "models/IAPIResult";
import { IPanelInitResult } from "models/IPanelInitResult";
import IESynergySalesRep from "models/sql/IESynergySalesRep";
import IPanel from "models/sql/IPanel";
import IPanelAquisition from "models/sql/IPanelAquisition";
import { fetchGetAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "Panel";

export default class PanelService {
    static async GetAllAsync(accessToken: string, start: number, count: number): Promise<{ total: number, records: IPanel[] | null, records2: IPanelAquisition[] | null }> {
        return await APIService.GetAllAsync<IPanel, IPanelAquisition>(accessToken, SERVICE_PATH, start, count);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: IPanel | null, record2: IESynergySalesRep | null }> {
        return await APIService.GetAsync<IPanel, IESynergySalesRep>(accessToken, SERVICE_PATH, id);
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async LookupByPanelIdAsync(accessToken: string, panelId: number): Promise<IPanel | null> {
        return await APIService.CustomAsync<IPanel>(accessToken, SERVICE_PATH, "lookupByPanelId", { panelId });
    }

    static async GetPanelInitAsync(accessToken: string): Promise<IPanelInitResult | null> {
        const result: IAPIResult<IPanelInitResult> =
            await fetchGetAsync<IAPIResult<IPanelInitResult>>(
                `/api/${SERVICE_PATH}/init`,
                {},
                accessToken);

        return result.success ? result.data : null;
    }
}