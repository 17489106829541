import { useEffect, useState } from 'react';
import { Button, Row } from "react-bootstrap";
import { appStates, IKioskSceneBaseProps, ITestData } from "../Index";

const CANVAS_WIDTH = 600;
const CANVAS_HEIGHT = 600;
const ORIGINAL_LINE_THICKNESS = 4;
const TIME_SCALE_BUMP = 0.2;
const VOLTAGE_SCALE = 500;
const VOLTAGE_DIVISOR = 0.79;
const TIME_OFFSET = 40;
const FILTER_OFFSET = 40;
const SILVER_Y_OFFSET = 30;
const SILVER_V_MULTIPLY = 1.1;

const Running = (props: IKioskSceneBaseProps) => {
    const [canvas, setCanvas] = useState<HTMLCanvasElement>();
    const [ctx, setCTX] = useState<CanvasRenderingContext2D>();

    let TIME_SCALE = 0.05;
    let testData1: { time: number, v: number }[] = [];
    let testData2: { time: number, v: number }[] = [];
    let lastVX = 0;
    let lastVY = CANVAS_HEIGHT;
    let highestV1 = Number.NEGATIVE_INFINITY;
    let lowestV1 = Number.POSITIVE_INFINITY;
    let highestV2 = Number.NEGATIVE_INFINITY;
    let lowestV2 = Number.POSITIVE_INFINITY;

    const processAquisition = (data: ITestData) => {
        lastVX = 0;
        lastVY = CANVAS_HEIGHT;
        let timeOffset = 0;
        let DRAW_RATE = 1;

        if (data.strip1.length > 1000) {
            DRAW_RATE = 2;
        }
        else if (data.strip1.length > 2000) {
            DRAW_RATE = 5;
        }
        else if (data.strip1.length > 4000) {
            DRAW_RATE = 10;
        }
        else if (data.strip1.length > 8000) {
            DRAW_RATE = 15;
        }
        else if (data.strip1.length > 16000) {
            DRAW_RATE = 30;
        }

        data.strip1.forEach(
            (e, i) => {
                if (i % DRAW_RATE === 0) {
                    const line1 = data.strip1[i];
                    const line2 = data.strip2[i];

                    if (testData1.length === 0) {
                        timeOffset = line1.time * -1;
                    }

                    testData1.push({
                        time: line1.time + timeOffset,
                        v: Math.floor(line1.value * VOLTAGE_SCALE)
                    });

                    testData2.push({
                        time: line2.time + timeOffset,
                        v: Math.floor(line2.value * VOLTAGE_SCALE)
                    });

                    // highestV1 = Math.max(highestV1, Math.floor(line1.value * VOLTAGE_SCALE));
                    // highestV2 = Math.max(highestV2, Math.floor(line2.value * VOLTAGE_SCALE));
                    // lowestV1 = Math.min(lowestV1, Math.floor(line1.value * VOLTAGE_SCALE));
                    // lowestV2 = Math.min(lowestV2, Math.floor(line2.value * VOLTAGE_SCALE));
                }
            });
    };

    const drawGrid = () => {
        if (ctx) {
            for (var seconds = 0; seconds <= (CANVAS_HEIGHT / TIME_SCALE); seconds += 100) {

                if (seconds % (5 * 60) === 0) {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
                    ctx.lineWidth = 2;
                }
                else {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
                    ctx.lineWidth = 1;
                }

                ctx.beginPath();
                ctx.moveTo(
                    0,
                    getYPosition(seconds));
                ctx.lineTo(
                    CANVAS_WIDTH,
                    getYPosition(seconds));
                ctx.stroke();
            }

            const voltMax = 2;
            const voltIncrement = 0.25;

            for (var volts = 0; volts <= voltMax * VOLTAGE_SCALE; volts += (voltIncrement * VOLTAGE_SCALE)) {
                if (volts % (1 * VOLTAGE_SCALE) === 0) {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
                    ctx.lineWidth = 2;
                }
                else {
                    ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
                    ctx.lineWidth = 1;
                }

                ctx.beginPath();
                ctx.moveTo(
                    getXPosition(volts, false, false),
                    0);
                ctx.lineTo(
                    getXPosition(volts, false, false),
                    CANVAS_HEIGHT);
                ctx.stroke();
            }
        }
    }

    const getXPosition = (val: number, isSilver = false, honorDivisor = true) => {
        return (val * (honorDivisor ? VOLTAGE_DIVISOR : 1)) * (isSilver ? SILVER_V_MULTIPLY : 1) + FILTER_OFFSET;
    }

    const getYPosition = (val: number, isSilver = false) => {
        return CANVAS_HEIGHT - ((val * TIME_SCALE) + TIME_OFFSET + (isSilver ? SILVER_Y_OFFSET : 0));
    }

    const drawIndividualGraph = (data: { time: number, v: number }[], color: string, offset = false) => {
        if (ctx) {
            data.forEach(
                (entry, index) => {
                    if (index === 0) {
                        lastVX = entry.v;
                        lastVY = entry.time;
                    }

                    ctx.strokeStyle = color;

                    var lowY = getYPosition(entry.time, offset);

                    ctx.beginPath();
                    ctx.moveTo(
                        getXPosition(lastVX, offset),
                        getYPosition(lastVY, offset));
                    ctx.lineTo(
                        getXPosition(entry.v, offset),
                        lowY);
                    ctx.stroke();

                    // if (lowY < 100) {
                    //     TIME_SCALE -= TIME_SCALE_BUMP;
                    // }

                    lastVX = entry.v;
                    lastVY = entry.time;
                });
        }
    }

    const drawGraphs = () => {
        if (ctx) {
            ctx.lineWidth = ORIGINAL_LINE_THICKNESS;

            // silver
            if (testData1.length > 0 && props.strip1MaterialType) {
                drawIndividualGraph(
                    testData1,
                    props.strip1MaterialType === 1 ? "green" : "red",
                    props.strip1MaterialType === 1);
            }

            // copper
            if (testData2.length > 0 && props.strip2MaterialType) {
                drawIndividualGraph(
                    testData2,
                    props.strip2MaterialType === 1 ? "green" : "red",
                    props.strip2MaterialType === 1);
            }
        }
    }

    useEffect(
        () => {
            if (ctx) {
                // @ts-ignore
                ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

                if (props.testData &&
                    (
                        props.testData.strip1 && props.testData.strip1.length > 0 ||
                        props.testData.strip2 && props.testData.strip2.length > 0
                    )) {
                    processAquisition(props.testData);

                    if (testData1.length + testData2.length > 0) {
                        drawGrid();
                        drawGraphs();
                    }
                }
            }
        }, [props.testData]);

    useEffect(
        () => {
            (async () => {
                // enable 24v
                // todo: only enable / disable if this one is running
                props.relayOn(2);
                props.relayOn(3);
                const stageCanvas = document.getElementById("canvas") as HTMLCanvasElement;
                stageCanvas.width = CANVAS_WIDTH;
                stageCanvas.height = CANVAS_HEIGHT;
                const stageCTX = stageCanvas.getContext("2d");

                if (stageCTX) {
                    stageCTX.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
                    stageCTX.fillStyle = "white";
                    stageCTX.strokeStyle = "white";
                    setCanvas(stageCanvas);
                    setCTX(stageCTX);
                    props.startADCReads();
                }

                return () => {
                    // disable 24v
                    // todo: only enable / disable if this one is running
                    props.relayOff(2);
                    props.relayOff(3);
                    props.stopADCReads();
                };
            })();
        }, []);

    return (
        <Row className="screen running-screen">
            <h1>Running panel {props.couponNumber}</h1>
            <div className="d-grid gap-2">
                <canvas id="canvas" width="800" height="600" style={{ backgroundColor: "#000", width: "100%" }}></canvas>
            </div>
            <Button
                className="cancel"
                variant="danger"
                onClick={
                    () => {
                        props.stopADCReads();
                        setTimeout(
                            () => window.location.reload(),
                            300);

                    }}
            >Cancel</Button>
            <Button
                className="done"
                variant="success"
                onClick={() => {
                    props.stopADCReads();
                    props.setAppState(appStates.Complete);
                }}
            >Complete</Button>
        </Row>
    );
}

export default Running;
