import { GlobalContext } from "components/GlobalContext";
import QRCodeReader from "components/QRCodeReader";
import TouchKeyboard, { KeyboardMode } from "components/touch/TouchKeyboard";
import { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { appStates, IKioskSceneBaseProps } from "../Index";

const SetupCouponCode = (props: IKioskSceneBaseProps) => {
    const globalContext = useContext(GlobalContext);
    const [entryType, setEntryType] = useState(0);
    const [manualSoFar, setManualSoFar] = useState("");

    useEffect(
        () => {
            return () => {
                props.changeAllLedColors(0, 0, 0, 0);
            }
        }, []);

    useEffect(
        () => {
            if (entryType === 1) {
                props.changeAllLedColors(0, 0, 0, 255);
            }
            else {
                props.changeAllLedColors(0, 0, 0, 0);
            }
        },
        [entryType])

    const getTitlePhrase = () => {
        switch (entryType) {
            case 0:
                return "Panel Number?";
            case 1:
                return "Scanning QR";
            case 2:
                return "Manual Entry";
        }
    };

    const doBack = () => {
        switch (entryType) {
            case 0:
                props.setAppState(appStates.Photo);
                break;
            case 1:
            case 2:
                setEntryType(0);
                break;
        }
    };

    const qrSuccess = (data: any) => {
        console.log("Got qr code");
        console.log(data);
        props.setCouponNumber(data.decodedText);
        props.setAppState(appStates.SetupStrips);
    };

    const touchKeyboardOk = (data: string) => {
        if (data) {
            globalContext.showErrorMessage("");
            props.setCouponNumber(data);
            props.setAppState(appStates.SetupStrips);
        }
        else {
            globalContext.showErrorMessage("You must provide a panel number");
        }
    };

    const touchKeyboardCancel = () => {
        setEntryType(0);
    };

    const keypadChangeHandler = (data: string) => {
        setManualSoFar(data);
    }

    return (
        <Row className="screen setup-coupon-code-screen">
            <h1>
                <Button
                    className="back"
                    onClick={doBack}
                >Back</Button>
                {getTitlePhrase()}</h1>

            {
                entryType === 0 &&
                <>
                    <Button
                        className="scan-qr"
                        onClick={() => {
                            setEntryType(1);
                        }}>Scan QR Code</Button>

                    <Button
                        className="manual-entry"
                        onClick={() => {
                            setEntryType(2);
                        }}>Manually Enter</Button>
                </>
            }

            {
                entryType === 1 &&
                <>
                    <QRCodeReader successCallback={qrSuccess} />
                </>
            }

            {
                // TODO: when manually entering show a live photo of the sample so they can try to see the code off of it while entering
                entryType === 2 &&
                <>
                    <span className="manual-preview">{manualSoFar}</span>
                    <TouchKeyboard
                        changeCallback={keypadChangeHandler}
                        mode={KeyboardMode.Digits}
                        cancelCallback={touchKeyboardCancel}
                        okCallback={touchKeyboardOk} />
                </>
            }
        </Row>
    );
}

export default SetupCouponCode