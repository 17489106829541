
import { GlobalContext } from 'components/GlobalContext';
import { ILabRequestTemplateInitResult } from 'models/ILabRequestTemplateInitResult';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Nav, Navbar } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LabRequestTemplateService from 'services/LabRequestTemplateService';
import LabRequestTemplateDetail from './Detail';
import LabRequestTemplateLookup from './Lookup';
import './styles/LabRequestTemplate.scss';

const LabRequestTemplateIndex = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const navigate = useNavigate();
    const [showLookup, setShowLookup] = useState(false);
    const [id, setid] = useState<string>();
    const [baseData, setbaseData] = useState<ILabRequestTemplateInitResult>();

    useEffect(() => {
        globalContext.setSecondaryNav(<>
            <Navbar.Brand href="/FormTemplates"><strong>Form Templates</strong></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/FormTemplates">All</Nav.Link>
                    <Nav.Link onClick={() => showLookupModal()}>Lookup</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </>);

        (async () => {
            globalContextRef.current.setLoading(true);
            const accessToken = await globalContext.getAccessTokenAsync();
            const result = await LabRequestTemplateService.GetInitAsync(accessToken);

            if (result) {
                setbaseData(result);
            }

            globalContextRef.current.setLoading(false);
        })();
    }, []);

    const showLookupModal = () => {
        setShowLookup(true);
    };

    const hideLookupModal = () => {
        setShowLookup(false);
    }

    const lookupTemplateDataAsync = async () => {
        hideLookupModal();

        if (id) {
            globalContext.setLoading(true);
            const accessToken = await globalContext.getAccessTokenAsync();
            const template = await LabRequestTemplateService.GetAsync(accessToken, parseInt(id));

            if (template.record) {
                navigate(`/FormTemplates/Detail/${template.record.labRequestTemplateId}`)
            }
            else {
                globalContext.showErrorMessage(`Couldn't find data for Template Number ${id}`, 10000);
            }

            globalContext.setLoading(false);
        }
    }

    if (!baseData) {
        return (
            <>
            </>
        );
    }

    return (
        <>
            <div>
                <Routes>
                    <Route path={"/Detail/:id"} element={<LabRequestTemplateDetail baseData={baseData} />} />
                    <Route path={"/:page"} element={<LabRequestTemplateLookup baseData={baseData} />} />
                    <Route path={"*"} element={<LabRequestTemplateLookup baseData={baseData} />} />
                </Routes>
            </div>

            <Modal show={showLookup} onHide={hideLookupModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Lookup Template Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="id">
                            <Form.Label>Please provide Template Number</Form.Label>
                            <Form.Control type="text" placeholder="Template Id" value={id} onChange={(e) => setid(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideLookupModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={lookupTemplateDataAsync}>
                        Find Template Data
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LabRequestTemplateIndex;
