import IAPIResult from "models/IAPIResult";
import { ILabRequestEntryInitResult } from "models/ILabRequestEntryInitResult";
import ILabRequestEntry, { ILabRequestEntryCreateOrUpdate, ILabRequestEntryFilters } from "models/sql/ILabRequestEntry";
import ILabRequestTemplate from "models/sql/ILabRequestTemplate";
import { fetchGetAsync, fetchPostAsync } from "utilities/Fetch";
import APIService from "./APIService";

const SERVICE_PATH = "LabRequestEntry";

export default class LabRequestEntryService {
    static async GetAllAsync(accessToken: string, start: number, count: number, filters: ILabRequestEntryFilters): Promise<{ total: number, records: ILabRequestEntry[] | null, records2: ILabRequestTemplate[] | null }> {
        return await APIService.GetAllAsync<ILabRequestEntry, ILabRequestTemplate>(accessToken, SERVICE_PATH, start, count, filters);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: ILabRequestEntry | null, record2: null }> {
        return await APIService.GetAsync<ILabRequestEntry, null>(accessToken, SERVICE_PATH, id);
    }

    static async LookupByCodeAsync(accessToken: string, code: string, formNumber: string = "", exact: boolean = false): Promise<ILabRequestEntry[] | null> {
        return await APIService.CustomAsync<ILabRequestEntry[]>(accessToken, SERVICE_PATH, "lookupByCode", { code, formNumber, exact });
    }

    static async LookupBySearchAllAsync(accessToken: string, phrase: string, formNumber: string = ""): Promise<ILabRequestEntry[] | null> {
        return await APIService.CustomAsync<ILabRequestEntry[]>(accessToken, SERVICE_PATH, "lookupBySearchAllAsync", { phrase, formNumber });
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async CreateOrUpdateAsync(accessToken: string, entry: ILabRequestEntryCreateOrUpdate): Promise<boolean> {
        return APIService.CreateOrUpdate<ILabRequestEntryCreateOrUpdate>(accessToken, SERVICE_PATH, entry);
    }

    static async UploadFileAsync(accessToken: string, formData: FormData): Promise<string> {
        const result: IAPIResult<string> =
            await fetchPostAsync<IAPIResult<string>>(
                `/api/${SERVICE_PATH}/uploadFile`,
                formData,
                accessToken,
                {},
                true,
                true,
                null);

        return result.success ? result.data : "";
    }

    static async GetInitAsync(accessToken: string): Promise<ILabRequestEntryInitResult | null> {
        const result: IAPIResult<ILabRequestEntryInitResult> =
            await fetchGetAsync<IAPIResult<ILabRequestEntryInitResult>>(
                `/api/${SERVICE_PATH}/init`,
                {},
                accessToken);

        return result.success ? result.data : null;
    }
}