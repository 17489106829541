import { GlobalContext, IModalFormElement } from "components/GlobalContext";
import SortableTable, { createFilterableArrayColumnDefinition, createFilterableArraySelectColumnDefinition, createFilterableDateRangeColumnDefinition, IColumnDefinition } from "components/SortableTable";
import { ILabRequestEntryInitResult } from "models/ILabRequestEntryInitResult";
import ILabRequestEntry, { ILabRequestEntryCreateOrUpdate, ILabRequestEntryFilters } from "models/sql/ILabRequestEntry";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Nav, Navbar, Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import LabRequestEntryService from "services/LabRequestEntryService";
import { exportDataToCsv, getObjectFromQuerystring } from "utilities/BrowserUtility";
import { DataType } from "utilities/FormUtility";
import { formatDate } from "utilities/StringUtility";
import { DEFAULT_TABLE_RECORD_COUNT } from "utilities/UIUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faListCheck, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { addNewLogEntry, generateSubmissionLog, generateSubmissionResults, typesToSkip } from "./LabRequestEntryUtilities";
import ILabRequestTemplate, { ICustomWorkflowData } from "models/sql/ILabRequestTemplate";
import LabRequestTemplateService from "services/LabRequestTemplateService";

interface ILabRequestEntrySubmissionsProps {
    baseData?: ILabRequestEntryInitResult;
}

const LabRequestEntrySubmissions = (props: ILabRequestEntrySubmissionsProps) => {
    const getSearchParams = (params: URLSearchParams, extraDefaults?: any) => {
        const defaultParams =
            params.toString().indexOf("=") !== -1 ?
                {} : // if there's a querystring already, don't apply defaults
                {
                    ...{
                        formNumbers: formNumber !== "all" ? [formNumber] : [],
                        sortBys: ["Priority"],
                        sortDescending: true,
                        statuses: [0, 1, 2, 3],
                        originEntries: false,
                        assignedTos: [globalContext.userEmail]
                    } as ILabRequestEntryFilters,
                    ...extraDefaults || {}
                };

        const queryParams =
            getObjectFromQuerystring(
                params,
                {
                    labRequestEntryIds: "Array",
                    formNumbers: "Array",
                    labRequestTemplateIds: "Array",
                    statuses: "Array",
                    assignedTos: "Array",
                    contactEmails: "Array",
                    submissionCodes: "Array",
                    sortBys: "Array",
                    sortDescending: "Boolean",
                    hasAttachmentUrls: "Boolean",
                    originEntries: "Boolean"
                });

        return ({ ...defaultParams, ...queryParams });
    }

    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [searchParams, setSearchParams] = useSearchParams();
    const [entries, setEntries] = useState<ILabRequestEntry[]>([]);
    const navigate = useNavigate();
    const { formNumber, page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);
    const { search } = useLocation();
    const [filter, setFilter] = useState<ILabRequestEntryFilters>(getSearchParams(searchParams));
    const [refresh, setRefresh] = useState<Date>(new Date());
    const [accessToken, setAccessToken] = useState("");
    const [template, setTemplate] = useState<ILabRequestTemplate>();
    const [lookupEntries, setLookupEntries] = useState<ILabRequestEntry[]>();
    const [initialDataReady, setInitialDataReady] = useState(false);
    const [workflow, setWorkflow] = useState<ICustomWorkflowData>({
        filters: [
            { title: "Any", value: "-1" },
            { title: "All Open", value: "0,1,2,3" },
            { title: "Issues", value: "2,3" },
            { title: "New", value: "0" },
            { title: "In Progress", value: "1" },
            { title: "Internal Issue", value: "2" },
            { title: "Exeternal Issue", value: "3" },
            { title: "Complete", value: "4" },
            { title: "Cancelled", value: "5" }
        ],
        statuses: ["New", "In Progress", "Internal Issue", "External Issue", "Complete", "Cancelled"],
        workflows: []
    });
    const priorities = [
        { title: "None", value: "" },
        { title: "High", value: "3" },
        { title: "Medium", value: "2" },
        { title: "Low", value: "1" }
    ];

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setSecondaryNav(<>
                    <Navbar.Brand href="/FormSubmissions"><strong>Back to form select</strong></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Button
                                style={{ marginRight: "10px" }}
                                onClick={
                                    () => {
                                        globalContext.showModal({
                                            title: "Lookup by Submission Code",
                                            formElements: [{
                                                key: "code",
                                                type: "text",
                                                title: "Submission Code (Whole or Partial)",
                                                value: ""
                                            }],
                                            content: <></>,
                                            size: "lg",
                                            okButtonTitle: "Find Submissions",
                                            okCallback: async (data: any) => {
                                                globalContext.setLoading(true);
                                                const accessToken = await globalContext.getAccessTokenAsync();
                                                const entrys = await LabRequestEntryService.LookupByCodeAsync(accessToken, data[0].value, formNumber);
                                                globalContext.setLoading(false);
                                                setLookupEntries(entrys || []);
                                            }
                                        });
                                    }}>Lookup by Code</Button>
                            <Button onClick={
                                () => {
                                    globalContext.showModal({
                                        title: "Lookup by phrase in data",
                                        formElements: [{
                                            key: "code",
                                            type: "text",
                                            title: "Phrase in submitted data, log or required status fields (Whole or Partial)",
                                            value: ""
                                        }],
                                        content: <></>,
                                        size: "lg",
                                        okButtonTitle: "Find Submissions",
                                        okCallback: async (data: any) => {
                                            globalContext.setLoading(true);
                                            const accessToken = await globalContext.getAccessTokenAsync();
                                            const entrys = await LabRequestEntryService.LookupBySearchAllAsync(accessToken, data[0].value, formNumber);
                                            globalContext.setLoading(false);
                                            setLookupEntries(entrys || []);
                                        }
                                    });
                                }}>Lookup by phrase in data</Button>
                        </Nav>
                    </Navbar.Collapse>
                </>);

                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(accessToken);
                const templateResult =
                    await LabRequestTemplateService.getByFormNumber(
                        accessToken,
                        formNumber);

                if (templateResult) {
                    setTemplate(templateResult);

                    if (templateResult.customWorkflowDataJson) {
                        const workflowdata: ICustomWorkflowData = JSON.parse(templateResult.customWorkflowDataJson);

                        if (workflowdata) {
                            setFilter({
                                ...getSearchParams(
                                    searchParams,
                                    {
                                        statuses: [...Array(workflowdata.statuses.length - 2)].map((x, index) => index)
                                    })
                            });

                            setWorkflow(workflowdata);
                        }
                    }
                }

                setInitialDataReady(true);
            })();
        }, []);

    useEffect(
        () => {
            (async () => {
                if (initialDataReady) {
                    //@ts-ignore
                    const accessToken = await globalContextRef.current.getAccessTokenAsync();
                    globalContextRef.current.setLoading(true);

                    const result =
                        await LabRequestEntryService.GetAllAsync(
                            accessToken,
                            page - 1,
                            DEFAULT_TABLE_RECORD_COUNT,
                            filter);

                    if (result.records != null) {
                        setEntries(result.records);
                        setTotalRecords(result.total);
                    }
                    else {
                        globalContextRef.current.showErrorMessage("Unable to load recent Entries");
                    }

                    globalContextRef.current.setLoading(false);
                }
            })();
        }, [page, formNumber, filter, refresh, initialDataReady]);

    // useEffect(
    //     () => {
    //         const searchSettings = getSearchParams(searchParams);
    //         const reducedSearchSettings = JSON.stringify(searchSettings);
    //         if (JSON.stringify(filter) !== reducedSearchSettings) {
    //             // setFilter(searchSettings);
    //         }
    //     },
    //     [search]);

    useEffect(
        () => {
            const defaultStatusPhrases = ["New", "In Progress", "Internal Issue", "External Issue", "Complete"];

            if (lookupEntries && lookupEntries.length > 0) {
                globalContext.showModal(
                    {
                        title: "Submission Lookup",
                        content: <>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <td>Form</td>
                                        <td>Submission Date</td>
                                        <td>Status</td>
                                        <td>Close Date</td>
                                        <td>Submission Code</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lookupEntries.map(
                                        (entry, index) => {

                                            let statusName = defaultStatusPhrases[entry.status];

                                            if (entry.labRequestTemplate.customWorkflowDataJson) {
                                                const workflowdata: ICustomWorkflowData = JSON.parse(entry.labRequestTemplate.customWorkflowDataJson);
                                                statusName = workflowdata.statuses[entry.status];
                                            }

                                            return (
                                                <tr
                                                    key={index}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        navigate(`/FormSubmissions/Detail/${entry.labRequestEntryId}`);
                                                        globalContext.hideModal();
                                                    }}>
                                                    <td>{`${entry.labRequestTemplate.title} (${entry.labRequestTemplate.formNumber} Ver. ${entry.labRequestTemplate.version})`}</td>
                                                    <td>{formatDate(new Date(entry.submissionDate))}</td>
                                                    <td>{statusName}</td>
                                                    <td>{entry.closeDate ? formatDate(new Date(entry.submissionDate)) : "Still Open"}</td>
                                                    <td>{entry.submissionCode}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </ Table>
                        </>
                    });
            }
            else {
                if (lookupEntries && lookupEntries.length === 0) {
                    globalContext.showModal(
                        {
                            title: "Submission Lookup",
                            content: <><strong>No submissions found</strong></>
                        });
                }
            }
        },
        [lookupEntries]);

    const updateSearchParams = (newParams: any) => {
        setSearchParams(newParams);
        setFilter(newParams);
    };

    const saveEntryUpdate = async (entry: ILabRequestEntry) => {
        globalContext.setLoading(true);
        const data: ILabRequestEntryCreateOrUpdate = {
            closeDate: entry.closeDate != null ? entry.closeDate : undefined,
            dataJson: entry.dataJson,
            labRequestEntryId: entry.labRequestEntryId,
            labRequestTemplateId: entry.labRequestTemplateId,
            submissionDate: entry.submissionDate,
            assignedTo: entry.assignedTo,
            labNotes: entry.labNotes,
            status: entry.status,
            contactEmail: entry.contactEmail,
            closedBy: entry.closedBy,
            submissionCode: entry.submissionCode,
            lastStatusContact: entry.lastStatusContact,
            attachmentUrls: entry.attachmentUrls,
            formAttachmentUrls: entry.formAttachmentUrls,
            backfillOrigin: entry.backfillOrigin,
            additionalCompleteContent: entry.additionalCompleteContent,
            additionalExternalIssueContent: entry.additionalExternalIssueContent,
            systemDataJson: entry.systemDataJson,
            formNumber: entry.formNumber,
            privateAttachmentUrls: entry.privateAttachmentUrls,
            backFilledFrom: entry.backFilledFrom,
            priority: entry.priority
        };

        await LabRequestEntryService.CreateOrUpdateAsync(accessToken, data);
        globalContext.setLoading(false);
    }

    const getFilteringBy = () => {
        if (JSON.stringify(filter) !== "{}") {
            const shownFilterProps =
                Object.keys(filter)
                    // @ts-ignore
                    .filter(p => filter[p] && (Array.isArray(filter[p]) ? filter[p].length > 0 : filter[p] !== ""))
                    .filter(p => p !== "sortBys" && p !== "sortDescending" && p !== "labRequestTemplateIds")
                    .map(p => {
                        switch (p) {
                            case "hasAttachmentUrls":
                                return "Has Attachments";
                            case "statuses":
                                return "Status";
                            case "contactEmails":
                                return "Contact";
                            case "submissionDateStart":
                                return "Submission Date";
                            case "closeDateStart":
                                return "Close Date";
                            case "lastUpdatedStart":
                                return "Last Updated Date";
                            case "assignedTos":
                                if (filter.assignedTos && filter.assignedTos.length === 1 && filter.assignedTos[0] === globalContext.userEmail) {
                                    return "Assigned To You";
                                }
                                else {
                                    return "Assigned To";
                                }
                            default:
                                return "";
                        }
                    })
                    .filter(p => !!p);

            if (shownFilterProps.length > 0) {
                return (
                    <strong>Filtering by {shownFilterProps.join(" & ")}</strong>
                );
            }
            else {
                return <></>;
            }
        }

        return <></>;
    }

    //@ts-ignore
    const extraColumnKeys: { key: string, title: string }[] =
        entries.flatMap(
            entry => {
                const parsedData = JSON.parse(entry.dataJson);
                return Object.keys(parsedData).map(
                    key => {
                        if (
                            parsedData[key].hasOwnProperty("showOnTable") &&
                            parsedData[key].showOnTable === "true") {
                            return ({
                                key: key,
                                title: parsedData[key].title
                            });
                        }
                        else {
                            return null;
                        }
                    });
            })
            .filter(
                (entry, index, array) => {
                    return entry && entry.key && array.findIndex(e => e && e.key === entry.key) === index
                });

    const showQuickNotes = (entry: ILabRequestEntry) => {
        const formData: IModalFormElement[] = [{
            key: "labNotes",
            type: "textarea",
            title: "Internal Notes",
            value: entry.labNotes,
            style: { width: "100%", height: "200px" }
        }];

        globalContext.showModal({
            title: `Entry ${entry.labRequestEntryId} quick notes view`,
            cancelButtonTitle: "Close",
            formElements: formData,
            content: <></>,
            size: "lg",
            okButtonTitle: "Save Changes",
            okCallback: (data: any) => {
                let updateEntry = entries.find(e => e.labRequestEntryId === entry.labRequestEntryId);
                if (updateEntry) {
                    // @ts-ignore
                    updateEntry.labNotes = data[0].value;

                    updateEntry =
                        addNewLogEntry(
                            (new Date()),
                            `${globalContext.userName} changed the notes to '${data[0].value}'`,
                            globalContext.userEmail,
                            101,
                            updateEntry);

                    saveEntryUpdate(updateEntry);
                    setEntries([...entries]);
                }
            }
        });
    }

    const showQuickSubmission = (entry: ILabRequestEntry) => {
        globalContext.showModal({
            title: `Entry ${entry.labRequestEntryId} quick data view`,
            cancelButtonTitle: "Close",
            content: generateSubmissionResults(entry),
            size: "lg"
        })
    }

    const showQuickLog = (entry: ILabRequestEntry) => {
        globalContext.showModal({
            title: `Entry ${entry.labRequestEntryId} quick log view`,
            cancelButtonTitle: "Close",
            content: generateSubmissionLog(entry),
            size: "lg"
        })
    }

    //@ts-ignore
    const extraColumns: IColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>[] =
        extraColumnKeys.map(
            entry => ({
                propName: entry.key as keyof ILabRequestEntry,
                title: entry.title,
                customRenderer: (value, data) => {
                    const parsedData = JSON.parse(data.dataJson);
                    return (<td className="align-top">{
                        parsedData.hasOwnProperty(entry.key) && parsedData[entry.key].hasOwnProperty("value") ?
                            parsedData[entry.key].value :
                            ""
                    }</td>)
                },
            }));

    const toggleShowBackfill = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.checked;
        setFilter({ ...filter, originEntries: val });
    };

    const changePriorityAsync = async (newPriority: string, newPriorityName: string, entry: ILabRequestEntry) => {
        let updateEntry = entries.find(e => e.labRequestEntryId === entry.labRequestEntryId);
        if (updateEntry) {
            updateEntry.priority = parseInt(newPriority);
            await saveEntryUpdate(updateEntry);
            setRefresh(new Date());
        }
    };

    const changeStatus = (newStatus: string, newStatusName: string, entry: ILabRequestEntry) => {
        let updateEntry = entries.find(e => e.labRequestEntryId === entry.labRequestEntryId);

        const statusType =
            (workflow && workflow.workflows && workflow.workflows.length > 0) ?
                workflow.workflows[parseInt(newStatus)].statusType :
                parseInt(newStatus);

        const formElements: IModalFormElement[] = [];

        if (statusType === 2) { // internal issue
            formElements.push({
                key: "labNotes",
                type: "textarea",
                title: "Please add some details about the issue to our internal notes",
                value: entry.labNotes,
                style: { width: "100%", height: "200px" }
            });
        }
        else if (statusType === 3 && entry.contactEmail) { // external issue
            formElements.push({
                key: "additionalExternalIssueContent",
                type: "textarea",
                title: `Message to be sent to ${entry.contactEmail} describing the issue`,
                value: entry.additionalExternalIssueContent,
                style: { width: "100%", height: "200px" }
            });
        }
        else if (statusType === 4 && entry.contactEmail) { // close
            formElements.push({
                key: "additionalCompleteContent",
                type: "textarea",
                title: `Additional info to be sent to ${entry.contactEmail} after closing this submission`,
                value: entry.additionalCompleteContent,
                style: { width: "100%", height: "200px" }
            });
        }

        if (workflow && workflow.workflows[parseInt(newStatus)]) {
            if (workflow.workflows[parseInt(newStatus)].requiredFields) {
                workflow.workflows[parseInt(newStatus)].requiredFields?.forEach(
                    field => {
                        formElements.push({
                            // @ts-ignore
                            type: field.type,
                            key: field.title,
                            title: field.title + " (Required)",
                            required: true
                        })
                    });
            }
        }

        globalContext.showModal({
            title: `Change Status`,
            cancelButtonTitle: "Cancel",
            formElements: formElements,
            content: <><strong>Are you sure you want to update the status on this submission?</strong></>,
            size: "lg",
            okButtonTitle: "Change Status",
            cancelCallback: () => { },
            okCallback: async (data: any) => {
                if (updateEntry) {
                    if (statusType === 2) {
                        updateEntry.labNotes = data[0].value;

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}' with a message of '${data[0].value}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);

                        data.shift();
                    }
                    else if (statusType === 3 && entry.contactEmail) {
                        updateEntry.additionalExternalIssueContent = data[0].value;

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}' with a message of '${data[0].value}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);

                        data.shift();
                    }
                    else if (statusType === 4 && entry.contactEmail) {
                        updateEntry.additionalCompleteContent = data[0].value;

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}' with a message of '${data[0].value}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);

                        data.shift();
                    }
                    else {
                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);
                    }

                    if (data && data.length > 0) {
                        if (!updateEntry.systemDataJson) {
                            updateEntry.systemDataJson = "{}";
                        }

                        const parsedSystemData = JSON.parse(updateEntry.systemDataJson);
                        let extraData = parsedSystemData?.extraData || {};

                        data.forEach(
                            (entry: any) => {
                                if (entry.value === null || entry.value === "") {
                                }
                                else {
                                    extraData[entry.key] = entry.value;
                                }
                            });

                        updateEntry.systemDataJson = JSON.stringify({ ...parsedSystemData, ...{ extraData } });

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated extra submission data: '${JSON.stringify(extraData)}'`,
                                globalContext.userEmail,
                                100,
                                updateEntry);
                    }

                    updateEntry.status = parseInt(newStatus);
                    await saveEntryUpdate(updateEntry);
                    setRefresh(new Date());
                }
            }
        });
    };

    const getColumns = (): IColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>[] => {
        return [
            {
                propName: "labRequestEntryId",
                title: "Actions",
                customRenderer: (value, data) => {
                    return (<td key={`align-top actions${value}`}>
                        <span className="quick-action" title="Quick note view" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            showQuickNotes(data); return false;
                        }}><FontAwesomeIcon icon={faMoneyCheck as IconProp} /></span>
                        <span className="quick-action" title="Quick submission view" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            showQuickSubmission(data); return false;
                        }}><FontAwesomeIcon icon={faClipboardList as IconProp} /></span>
                        <span className="quick-action" title="Quick submission view" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            showQuickLog(data); return false;
                        }}><FontAwesomeIcon icon={faListCheck as IconProp} /></span>

                    </td>)
                },
                sortable: false
            },
            createFilterableArraySelectColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "priority",
                "priorities",
                true,
                filter,
                priorities,
                DataType.Number,
                (value, row) => <td className="align-top" key={`priority${row.labRequestEntryId}`}>
                    <Form.Select
                        style={{ width: "150px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }}
                        onChange={(e) => {
                            // @ts-ignore
                            changePriorityAsync(e.currentTarget.value, e.currentTarget.selectedOptions[0].innerHTML || "", row)
                        }}
                        value={value}>
                        {
                            (priorities || []).map((s, index) => <option key={index} value={s.value}>{s.title}</option>)
                        }
                    </Form.Select>
                </td>),
            {
                propName: "labRequestEntryId",
                title: "Form",
                customRenderer: (value, data) => <td className="align-top" key={`entryid${value}`}>
                    {data.labRequestTemplate.title}<br />{data.labRequestTemplate.formNumber} Rev. {data.labRequestTemplate.version}
                </td>,
                sortable: true
            },
            {
                propName: "submissionCode",
                title: "Submission Code",
                sortable: true
            },
            createFilterableArraySelectColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "status",
                "statuses",
                true,
                filter,
                (workflow?.filters || []),
                DataType.Number,
                (value, row) => <td className="align-top" key={`status${row.labRequestEntryId}`}>
                    <Form.Select
                        style={{ width: "150px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }}
                        onChange={(e) => {
                            // @ts-ignore
                            changeStatus(e.currentTarget.value, e.currentTarget.selectedOptions[0].innerHTML || "", row)
                        }}
                        value={value}>
                        {
                            (workflow?.statuses || []).map((s, index) => <option key={s} value={index}>{s}</option>)
                        }
                    </Form.Select>
                </td>),
            createFilterableDateRangeColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "submissionDate",
                // @ts-ignore
                "submissionDate",
                true,
                filter,
                (value, row) => <td className="align-top" key={`submissiondate${row.labRequestEntryId}`}>
                    {row.submissionDate ? formatDate(new Date(row.submissionDate)) : ""}
                </td>),
            createFilterableDateRangeColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "lastUpdated",
                // @ts-ignore
                "lastUpdated",
                true,
                filter,
                (value, row) => <td className="align-top" key={`lastupdated${row.labRequestEntryId}`}>
                    {row.lastUpdated ? formatDate(new Date(row.lastUpdated)) : ""}
                </td>),
            createFilterableDateRangeColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "closeDate",
                // @ts-ignore
                "closeDate",
                true,
                filter,
                (value, row) => <td className="align-top" key={`closedate${row.labRequestEntryId}`}>
                    {row.closeDate ? formatDate(new Date(row.closeDate)) : ""}
                </td>),
            createFilterableArrayColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "closedBy",
                "closedBys",
                true,
                filter),
            createFilterableArrayColumnDefinition<ILabRequestEntry, ILabRequestEntryFilters>(
                globalContext,
                "assignedTo",
                "assignedTos",
                true,
                filter,
                undefined,
                (value, row) => <td className="align-top" key="">
                    {
                        (row.assignedTo || "").split(",")
                            .map(
                                (e, index, array) => <>{e}{array.length > 1 && <br></br>}</>)
                    }</td>),
            ...extraColumns
        ];
    }

    const exportToCSV = () => {
        // @ts-ignore
        const extraExportKeys: { key: string, title: string }[] =
            entries.flatMap(
                entry => {
                    const parsedData = JSON.parse(entry.dataJson);
                    return Object.keys(parsedData)
                        .filter((k: any) => typesToSkip.indexOf(parsedData[k].type) === -1)
                        .map(
                            key => ({
                                key: key,
                                title:
                                    parsedData[key].hasOwnProperty("internalTitle") ?
                                        parsedData[key].internalTitle :
                                        parsedData[key].title
                            }));
                })
                .filter(
                    (entry, index, array) => {
                        return entry && entry.key && array.findIndex(e => e && e.key === entry.key) === index
                    });

        let rows: any = entries.map(entry => {
            const parsedData = JSON.parse(entry.dataJson);

            let result: any = {
                formName: entry.labRequestTemplate.title,
                formNumber: entry.labRequestTemplate.formNumber,
                status: entry.status,
                assignedTo: entry.assignedTo,
                contactEmail: entry.contactEmail,
                submissionDate: entry.submissionDate,
                lastUpdated: entry.lastUpdated,
                closeDate: entry.closeDate || "Still Open",
                closedBy: entry.closedBy,
                notes: entry.labNotes,
                closeNotes: entry.additionalCompleteContent,
                externalIssueNotes: entry.additionalExternalIssueContent
            };

            extraExportKeys.forEach(e => { result[e.key] = parsedData.hasOwnProperty(e.key) ? parsedData[e.key].value : ""; });
            return result;
        });

        const extraExportColumns: string[] = extraExportKeys.map(entry => entry.title);
        const formNames =
            entries
                .map(e => e.labRequestTemplate.title)
                .filter((entry, index, array) => entry && array.findIndex(e => e && e === entry) === index);

        let headers: any = ["Form Name", "Form Number", "Status", "Assigned to", "Contact Email", "Submission Date", "Last Updated", "Close Date", "Closed By", "Internal Notes", "Close Notes", "Exernal Issue Notes", ...extraExportColumns];
        const fileName = `${formNames.length > 1 ? "Multiple form submission export" : formNames[0]} export.csv`;
        exportDataToCsv(rows, headers, fileName);
    };

    return (
        <SortableTable<ILabRequestEntry, ILabRequestEntryFilters>
            changePageCallback={(newPage) => navigate(`/FormSubmissions/Form/${formNumber}/${newPage + 1}${window.location.search}`)}
            page={page}
            className="lookup-table"
            totalRecords={totalRecords}
            recordCount={entries.length}
            records={entries}
            setFilter={updateSearchParams}
            filter={filter}
            rowClickCallback={(data) => navigate(`/FormSubmissions/Detail/${data.labRequestEntryId}`)}
            rowClassRenderer={(data) => {
                const statusType =
                    (workflow && workflow.workflows && workflow.workflows.length > 0) ?
                        workflow.workflows[data.status].statusType :
                        data.status;

                var statusTypeName =
                    statusType === 4 ? "closed" :
                        statusType === 3 ? "externalissue" :
                            statusType === 2 ? "internalissue" :
                                statusType === 1 ? "inprogress" :
                                    "";

                return `priority${data.priority} ${statusTypeName}`;
            }
            }
            tools={[
                <span
                    className="float-start"
                    key="showOriginaction">
                    <label htmlFor="showOrigin"><input name="showOrigin" id="showOrigin" type="checkbox" onChange={(e) => toggleShowBackfill(e)}></input>&nbsp;<strong>Backfill origins</strong></label>
                </span>,
                <span
                    className="p-2"
                    key="filtersummary">{getFilteringBy()}</span>,
                <Button
                    className="me-2"
                    key="resetfilter"
                    onClick={() => {
                        // @ts-ignore
                        window.location = `/FormSubmissions/Form/${formNumber}/1`;
                    }}>Reset</Button>,
                <Button
                    key="exporttable"
                    onClick={() => { exportToCSV(); }}>Export to CSV</Button>
            ]}
            columnDefinitions={getColumns()}
        />
    );
}

export default LabRequestEntrySubmissions;