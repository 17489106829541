import React, { useState, FC, createContext, useEffect, ReactElement, useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useIsAuthenticated } from "@azure/msal-react";
import { graphConfig, loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";

import { ISelectOption } from "utilities/FormUtility";
import LoggedInContent from "routes/LoggedInContent";
import LoggedOutContent from "routes/LoggedOutContent";

export interface IGlobalContextProps {
    loading: boolean;
    isAuthenticated: boolean;
    userName: string;
    userEmail: string;
    getAccessTokenAsync: () => Promise<string>;
    setLoading: (state: boolean) => void;
    setTitle: (title: string) => void;
    setSecondaryNav: (content: React.ReactElement) => void;
    showModal: (settings: IModalSettings) => void;
    hideModal: () => void;
    showErrorMessage: (message: string, timeout?: number, variant?: string) => void;
    roles: string[];
}

export const GlobalContext = createContext<IGlobalContextProps>({
    loading: false,
    isAuthenticated: false,
    userName: "",
    userEmail: "",
    getAccessTokenAsync: () => new Promise((resolve, reject) => resolve("")),
    setLoading: (state: boolean) => { },
    setTitle: (title: string) => { },
    setSecondaryNav: (content: React.ReactElement) => { },
    showModal: (settings: IModalSettings) => { },
    hideModal: () => { },
    showErrorMessage: (message: string, timeout?: number, variant?: string) => { },
    roles: []
});

interface GlobalContextWrapperProps {
    children: React.ReactNode;
}

export interface IModalFormElement {
    type: "text" | "select" | "date" | "time" | "datetime" | "textarea" | "multiselect" | "adder";
    key: string;
    title: string;
    value?: string;
    options?: ISelectOption[];
    style?: React.CSSProperties;
    className?: string;
    useFilter?: boolean;
    filterPhrase?: string;
    adderBuffer?: string;
    adderBuffer2?: string;
    required?: boolean;
    error?: string;
};

interface IModalSettings {
    content: ReactElement | string;
    title?: ReactElement | string;
    okCallback?: (data: IModalFormElement[] | null) => void;
    cancelCallback?: () => void;
    okButtonTitle?: string;
    cancelButtonTitle?: string;
    formElements?: IModalFormElement[];
    size?: "sm" | "lg" | "xl";
    fullScreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
};

const GlobalContextWrapper: FC<GlobalContextWrapperProps> = (props: GlobalContextWrapperProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorCustomVariant, setErrorCustomVariant] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [graphData, setGraphData] = useState<any>(null);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [title, setTitle] = useState("");
    const [secondaryNav, setSecondaryNav] = useState<React.ReactElement>();
    const [modalSettings, setModalSettings] = useState<IModalSettings | null>(null);
    const [modalFormData, setModalFormData] = useState<IModalFormElement[] | null>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const rolesRef = useRef(roles);
    rolesRef.current = roles;

    const showModal = (settings: IModalSettings) => {
        if (settings.formElements) {
            // default any select boxes if the current valueis empty
            settings.formElements.forEach(
                entry => {
                    if (entry.type === "select" && entry.options?.[0].value !== null && entry.options?.[0].value !== undefined) {
                        entry.value = entry.options?.[0].value;
                    }
                });

            setModalFormData(settings.formElements);
        }
        setModalSettings(settings);
    };

    const hideModal = () => {
        setModalSettings(null);
    };

    const getProfileAndTokenAsync = async (msalInstance: any) => {
        const account = msalInstance.getActiveAccount();

        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const request = {
            ...loginRequest,
            account: account
        };

        const response =
            await msalInstance.acquireTokenSilent(request)
                .catch(async (error: any) => {
                    return msalInstance.acquireTokenRedirect(request);
                });

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };

        return fetch(graphConfig.graphMeEndpoint, options)
            .then(response => response.json())
            .catch(error => console.log(error));
    }

    const showErrorMessage = (message: string, timeout?: number, variant?: string) => {
        if (variant) {
            setErrorCustomVariant(variant);
        }

        setErrorMessage(message);

        if (timeout) {
            setTimeout(
                () => {
                    setErrorMessage("");
                }, timeout);
        }
    }

    const RequestProfileDataAsync = async () => {
        try {
            if (!graphData) {
                let graphResult = await getProfileAndTokenAsync(instance);
                setGraphData(graphResult);
                setUserName(graphResult.displayName);
                setUserEmail(graphResult.mail);
                await getAccessTokenAsync();
            }
        }
        catch (e: any) {
            console.log("Couldn't get graph data");
        }
    };

    const getAccessTokenAsync = async () => {
        const account = instance.getActiveAccount();

        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const accessTokenRequest = {
            scopes: ["api://0be9157c-6839-45f7-8c67-ecb967d89057/Web.CCCAquisitionData"],
            account: account
        }

        const response =
            await instance.acquireTokenSilent(accessTokenRequest)
                .catch(async (error: any) => {
                    instance.acquireTokenRedirect(accessTokenRequest);
                });

        if (rolesRef.current.length < 1) {
            // @ts-ignore
            setRoles(response.account?.idTokenClaims?.roles || []);
        }

        return response?.accessToken ?? "";
    }

    useEffect(
        () => {
            instance.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const payload = event.payload as AuthenticationResult;
                    const account = payload.account;
                    instance.setActiveAccount(account);
                }
            });

            (async () => {
                if (isAuthenticated) {
                    setLoading(true);
                    await RequestProfileDataAsync();
                    setLoading(false);
                }
            })();
        }, [isAuthenticated, instance]);

    const handleLogout = (instance: any) => {
        instance
            .logoutRedirect()
            .catch((e: any) => {
                console.error(e);
            });
    }

    const handleLogin = (instance: any) => {
        instance
            .loginRedirect(loginRequest)
            .catch((e: any) => {
                console.error(e);
            });
    }

    return (
        <>
            {
                loading &&
                <div className="loading-display">
                    <div className="content">
                        <h2>
                            <Spinner animation="border" role="status"></Spinner>
                            &nbsp;Loading...
                        </h2>
                    </div>
                </div>
            }
            {
                errorMessage &&
                <Alert
                    variant={errorCustomVariant || "danger"}
                    onClose={() => setErrorMessage("")}
                    dismissible>
                    {errorMessage}
                </Alert>
            }
            <GlobalContext.Provider value={{ loading, setLoading, showErrorMessage, getAccessTokenAsync, isAuthenticated, userName, userEmail, setTitle, setSecondaryNav, showModal, hideModal, roles }}>
                <div style={{ display: loading ? "none" : "" }}>
                    {
                        (isAuthenticated && userName) ?
                            <LoggedInContent
                                displayName={userName}
                                handleLogout={handleLogout}
                                instance={instance}
                                secondaryNav={secondaryNav}
                                title={title}>{props.children}</LoggedInContent> :
                            <LoggedOutContent
                                handleLogin={handleLogin}
                                instance={instance}></LoggedOutContent>
                    }
                </div>
                <Modal
                    id="globalPopup"
                    show={modalSettings != null}
                    size={modalSettings?.size || "lg"}
                    fullscreen={modalSettings?.fullScreen || undefined}
                    onHide={() => {
                        if (modalSettings?.cancelCallback) {
                            modalSettings.cancelCallback();
                        }

                        setModalSettings(null);
                        setModalFormData(null);
                    }}>
                    {
                        modalSettings?.title &&
                        <Modal.Header closeButton>
                            <Modal.Title>{modalSettings.title}</Modal.Title>
                        </Modal.Header>
                    }

                    <Modal.Body>
                        <Row className="align-items-end">
                            <Col>
                                {modalSettings?.content}
                            </Col>
                        </Row>
                        {
                            modalSettings?.formElements?.map(
                                (element, index) =>
                                    <Row className="align-items-end" key={index}>
                                        <Col>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{element.title}</Form.Label>
                                                {
                                                    element.error &&
                                                    <Form.Control.Feedback type="invalid" style={{ display: "block" }}>{element.error}</Form.Control.Feedback>
                                                }
                                                {
                                                    element.type === "text" &&
                                                    <Form.Control
                                                        type="text"
                                                        style={element.style}
                                                        className={element.className}
                                                        isInvalid={!!element.error}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                element.value = e.target.value;
                                                                setModalFormData([...(modalFormData || [])]);
                                                            }
                                                        }
                                                        value={element.value}
                                                    />
                                                }
                                                {
                                                    element.type === "textarea" &&
                                                    <Form.Control
                                                        as="textarea"
                                                        style={element.style}
                                                        className={element.className}
                                                        isInvalid={!!element.error}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                element.value = e.target.value;
                                                                setModalFormData([...(modalFormData || [])]);
                                                            }
                                                        }
                                                        value={element.value}
                                                    />
                                                }
                                                {
                                                    element.type === "select" &&
                                                    <Form.Select
                                                        value={element.value}
                                                        style={element.style}
                                                        className={element.className}
                                                        isInvalid={!!element.error}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                element.value = e.target.value;
                                                                setModalFormData([...(modalFormData || [])]);
                                                            }
                                                        }>
                                                        {(element.options || []).map((option, index) => <option key={option.value} value={option.value}>{option.title}</option>)}
                                                    </Form.Select>
                                                }
                                                {
                                                    element.type === "multiselect" &&
                                                    <>
                                                        {element.useFilter &&
                                                            <>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            element.filterPhrase = e.currentTarget.value;
                                                                            setModalFormData([...(modalFormData || [])]);
                                                                        }
                                                                    }
                                                                    value={element.filterPhrase}
                                                                /><br />
                                                            </>
                                                        }
                                                        <Form.Select
                                                            multiple={true}
                                                            style={element.style}
                                                            className={element.className}
                                                            isInvalid={!!element.error}
                                                            value={(element.value || "")?.split(",")}
                                                            onChange={
                                                                (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const options = e.target.selectedOptions;
                                                                    // @ts-ignore
                                                                    element.value = [].slice.call(options).map(o => o.value).join(",");
                                                                    setModalFormData([...(modalFormData || [])]);
                                                                }
                                                            }>
                                                            {
                                                                (element.options || [])
                                                                    .filter(
                                                                        o =>
                                                                            element.filterPhrase && o ?
                                                                                o.title.toLowerCase().indexOf(element.filterPhrase.toLowerCase()) > -1 :
                                                                                true)
                                                                    .map(option => <option key={option.value} value={option.value}>{option.title}</option>)
                                                            }
                                                        </Form.Select>
                                                    </>
                                                }
                                                {
                                                    element.type === "adder" &&
                                                    <>
                                                        {element.useFilter &&
                                                            <>
                                                                <br /><strong>Filter available options</strong>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="mb-1"
                                                                    onChange={
                                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            element.filterPhrase = e.currentTarget.value;
                                                                            setModalFormData([...(modalFormData || [])]);
                                                                        }
                                                                    }
                                                                    value={element.filterPhrase}
                                                                />
                                                            </>
                                                        }
                                                        <Form.Select
                                                            multiple={true}
                                                            style={element.style}
                                                            className={element.className}
                                                            isInvalid={!!element.error}
                                                            value={(element.adderBuffer || "")?.split(",")}
                                                            onChange={
                                                                (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const options = e.target.selectedOptions;
                                                                    // @ts-ignore
                                                                    element.adderBuffer = [].slice.call(options).map(o => o.value).join(",");
                                                                    setModalFormData([...(modalFormData || [])]);
                                                                }
                                                            }>
                                                            {
                                                                (element.options || [])
                                                                    .filter(
                                                                        (o: any) =>
                                                                            ((element.value || "")
                                                                                .split(",") || [])
                                                                                .findIndex(a => a === o.value) === -1)
                                                                    .filter((o: any) => element.filterPhrase && o ?
                                                                        o.title.toLowerCase().indexOf(element.filterPhrase.toLowerCase()) > -1 :
                                                                        true)
                                                                    .map(option => <option key={option.value} value={option.value}>{option.title}</option>)
                                                            }
                                                        </Form.Select>
                                                        <div className="text-center">
                                                            <Button
                                                                className="m-3"
                                                                style={{ width: "45%" }}
                                                                onClick={(e) => {
                                                                    element.value = (element?.value ? element.value + "," : "") + element.adderBuffer;
                                                                    setModalFormData([...(modalFormData || [])]);
                                                                }}>
                                                                Add Selected
                                                            </Button>
                                                            <Button
                                                                className="m-3"
                                                                style={{ width: "45%" }}
                                                                onClick={(e) => {
                                                                    element.value = (element?.value || "").split(",").filter(e => element.adderBuffer2?.indexOf(e) === -1).join(",");
                                                                    setModalFormData([...(modalFormData || [])]);
                                                                }}>
                                                                Remove Selected
                                                            </Button>
                                                        </div>
                                                        <Form.Select
                                                            multiple={true}
                                                            style={element.style}
                                                            className={element.className}
                                                            isInvalid={!!element.error}
                                                            onChange={
                                                                (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const options = e.target.selectedOptions;
                                                                    // @ts-ignore
                                                                    element.adderBuffer2 = [].slice.call(options).map(o => o.value).join(",");
                                                                    setModalFormData([...(modalFormData || [])]);
                                                                }
                                                            }>
                                                            {
                                                                (element.value || "")
                                                                    .split(",").map(optionValue => <option key={optionValue} value={optionValue}>{(element?.options || []).find(o => o.value === optionValue)?.title || ""}</option>)
                                                            }
                                                        </Form.Select>
                                                    </>
                                                }
                                                {
                                                    element.type === "date" &&
                                                    <Form.Control
                                                        type="date"
                                                        style={element.style}
                                                        className={element.className}
                                                        isInvalid={!!element.error}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                element.value = e.target.value;
                                                                setModalFormData([...(modalFormData || [])]);
                                                            }
                                                        }
                                                        value={element.value}
                                                    />
                                                }
                                                {
                                                    element.type === "time" &&
                                                    <Form.Control
                                                        type="time"
                                                        style={element.style}
                                                        className={element.className}
                                                        isInvalid={!!element.error}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                element.value = e.target.value;
                                                                setModalFormData([...(modalFormData || [])]);
                                                            }
                                                        }
                                                        value={element.value}
                                                    />
                                                }
                                                {
                                                    element.type === "datetime" &&
                                                    <Form.Control
                                                        type="datetime-local"
                                                        style={element.style}
                                                        className={element.className}
                                                        isInvalid={!!element.error}
                                                        onChange={
                                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                element.value = e.target.value;
                                                                setModalFormData([...(modalFormData || [])]);
                                                            }
                                                        }
                                                        value={element.value}
                                                    />
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                            )
                        }
                    </Modal.Body>

                    {
                        (modalSettings?.cancelCallback || modalSettings?.okCallback) &&
                        <Modal.Footer>
                            {
                                modalSettings.cancelCallback &&
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        if (modalSettings.cancelCallback) {
                                            modalSettings.cancelCallback();
                                        }
                                        setModalSettings(null);
                                        setModalFormData(null);
                                    }}>{modalSettings.cancelButtonTitle || "Cancel"}</Button>
                            }
                            {
                                modalSettings.okCallback &&
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        let validData = true;
                                        modalFormData?.forEach(
                                            entry => {
                                                if (entry.required && (!entry.hasOwnProperty("value") || entry.value === null || entry.value === "")) {
                                                    validData = false;
                                                    entry.error = "This is required";
                                                }
                                                else {
                                                    entry.error = "";
                                                }
                                            });

                                        setModalFormData([...(modalFormData || [])]);

                                        if (validData) {
                                            if (modalSettings.okCallback) {
                                                modalSettings.okCallback(modalFormData ? [...modalFormData] : null);
                                            }
                                            setModalSettings(null);
                                            setModalFormData(null);
                                        }
                                        else {
                                        }
                                    }}>{modalSettings.okButtonTitle || "OK"}</Button>
                            }
                        </Modal.Footer>
                    }
                </Modal>
            </GlobalContext.Provider>
        </>
    );
};

export default GlobalContextWrapper;