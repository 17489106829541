export const getLowestDate = (dates: Date[]) => {
    return dates.slice().sort((a, b) => b.getTime() - a.getTime()).at(0);
}

export const getHighestDate = (dates: Date[]) => {
    return dates.slice().sort((a, b) => b.getTime() - a.getTime()).at(-1);
}

export const getArrayOfUniqueDays = (dates: Date[]) => {
    var sortedDates = dates.slice().sort((a, b) => b.getTime() - a.getTime());
    var lastDay = sortedDates[0].getDay();
    var uniqueDays = [sortedDates[0]];
    for (var i = 1; i < sortedDates.length; i++) {
        if (sortedDates[i].getDay() !== lastDay) {
            uniqueDays.push(sortedDates[i]);
            lastDay = sortedDates[i].getDay();
        }
    }
    return uniqueDays;
}