import { CSSProperties, ReactElement } from "react";
import { Table } from "react-bootstrap";
import { DEFAULT_TABLE_RECORD_COUNT, generatePagination } from "utilities/UIUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownWideShort, faArrowUpShortWide, faFilter } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IGlobalContextProps } from "components/GlobalContext";
import moment from "moment";
import { DataType, ISelectOption } from "utilities/FormUtility";
import { camelCaseToProper, numberWithCommas } from "utilities/StringUtility";

import './styles/SortableTable.scss';

export interface IColumnDefinition<T, U> {
    title: string;
    propName: keyof T & string;
    sortable?: boolean;
    customRenderer?: (value: string, row: T) => ReactElement;
    filterCallbackAsync?: () => Promise<U | null>;
}

export interface ISortableTableProps<T, U> {
    className?: string;
    columnDefinitions: IColumnDefinition<T, U>[];
    changePageCallback: (newPage: number) => void;
    page: number;
    totalRecords: number;
    recordCount?: number;
    records: T[];
    rowClickCallback?: (rowData: T) => void;
    rowStyleRenderer?: (rowData: T) => CSSProperties;
    rowClassRenderer?: (rowData: T) => string;
    extraPreRowsRenderer?: () => ReactElement[];
    tools?: ReactElement[];
    filter?: U;
    setFilter?: (newFilter: any) => void;
}

const SortableTable = <T, U>(props: ISortableTableProps<T, U>) => {
    const showFilterAsync = async (e: React.MouseEvent, column: IColumnDefinition<T, U>) => {
        if (props.setFilter && column.filterCallbackAsync) {
            const newFilterData = await column.filterCallbackAsync();

            if (newFilterData !== null) {
                props.changePageCallback(0);
                props.setFilter({ ...newFilterData })
            }
        }
    };

    return (
        <div className={`sortable-table ${props.className}`}>
            {
                props.tools &&
                <div className="tool-panel text-end p-2">
                    {
                        props.tools.map(tool => tool)
                    }
                </div>
            }
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        {
                            props.columnDefinitions.map(
                                (column, index) => {
                                    return (<th
                                        key={`${column.propName as string}-${index}`}
                                        className={`align-top ${!column.sortable ? "" : "sortable"}`}
                                        onClick={() => {
                                            if (props.setFilter && column.sortable) {
                                                // @ts-ignore
                                                const alreadySortingBy = (props?.filter?.sortBys || []).some(s => s.toLowerCase() === column.propName.toLowerCase());
                                                props.setFilter({
                                                    ...(props.filter || {}),
                                                    sortBys: [column.propName],
                                                    // @ts-ignore
                                                    sortDescending: alreadySortingBy ? !props.filter.sortDescending : true
                                                });
                                                console.log("set filter");
                                            }
                                        }}>
                                        {
                                            //@ts-ignore
                                            column.sortable && props.setFilter && props.filter && props.filter?.sortBys && props.filter.sortBys.some(s => s.toLowerCase() === column.propName.toLowerCase()) ?
                                                <>
                                                    {
                                                        //@ts-ignore
                                                        !props.filter.sortDescending ?
                                                            <FontAwesomeIcon icon={faArrowUpShortWide as IconProp} /> :
                                                            <FontAwesomeIcon icon={faArrowDownWideShort as IconProp} />
                                                    }
                                                </> :
                                                <></>
                                        }
                                        {column.title}
                                        {
                                            props.setFilter && column.filterCallbackAsync &&
                                            <span
                                                className="float-end"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    showFilterAsync(e, column);
                                                    return false;
                                                }}><FontAwesomeIcon icon={faFilter as IconProp} /></span>
                                        }
                                    </th>)
                                })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.extraPreRowsRenderer && props.extraPreRowsRenderer()
                    }
                    {
                        (!props.records || props.records.length < 1) &&
                        <tr>
                            <td colSpan={props.columnDefinitions.length}>
                                {

                                }
                                <h4 style={{ width: "100%" }} className="text-center pt-2">No Records</h4>
                            </td>
                        </tr>
                    }
                    {
                        props.records.map(
                            (row, index) =>
                                <tr
                                    key={index}
                                    onClick={() => {
                                        if (props.rowClickCallback) {
                                            props.rowClickCallback(row)
                                        }
                                    }}
                                    className={props.rowClassRenderer ? props.rowClassRenderer(row) : ""}
                                    style={{
                                        ...(props.rowStyleRenderer ? props.rowStyleRenderer(row) : {}),
                                        ...(props.rowClickCallback ? { cursor: "pointer" } : {})
                                    }}>
                                    {
                                        props.columnDefinitions.map(
                                            (column, index) =>
                                                column.customRenderer ?
                                                    column.customRenderer(row[column.propName] as any, row) :
                                                    <td key={index}>{row[column.propName as keyof T] as any}</td>)
                                    }
                                </tr>
                        )
                    }
                </tbody>
            </Table>
            {generatePagination(props.changePageCallback, props.page - 1, props.totalRecords, props.recordCount || DEFAULT_TABLE_RECORD_COUNT)}
            <div className="text-center">
                {
                    props.totalRecords &&
                    <>{((props.page - 1) * (props.recordCount || 0)) + 1} - {props.page * (props.recordCount || 0)} of {numberWithCommas(props.totalRecords)} Records</>
                }
            </div>
        </div>
    )
}

export default SortableTable;

export const createFilterRequestCallback = <U,>(
    title: string,
    propName: keyof U & string,
    globalContext: IGlobalContextProps,
    filter: U,
    value?: string,
    customValueRenderer?: (data: any) => any,
    type?: "text" | "select",
    options?: ISelectOption[]
): () => Promise<U | null> => () => new Promise(
    (resolve, reject) => {
        globalContext.showModal({
            content:
                <>
                </>,
            title: "Update Filter",
            formElements: [
                {
                    key: propName,
                    type: type || "text",
                    title: title,
                    value: value || "",
                    options: options
                }
            ],
            okCallback:
                (data) => {
                    const returnFilter = { ...filter };
                    const returnValue = data?.[0].value || "";

                    // @ts-ignore
                    returnFilter[propName] =
                        customValueRenderer ?
                            customValueRenderer(returnValue) :
                            returnValue;

                    if (data || data === 0) {
                        resolve(returnFilter);
                    }
                    else {
                        reject();
                    }
                },
            cancelCallback: () => { resolve(null); }
        });
    });


const parseFilterFormArrayResult = (value: string, valueType: number | undefined) =>
    (value || "").split(",")
        .map(v => parseFilterFormResult(v, valueType))
        .filter((v: any) => !!v || v === 0);

const parseFilterFormResult = (value: string, valueType: number | undefined) =>
    valueType === DataType.Number ?
        (value === "" ?
            null :
            parseInt(value)) :
        valueType === DataType.Boolean ?
            (value === "" ?
                null :
                value === "true") :
            value;

export const createFilterableArrayColumnDefinition = <T, U>(
    globalContext: IGlobalContextProps,
    propName: keyof T & string,
    filterPropName: keyof U & string,
    sortable: boolean,
    filter: U,
    valueType?: DataType,
    customRenderer?: (value: string, row: T) => ReactElement,
    customName?: string
): IColumnDefinition<T, U> => ({
    propName: propName,
    title: customName ?? camelCaseToProper(propName),
    sortable: sortable,
    //@ts-ignore
    filterCallbackAsync:
        createFilterRequestCallback<T>(
            `Filter ${camelCaseToProper(filterPropName)} By:`,
            // @ts-ignore
            filterPropName,
            globalContext,
            filter,
            // @ts-ignore
            filter[filterPropName]?.join(",") || "",
            (val) => parseFilterFormArrayResult(val, valueType),
        ),
    customRenderer: customRenderer
});

export const createFilterableArraySelectColumnDefinition = <T, U>(
    globalContext: IGlobalContextProps,
    propName: keyof T & string,
    filterPropName: keyof U & string,
    sortable: boolean,
    filter: U,
    options: ISelectOption[],
    valueType?: DataType,
    customRenderer?: (value: string, row: T) => ReactElement,
    customName?: string
): IColumnDefinition<T, U> => ({
    propName: propName,
    title: customName ?? camelCaseToProper(propName),
    sortable: sortable,
    //@ts-ignore
    filterCallbackAsync:
        createFilterRequestCallback<T>(
            `Filter ${camelCaseToProper(filterPropName)} By:`,
            // @ts-ignore
            filterPropName,
            globalContext,
            filter,
            // @ts-ignore
            filter[filterPropName]?.join(",") || "",
            (val) => parseFilterFormArrayResult(val, valueType),
            "select",
            options
        ),
    customRenderer: customRenderer
});

export const createFilterableSelectColumnDefinition = <T, U>(
    globalContext: IGlobalContextProps,
    propName: keyof T & string,
    filterPropName: keyof U & string,
    sortable: boolean,
    filter: U,
    options: ISelectOption[],
    valueType?: DataType,
    customRenderer?: (value: string, row: T) => ReactElement,
    customName?: string
): IColumnDefinition<T, U> => ({
    propName: propName,
    title: customName ?? camelCaseToProper(propName),
    sortable: sortable,
    //@ts-ignore
    filterCallbackAsync:
        createFilterRequestCallback<T>(
            `Filter ${camelCaseToProper(filterPropName)} By:`,
            // @ts-ignore
            filterPropName,
            globalContext,
            filter,
            // @ts-ignore
            filter[filterPropName] || "",
            (val) => parseFilterFormResult(val, valueType),
            "select",
            options
        ),
    customRenderer: customRenderer
});

export const createFilterableDateRangeColumnDefinition = <T, U>(
    globalContext: IGlobalContextProps,
    propName: keyof T & string,
    filterPropName: keyof U & string,
    sortable: boolean,
    filter: U,
    customRenderer?: (value: string, row: T) => ReactElement,
    customName?: string
): IColumnDefinition<T, U> => ({
    propName: propName,
    title: customName ?? camelCaseToProper(propName),
    sortable: sortable,
    //@ts-ignore
    filterCallbackAsync:
        () => new Promise((resolve, reject) => {
            globalContext.showModal({
                content:
                    <></>,
                title: "Update Filter",
                formElements: [
                    {
                        key: filterPropName + "Start",
                        type: "date",
                        title: `${camelCaseToProper(propName)} Start Date`,
                        // @ts-ignore
                        value: filter[filterPropName + "Start"] || moment().startOf('month').format("YYYY-MM-DD")
                    },
                    {
                        key: filterPropName + "End",
                        type: "date",
                        title: `${camelCaseToProper(propName)} End Date`,
                        // @ts-ignore
                        value: filter[filterPropName + "End"] || moment().endOf('month').format("YYYY-MM-DD")
                    }
                ],
                okCallback:
                    (data) => {
                        const returnFilter = { ...filter };
                        const returnValueStart = data?.[0].value || "";
                        const returnValueEnd = data?.[1].value || "";

                        // @ts-ignore
                        returnFilter[filterPropName + "Start"] = returnValueStart;
                        // @ts-ignore
                        returnFilter[filterPropName + "End"] = returnValueEnd;
                        if (data) {
                            resolve(returnFilter);
                        }
                        else {
                            reject();
                        }
                    },
                cancelCallback: () => { resolve(null); }
            });
        }),
    customRenderer: customRenderer
});
export const createFilterableNumberRangeColumnDefinition = <T, U>(
    globalContext: IGlobalContextProps,
    propName: keyof T & string,
    filterPropName: keyof U & string,
    sortable: boolean,
    filter: U,
    customRenderer?: (value: string, row: T) => ReactElement
): IColumnDefinition<T, U> => ({
    propName: propName,
    title: camelCaseToProper(propName),
    sortable: sortable,
    //@ts-ignore
    filterCallbackAsync:
        () => new Promise((resolve, reject) => {
            globalContext.showModal({
                content:
                    <></>,
                title: "Update Filter",
                formElements: [
                    {
                        key: filterPropName + "Min",
                        type: "text",
                        title: `${camelCaseToProper(propName)} Start Date`,
                        // @ts-ignore
                        value: filter["min" + filterPropName] || 0
                    },
                    {
                        key: filterPropName + "Max",
                        type: "text",
                        title: `${camelCaseToProper(propName)} End Date`,
                        // @ts-ignore
                        value: filter["max" + filterPropName] || 1000000
                    }
                ],
                okCallback:
                    (data) => {
                        const returnFilter = { ...filter };
                        const returnValueStart = data?.[0].value || "";
                        const returnValueEnd = data?.[1].value || "";
                        // @ts-ignore
                        returnFilter["min" + filterPropName] = returnValueStart;
                        // @ts-ignore
                        returnFilter["max" + filterPropName] = returnValueEnd;
                        if (data) {
                            resolve(returnFilter);
                        }
                        else {
                            reject();
                        }
                    },
                cancelCallback: () => { resolve(null); }
            });
        }),
    customRenderer: customRenderer
});
