import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "components/GlobalContext";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import ISystemDatum, { ISystemDatumCreateOrUpdate } from "models/sql/ISystemDatum";
import SystemDataService from "services/SystemDataService";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

const SystemDataDetail = () => {
    const globalContext = useContext(GlobalContext);
    const { id }: any = useParams();
    const [entry, setEntry] = useState<ISystemDatum>();
    const navigate = useNavigate();
    const [hasFormDataChanged, setHasFormDataChanged] = useState(false);

    useEffect(
        () => {
            (async () => {
                globalContext.setLoading(true);

                const accessToken = await globalContext.getAccessTokenAsync();
                const result = await SystemDataService.GetAsync(accessToken, id);

                if (result.record) {
                    setEntry(result.record);
                }
                else {
                    globalContext.showErrorMessage("Unable to load System Data");
                }

                globalContext.setLoading(false);
            })();
        }, [id]);

    const submitFormAsync = async () => {
        if (entry) {
            globalContext.setLoading(true);
            const accessToken = await globalContext.getAccessTokenAsync();

            const data: ISystemDatumCreateOrUpdate = {
                systemDataId: entry.systemDataId,
                title: entry.title,
                dataJson: entry.dataJson,
                lastUpdated: entry.lastUpdated,
                updatedBy: entry.updatedBy,
                description: entry.description
            };

            await SystemDataService.CreateOrUpdateAsync(accessToken, data);
            globalContext.setLoading(false);
            navigate("/SystemData");
        }
    };

    if (entry) {
        return (
            <>
                <Row>
                    <Col>
                        <Card className="mb-2 mt-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Global System Data for '{entry.title}'</strong>
                                </Card.Title>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Label>Data</Form.Label>
                                        <AceEditor
                                            height="70vh"
                                            width="100%"
                                            fontSize={14}
                                            mode="javascript"
                                            theme="github"
                                            onChange={(code: any) => {
                                                setEntry({ ...entry, dataJson: code })
                                            }}
                                            name="dataJson"
                                            value={entry.dataJson}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-end">
                    <Col>
                        <Button
                            style={{ width: "100%", height: "100%" }}
                            onClick={
                                () => submitFormAsync()}>Save Changes</Button>
                    </Col>
                </Row>
            </>
        );
    }
    else {
        return (
            <>
                <h1>There was an issue retrieving the data, please try again.</h1>
            </>
        );
    }
}

export default SystemDataDetail;
