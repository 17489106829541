import { GlobalContext } from "components/GlobalContext";
import { ILoggingDeviceDataInitResult } from "models/ILoggingDeviceDataInitResult";
import IAggregateBatchDatum, { IAggregateBatchDataFilters } from "models/sql/IAggregateBatchDatum";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import AggregateBatchDataService from "services/AggregateBatchDataService";
import DeviceLogService from "services/DeviceLogService";
import { formatDate, formatDateTime, formatTime, isoDateTimeStringWithoutTimeZone, roundToDigits } from "utilities/StringUtility";
import moment from 'moment';
import ILoggingDeviceMeasurement from "models/sql/ILoggingDeviceMeasurement";
import { getArrayOfUniqueDays } from "utilities/DateUtilities";
import { Chart } from 'react-chartjs-2';
import ILoggingDeviceDatum, { ILoggingDeviceDatumFilters } from "models/sql/ILoggingDeviceDatum";
import { exportTableToCsv } from "utilities/BrowserUtility";
import IFileBackupRecord from "models/sql/IFileBackupRecord";
import FileBackupRecordService from "services/FileBackupRecordService";
import { ITimeStudyBatchGroup, ITimeStudyDay } from "models/TimeStudyReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

interface IDeviceLogLookupProps {
    baseData?: ILoggingDeviceDataInitResult;
}

const DeviceLogLookup = (props: IDeviceLogLookupProps) => {
    const graphColors = ["#FABE61", "#E36492", "#6163FA", "#5DF0BD", "#40B7F7", "#51FF82", "#E6E645", "#FCB647", "#F53125", "#DAF720", "#FF8A30", "#C927E6", "#26AEFC", "#54F527", "#FABE61", "#E36492", "#6163FA", "#5DF0BD", "#40B7F7", "#51FF82", "#E6E645", "#FCB647", "#F53125", "#DAF720", "#FF8A30", "#C927E6", "#26AEFC", "#54F527"];
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [reportFilter, setReportFilter] = useState<IAggregateBatchDataFilters | undefined>();
    const [extraDataFilter, setExtraDataFilter] = useState<ILoggingDeviceDatumFilters | undefined>(undefined);
    const [extraMeasurements, setExtraMeasurements] = useState<ILoggingDeviceMeasurement[]>([]);
    const [timeStudyData, setTimeStudyData] = useState<ITimeStudyDay[]>();
    const [extraData, setExtraData] = useState<ILoggingDeviceDatum[]>();
    const [fileUploadRecords, setFileUploadRecords] = useState<IFileBackupRecord[]>();
    const [reportType, setReportType] = useState(0);
    const [accessToken, setAccessToken] = useState("");
    const uploadInputRef = useRef<HTMLInputElement>(null);

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);
                const accessTokenResult = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(accessTokenResult);

                var result =
                    await FileBackupRecordService.GetAllAsync(
                        accessTokenResult,
                        0,
                        20,
                        {
                            types: ["DeviceLogData"],
                            sortBys: ["UploadDate"],
                            sortDescending: true
                        });

                if (result.records && result.records.length > 0) {
                    setFileUploadRecords(result.records);
                }

                globalContextRef.current.setLoading(false);
            })();
        }, []);

    useEffect(
        () => {
            (async () => {
                if (accessToken) {
                    globalContext.setLoading(true);
                    var batchDataResult = null;
                    var extraDataResult = null;

                    if (reportFilter) {
                        batchDataResult = await AggregateBatchDataService.GetAllAsync(accessToken, 0, 9999, reportFilter);
                        console.log("Pulled batch data ", batchDataResult.records?.length);
                    }

                    if (extraDataFilter) {
                        extraDataResult = await DeviceLogService.GetAllAsync(accessToken, 0, 999999, extraDataFilter);
                        console.log("Pulled extra data ", extraDataResult.records?.length);
                        setExtraData(extraDataResult.records || []);
                        setExtraMeasurements(extraDataResult.records2 || []);
                    }
                    else {
                        setExtraData([]);
                        setExtraMeasurements([]);
                    }

                    if (reportType === 1) {
                        setTimeStudyData(
                            getTimeStudyData(
                                batchDataResult?.records,
                                batchDataResult?.records2,
                                extraDataResult?.records,
                                extraDataResult?.records2));
                    }
                    else {
                        setTimeStudyData([]);
                    }

                    globalContext.setLoading(false);
                }
            })();
        },
        [reportFilter, extraDataFilter, accessToken]);

    const uploadFileHandlerAsync = async (event: any) => {
        globalContext.showModal({ content: <h1>Uploading, please wait...</h1> });

        const formData = new FormData();
        const selectedFile = event.target.files[0];
        formData.append("file", selectedFile);
        const result = await DeviceLogService.UploadExcelLog(accessToken, formData);

        if (result != null) {

            globalContext.showModal({
                content: <>
                    <h1>Upload completed successfully</h1>
                    <table>
                        <tr>
                            <td><strong>Records found </strong></td>
                            <td>{result.recordCount.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td><strong>Measurements found </strong></td>
                            <td>{result.measurements.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td><strong>Batches created </strong></td>
                            <td>{result.batchCount.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td><strong>Time to complete </strong></td>
                            <td>{result.time}</td>
                        </tr>
                    </table>
                </>
            });
        }
        else {
            globalContext.showModal({
                content: <>There was an issue uploading your file, please contact jeff.goergen@filtrationgroup.com and include a copy of the file you're attempting to upload.</>,
                title: "File not uploaded."
            });
        }

        if (uploadInputRef.current) {
            uploadInputRef.current.value = "";
        }

        return false;
    }

    const showPullRawDataFilters = () => {
        const tempReportFilter = { ...extraDataFilter };
        globalContext.showModal({
            title: `Select filters`,
            cancelButtonTitle: "Close",
            formElements: [
                {
                    key: "dateStart",
                    type: "datetime",
                    title: "Start Time",
                    value: (tempReportFilter?.dateStart || moment().utc().startOf("day").set("hour", 6).toISOString()).slice(0, 16)
                },
                {
                    key: "dateEnd",
                    type: "datetime",
                    title: "End Time",
                    value: (tempReportFilter?.dateEnd || moment().utc().add("day", 1).startOf("day").set("hour", 5).set("minute", 59).toISOString()).slice(0, 16)
                },
                {
                    key: "extraMeasurementIds",
                    type: "adder",
                    title: "Measurements Averaged by 5 Minute Intervals",
                    value: (extraDataFilter?.measurementIds)?.join(","),
                    useFilter: true,
                    options:
                        props.baseData?.measurements
                            .filter(m => m.allowGraphing)
                            .map(
                                m => ({
                                    title:
                                        m.description ?
                                            `${m.description} (${m.name})` :
                                            m.name,
                                    value: m.measurementId.toString()
                                })),
                    style: {
                        width: "100%",
                        height: "140px"
                    }
                }],
            content: <><strong>PLEASE NOTE:</strong> The more specific you are with these filters, the faster your report will generate!</>,
            okButtonTitle: "Pull Data",
            okCallback: (data: any) => {
                if (data[2].value && data[2].value.split(",").length > 0) {
                    const daySpanned = moment(data[1].value).diff(moment(data[0].value), 'day');
                    if (daySpanned < 7 || window.confirm(`Your report spans more than 7 days (${daySpanned}.) It will likely be quite slow to pull and render, are you sure?`)) {
                        setReportType(2);
                        setExtraDataFilter(
                            (data[2].value && data[2].value.split(",")) ?
                                {
                                    dateStart: isoDateTimeStringWithoutTimeZone(data[0].value),
                                    dateEnd: isoDateTimeStringWithoutTimeZone(data[1].value),
                                    measurementIds: data[2].value.split(","),
                                    averageSeconds: 60 * 3 // 3 minute averages. there should never be 2 batches inside of a 3 minute interval
                                } :
                                undefined);
                    }
                }
            }
        })
    };

    const showPullTimeStudyFilters = () => {
        const tempReportFilter = { ...reportFilter };
        globalContext.showModal({
            title: `Select filters`,
            cancelButtonTitle: "Close",
            formElements: [
                {
                    key: "dateStart",
                    type: "datetime",
                    title: "Start Time",
                    value: (tempReportFilter?.dateStart || moment().utc().startOf("day").set("hour", 6).toISOString()).slice(0, 16)
                },
                {
                    key: "dateEnd",
                    type: "datetime",
                    title: "End Time",
                    value: (tempReportFilter?.dateEnd || moment().utc().add("day", 1).startOf("day").set("hour", 5).set("minute", 59).toISOString()).slice(0, 16)
                },
                {
                    key: "extraMeasurementIds",
                    type: "adder",
                    title: "Measurements Averaged by Batch",
                    value: (extraDataFilter?.measurementIds)?.join(","),
                    useFilter: true,
                    options:
                        props.baseData?.measurements
                            .filter(m => m.allowGraphing)
                            .map(
                                m => ({
                                    title: m.description ?
                                        `${m.description} (${m.name})` :
                                        m.name,
                                    value:
                                        m.measurementId.toString()
                                })),
                    style: {
                        width: "100%",
                        height: "140px"
                    }
                }],
            content: <><strong>PLEASE NOTE:</strong> The more specific you are with these filters, the faster your report will generate!</>,
            okButtonTitle: "Pull Data",
            okCallback: (data: any) => {
                const daySpanned = moment(data[1].value).diff(moment(data[0].value), 'day');
                if (daySpanned < 7 || window.confirm(`Your report spans more than 7 days (${daySpanned}.) It will likely be quite slow to pull and render, are you sure?`)) {
                    setReportType(1);
                    const batchOn = props.baseData?.measurements.filter(m => m.aggregateBatches)[0].measurementId || 0;
                    setReportFilter({
                        dateStart: isoDateTimeStringWithoutTimeZone(data[0].value),
                        dateEnd: isoDateTimeStringWithoutTimeZone(data[1].value),
                        measurementIds: [props.baseData?.measurements.filter(m => m.aggregateBatches)[0].measurementId || 0]
                    });

                    setExtraDataFilter(
                        (data[2].value && data[2].value.split(",")) ?
                            {
                                dateStart: isoDateTimeStringWithoutTimeZone(data[0].value),
                                dateEnd: isoDateTimeStringWithoutTimeZone(data[1].value),
                                measurementIds: data[2].value.split(","),
                                averageSeconds: 60
                            } :
                            undefined);
                }
            }
        })
    };

    const getTimeStudyData = (batchData: IAggregateBatchDatum[] | null | undefined, batchMeasurements: ILoggingDeviceMeasurement[] | null | undefined, extraData: ILoggingDeviceDatum[] | null | undefined, extraDataMeasurements: ILoggingDeviceMeasurement[] | null | undefined): ITimeStudyDay[] => {
        let result: ITimeStudyDay[] = [];

        if (batchData && batchData.length > 0 && batchMeasurements && batchMeasurements.length > 0) {
            const uniqueDays = getArrayOfUniqueDays(batchData.map(b => new Date(b.endTime)));

            uniqueDays.forEach(
                day => {
                    const timeStudyDay: ITimeStudyDay = { date: day, batchGroups: [] };

                    batchMeasurements.forEach(
                        batchMeasurement => {
                            const batches = batchData.filter(
                                batch =>
                                    batch.measurementId === batchMeasurement.measurementId &&
                                    moment(batch.endTime).isBetween(moment(day).startOf('day').add(6, "hour"), moment(day).endOf('day').add(6, "hour")));

                            const timeStudyBatchGroup: ITimeStudyBatchGroup = { batches: [], measurement: batchMeasurement };

                            batches.forEach(
                                batch => {
                                    const extraDataInDateRange =
                                        extraData && extraData.length > 0 ?
                                            extraData.filter(ed => moment(ed.time).isBetween(moment(batch.startTime), moment(batch.endTime))) :
                                            [];

                                    timeStudyBatchGroup.batches.push({ batch: batch, extraData: extraDataInDateRange });
                                })

                            timeStudyDay.batchGroups.push(timeStudyBatchGroup);
                        });

                    result.push(timeStudyDay);
                });
        }
        return result;
    }

    const renderRawCharts = () => {
        if (!extraData || extraData.length < 1) {
            return <>No Data</>;
        }

        const uniqueDays = getArrayOfUniqueDays(extraData.map(m => new Date(m.time)));

        return (
            <>
                <h1>Raw Measurement Data By Minute</h1>
                {
                    uniqueDays
                        .sort((a, b) => a.getTime() - b.getTime())
                        .map(
                            day =>
                                extraMeasurements.map(
                                    (em, index) => {
                                        const data = extraData.filter(
                                            d =>
                                                d.measurementId === em.measurementId &&
                                                moment(d.time).isBetween(moment(day).startOf('day').add(6, "hour"), moment(day).endOf('day').add(6, "hour")));

                                        const labels = data.map(d => formatTime(d.time));
                                        const chartData: any = {
                                            labels,
                                            datasets: [
                                                {
                                                    type: 'line' as const,
                                                    label: em.description || em.name,
                                                    backgroundColor: graphColors[index],
                                                    borderColor: graphColors[index],
                                                    fill: false,
                                                    borderWidth: 2,
                                                    data: data.map(d => d.data)
                                                }]
                                        };

                                        return (
                                            <div key={em.measurementId}>
                                                <h2>
                                                    {formatDate(day)} {em.description || em.name}
                                                </h2>

                                                <div className="chart-contaienr">
                                                    <Chart
                                                        type='line'
                                                        data={chartData}
                                                        height="300px"
                                                        options={{
                                                            maintainAspectRatio: false,
                                                            animation: false,
                                                            scales: {
                                                                xAxes: {
                                                                    ticks: {
                                                                        autoSkip: true,
                                                                        maxTicksLimit: 30
                                                                    }
                                                                }
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        );
                                    })

                        )
                }
            </>
        );
    }

    const getShiftIcon = (date: Date) => {
        if (!date) {
            return <></>;
        }

        const hour = (new Date(date)).getHours();

        return (
            <>{
                (hour >= 18 || hour < 6) ?
                    <FontAwesomeIcon icon={faMoon as IconProp} /> :
                    <FontAwesomeIcon icon={faSun as IconProp} />
            }
            </>
        );
    };

    const renderTimeStudy = () =>
        (timeStudyData || [])
            .sort((a, b) => a.date.getTime() - b.date.getTime())
            .map(
                (timeStudyDay, index) =>
                    <div style={{ breakInside: "avoid" }} key={index}>
                        <h1>{moment(timeStudyDay.date).format('dddd')} {formatDate(timeStudyDay.date)}</h1>
                        {
                            timeStudyDay.batchGroups.map(
                                (timeStudybatchGroup, index) => {
                                    const labels = timeStudybatchGroup.batches.map(b => `Batch ${b.batch.batchNumber.toString()}`);
                                    const chartData: any = { labels, datasets: [] };

                                    extraMeasurements.forEach(
                                        (em, index) => {
                                            chartData.datasets.push({
                                                type: 'line' as const,
                                                label: em.description || em.name,
                                                backgroundColor: graphColors[index],
                                                borderColor: graphColors[index],
                                                fill: false,
                                                borderWidth: 2,
                                                data:
                                                    timeStudybatchGroup.batches.map(
                                                        b =>
                                                            b.extraData
                                                                .filter(ed => ed.measurementId === em.measurementId && moment(ed.time).isBetween(moment(b.batch.startTime), moment(b.batch.endTime)))
                                                                .reduce((prev, cur) => (cur.data > prev ? cur.data : prev), 0))
                                            })
                                        });

                                    chartData.datasets.push({
                                        type: 'bar' as const,
                                        label: 'Time Elapsed (Mins.)',
                                        backgroundColor: 'rgb(200, 220, 190)',
                                        borderColor: 'rgb(200, 220, 190)',
                                        borderWidth: 2,
                                        fill: true,
                                        data: timeStudybatchGroup.batches.map(b => Math.round(moment.duration(moment(b.batch.endTime).diff(b.batch.startTime)).asMinutes()))
                                    });

                                    return (
                                        <div key={`batch${index}`}>
                                            <div className="chart-contaienr">
                                                <Chart
                                                    type='bar'
                                                    data={chartData}
                                                    height="300px"
                                                    options={{ maintainAspectRatio: false, animation: false }} />
                                            </div>

                                            <div className="table-container">
                                                <Button
                                                    style={{ width: "100%" }}
                                                    onClick={
                                                        () => {
                                                            exportTableToCsv(
                                                                `timeStudyTable-${index}-${formatDate(timeStudyDay.date).replaceAll("/", "-")}`,
                                                                `timestudy_${timeStudybatchGroup.measurement.description || timeStudybatchGroup.measurement.name}_${formatDate(timeStudyDay.date, false).replaceAll("/", "-")}`.replaceAll(".", "_").replaceAll(" ", "_") + ".csv")
                                                        }
                                                    }>Export Table to CSV</Button>
                                                <Table
                                                    id={`timeStudyTable-${index}-${formatDate(timeStudyDay.date).replaceAll("/", "-")}`}
                                                    striped
                                                    bordered
                                                    hover
                                                    size="sm"
                                                    className="lookup-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Batch #</th>
                                                            <th>Start Time</th>
                                                            <th>End Time</th>
                                                            <th>Elapsed</th>
                                                            {
                                                                extraMeasurements.map((em, index) => <th key={index}>{em.description || em.name}</th>)
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            timeStudybatchGroup.batches.map(
                                                                (timeStudyBatch, index, array) =>
                                                                    <tr key={index}>
                                                                        <td>{getShiftIcon(timeStudyBatch.batch.startTime)} {timeStudyBatch.batch.batchNumber}</td>
                                                                        <td>{formatDateTime(timeStudyBatch.batch.startTime)}</td>
                                                                        <td>{formatDateTime(timeStudyBatch.batch.endTime)}</td>
                                                                        <td>{Math.round(moment.duration(moment(timeStudyBatch.batch.endTime).diff(timeStudyBatch.batch.startTime)).asMinutes())} Mins</td>
                                                                        {
                                                                            extraMeasurements.map(
                                                                                em => {
                                                                                    const data = timeStudyBatch.extraData.filter(ed => ed.measurementId === em.measurementId);

                                                                                    let highest = Number.NEGATIVE_INFINITY;
                                                                                    //let lowest = Number.POSITIVE_INFINITY;
                                                                                    data.forEach(
                                                                                        e => {
                                                                                            if (e.data > highest) {
                                                                                                highest = e.data;
                                                                                            }
                                                                                        });

                                                                                    return <td key={em.measurementId}>{roundToDigits(highest, 2)}</td>
                                                                                })
                                                                        }
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    );
                                }
                            )
                        }
                    </div >
            );

    return (
        <>
            <div className="no-print">
                <Button
                    style={{ width: "100%" }}
                    className="mt-2"
                    onClick={(e) => {
                        if (uploadInputRef.current) {
                            uploadInputRef.current.click();
                        }
                    }}>
                    Upload XLSX Log file
                </Button>
                <input
                    id="fileUpload"
                    className="d-none"
                    type="file"
                    ref={uploadInputRef}
                    onChange={uploadFileHandlerAsync} />
                <Button
                    style={{ width: "100%", height: "60px" }}
                    className="mt-2"
                    onClick={(e) => {
                        showPullTimeStudyFilters();
                    }}>
                    Pull Time Study
                </Button>
                <Button
                    style={{ width: "100%", height: "60px" }}
                    className="mt-2"
                    onClick={(e) => {
                        showPullRawDataFilters();
                    }}>
                    Pull Raw Measurement Data
                </Button>
            </div>

            <Row>
                <Col className="p-3 text-center">
                    {
                        reportFilter &&
                        reportFilter.dateStart &&
                        reportFilter.dateEnd &&
                        reportFilter.measurementIds &&
                        timeStudyData &&
                        timeStudyData.length > 0 &&
                        <>
                            <h1>
                                {formatDateTime(reportFilter.dateStart, true)} to {formatDateTime(reportFilter.dateEnd, true)}
                            </h1>
                            <Button
                                style={{ width: "100%" }}
                                onClick={
                                    () => {
                                        const tableIds =
                                            timeStudyData.flatMap(
                                                day =>
                                                    day.batchGroups.map((g, index) => `timeStudyTable-${index}-${formatDate(day.date).replaceAll("/", "-")}`)
                                            );

                                        exportTableToCsv(
                                            tableIds,
                                            `timestudy_${formatDateTime(reportFilter.dateStart, true).replaceAll("/", "-")}_to_${formatDateTime(reportFilter!.dateEnd, true).replaceAll("/", "-")}`.replaceAll(".", "_").replaceAll(" ", "_") + ".csv")
                                    }
                                }>Export ALL day(s) Table(s) to CSV</Button>
                        </>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        reportFilter &&
                        reportFilter.dateStart &&
                        reportFilter.dateEnd &&
                        reportFilter.measurementIds &&
                        timeStudyData &&
                        timeStudyData.length > 0 &&
                        reportType === 1 &&
                        <>
                            {renderTimeStudy()}
                        </>
                    }

                    {
                        extraDataFilter &&
                        extraData &&
                        extraData.length > 0 &&
                        reportType === 2 &&
                        <>
                            {renderRawCharts()}
                        </>
                    }

                    {
                        reportType === 0 &&
                        <><h1>Recently processed files</h1>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                className="lookup-table">
                                <thead>
                                    <tr>
                                        <th>Upload Time</th>
                                        <th>File name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (fileUploadRecords || [])
                                            .map(
                                                f =>
                                                    <tr key={f.fileName}>
                                                        <td>{formatDate(f.uploadDate)}</td>
                                                        <td>{f.fileName}</td>
                                                    </tr>
                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                    }
                </Col>
            </Row>
        </>
    );
}

export default DeviceLogLookup;
