import { GlobalContext } from "components/GlobalContext";
import ILabRequestTemplate from "models/sql/ILabRequestTemplate";
import { useContext, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LabRequestTemplateService from "services/LabRequestTemplateService";
import { formatDate } from "utilities/StringUtility";
import { generatePagination } from "utilities/UIUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEnvelope, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { copyTextToClipboardAsync, printElement } from "utilities/BrowserUtility";
import { getBaseDomain } from "utilities/MiscUtilities";
import QRCode from "react-qr-code";

const RECORD_COUNT = 20;

const LabRequestDirectoryLookup = () => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const [templates, setTemplates] = useState<ILabRequestTemplate[]>([]);
    const navigate = useNavigate();
    const { page = 1 }: any = useParams();
    const [totalRecords, setTotalRecords] = useState(0);
    const [accessToken, setAccessToken] = useState("");

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);
                const accessToken = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(accessToken);
                const result = await LabRequestTemplateService.GetAllAsync(accessToken, page - 1, RECORD_COUNT);

                if (result.records != null) {
                    setTemplates(result.records);
                    setTotalRecords(result.total);
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load recent Templates");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [page]);

    const changePage = (newPage: number) => {
        navigate(`/FormDirectory/${newPage + 1}`);
    };

    const renderQuickLinks = (form: ILabRequestTemplate, url: string) => {
        return (
            <span className="quick-link-container">
                <span
                    title="Copy Form URL to clipboard"
                    className="quick-link"
                    onClick={(e) => {
                        copyTextToClipboardAsync(url)
                            .then(
                                (result) => {
                                    if (result) {
                                        window.alert("Copied!");
                                    }
                                    else {
                                        console.log("didn't work");
                                    }
                                });
                    }}>
                    <FontAwesomeIcon icon={faCopy as IconProp} />
                </span>
                <span
                    title="Email Form URL"
                    className="quick-link"
                    onClick={async (e) => {
                        globalContext.showModal({
                            content:
                                <></>,
                            title: "Email Form link",
                            formElements: [
                                {
                                    key: "email",
                                    type: "text",
                                    title: `Email Address to send the link to?`,
                                    value: ""
                                }
                            ],
                            okCallback:
                                async (data) => {
                                    var emailAddress = data?.[0].value;

                                    if (emailAddress) {
                                        await LabRequestTemplateService.EmailLinkAsync(
                                            accessToken,
                                            form.labRequestTemplateId,
                                            emailAddress,
                                            url);

                                        window.alert("A link to this form has been emailed to " + emailAddress);
                                    }
                                },
                            cancelCallback: () => { }
                        });
                    }}>
                    <FontAwesomeIcon icon={faEnvelope as IconProp} />
                </span>
                <span
                    title="Print Form URL QR Code"
                    className="quick-link"
                    onClick={(e) => {
                        const el = document.getElementById(`qr${form.formNumber}`);
                        if (el) {
                            el.style.display = "";
                            printElement(el);
                            el.style.display = "none";
                        }
                    }}>
                    <FontAwesomeIcon icon={faQrcode as IconProp} />
                </span>
                <span
                    className="print-qr-code"
                    id={`qr${form.formNumber}`}
                    style={{ display: "none" }} >
                    <span><strong>{form.formNumber}</strong></span><br />
                    <QRCode value={url} />
                </span>
            </span >
        )
    }

    return (
        <>
            <h5><i><strong>Internal links never require access keys!</strong></i></h5>
            <Table
                striped bordered hover size="sm"
                className="lookup-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Form Number</th>
                        <th>Version</th>
                        <th>Internal Link</th>
                        <th>Public Link</th>
                        <th>Public Access Key</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        templates.filter(t => t.active === true).map(
                            t => {
                                return (
                                    <tr
                                        key={t.labRequestTemplateId}
                                        className={!t.active ? "innactive" : ""}
                                        style={{ cursor: "pointer" }}>
                                        <td>{t.title}</td>
                                        <td>{t.formNumber}</td>
                                        <td>{t.version}</td>
                                        <td>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={`${window.location.protocol}//${getBaseDomain()}/Public/Form?form=${t.formNumber}${t.accessKey ? "&ak=" + t.accessKey : ""}`}>
                                                Internal Link
                                            </a>
                                            {
                                                renderQuickLinks(
                                                    t,
                                                    `${window.location.protocol}//${getBaseDomain()}/Public/Form?form=${t.formNumber}${t.accessKey ? "&ak=" + t.accessKey : ""}`)
                                            }
                                        </td>
                                        <td>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={
                                                    t.shortUrl ?
                                                        t.shortUrl :
                                                        `${window.location.protocol}//${getBaseDomain()}/Public/Form?form=${t.formNumber}`
                                                }>
                                                Public Link
                                            </a>
                                            {
                                                renderQuickLinks(
                                                    t,
                                                    t.shortUrl ?
                                                        t.shortUrl :
                                                        `${window.location.protocol}//${getBaseDomain().replace("3000", "5001")}/Public/Form?form=${t.formNumber}`)
                                            }
                                        </td>
                                        <td>{t.accessKey}</td>
                                        <td>{formatDate(new Date(t.lastUpdated))}</td>
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </Table>
            {generatePagination(changePage, page - 1, totalRecords, RECORD_COUNT)}
        </>
    );
}

export default LabRequestDirectoryLookup;
