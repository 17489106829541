import { IPanelAquisitionApproval, IPanelAquisitionCreateOrUpdate } from "models/sql/IPanelAquisition";
import IPanelAquisition from "models/sql/IPanelAquisition";
import APIService from "./APIService";
import IAPIResult from "models/IAPIResult";
import { fetchPostAsync } from "utilities/Fetch";

const SERVICE_PATH = "PanelAquisition";

export default class PanelAquisitionService {
    static async GetAllAsync(accessToken: string, start: number, count: number): Promise<{ total: number, records: IPanelAquisition[] | null, records2: null[] | null }> {
        return await APIService.GetAllAsync<IPanelAquisition, null>(accessToken, SERVICE_PATH, start, count);
    }

    static async GetAsync(accessToken: string, id: number): Promise<{ record: IPanelAquisition | null, record2: null }> {
        return await APIService.GetAsync<IPanelAquisition, null>(accessToken, SERVICE_PATH, id);
    }

    static async DeleteAsync(accessToken: string, id: number) {
        await APIService.DeleteAsync(accessToken, SERVICE_PATH, id);
    }

    static async LookupByPanelIdAsync(accessToken: string, panelId: number): Promise<IPanelAquisition | null> {
        return await APIService.CustomAsync<IPanelAquisition>(accessToken, SERVICE_PATH, "lookupByPanelId", { panelId });
    }

    static async CreateOrUpdateAsync(accessToken: string, entry: IPanelAquisitionCreateOrUpdate): Promise<boolean> {
        return APIService.CreateOrUpdate<IPanelAquisitionCreateOrUpdate>(accessToken, SERVICE_PATH, entry);
    }

    static async DeletePostAsync(accessToken: string, id: number): Promise<boolean> {
        return APIService.DeletePost(accessToken, SERVICE_PATH, id);
    }

    static async ApproveAsync<T>(accessToken: string, entry: IPanelAquisitionApproval): Promise<boolean> {
        const result: IAPIResult<boolean> =
            await fetchPostAsync<IAPIResult<boolean>>(
                `/api/${SERVICE_PATH}/approve`,
                { ...entry },
                accessToken);

        return result.success;
    }
}