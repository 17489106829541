import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext, IModalFormElement } from "components/GlobalContext";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { ILabRequestEntryInitResult } from "models/ILabRequestEntryInitResult";
import ILabRequestEntry, { ILabRequestEntryCreateOrUpdate } from "models/sql/ILabRequestEntry";
import LabRequestEntryService from "services/LabRequestEntryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import QRCode from "react-qr-code";
import { formatDate, limitLength } from "utilities/StringUtility";
import { useBeforeUnload } from "utilities/UIUtilities";
import { getBaseDomain } from "utilities/MiscUtilities";
import { downloadFile } from "utilities/BrowserUtility";
import { addNewLogEntry, generateSubmissionLog, generateSubmissionResults } from "./LabRequestEntryUtilities";
import { ICustomWorkflowData } from "models/sql/ILabRequestTemplate";

interface ILabRequestEntryProps {
    baseData?: ILabRequestEntryInitResult;
}

const LabRequestEntryDetail = (props: ILabRequestEntryProps) => {
    const globalContext = useContext(GlobalContext);
    const globalContextRef = useRef(globalContext);
    globalContextRef.current = globalContext;
    const { id }: any = useParams();
    const [entry, setEntry] = useState<ILabRequestEntry>();
    const [originalEntry, setOriginalEntry] = useState<ILabRequestEntry>();
    const navigate = useNavigate();
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const [formNeedsSaving, setFormNeedsSaving] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [reload, setReload] = useState<Date>(new Date);
    const [workflow, setWorkflow] = useState<ICustomWorkflowData>({
        filters: [
            { title: "Any", value: "" },
            { title: "All Open", value: "0,1,2,3" },
            { title: "Issues", value: "2,3" },
            { title: "New", value: "0" },
            { title: "In Progress", value: "1" },
            { title: "Internal Issue", value: "2" },
            { title: "Exeternal Issue", value: "3" },
            { title: "Complete", value: "4" },
            { title: "Cancelled", value: "5" }
        ],
        statuses: ["New", "In Progress", "Internal Issue", "External Issue", "Complete", "Cancelled"],
        workflows: []
    });
    const priorities = [
        { title: "None", value: "" },
        { title: "High", value: "3" },
        { title: "Medium", value: "2" },
        { title: "Low", value: "1" }
    ];

    useBeforeUnload(
        formNeedsSaving,
        "You have unsaved changes, please save them before leaving the page.");

    useEffect(
        () => {
            (async () => {
                globalContextRef.current.setLoading(true);

                const newAccessToken = await globalContextRef.current.getAccessTokenAsync();
                setAccessToken(newAccessToken);
                const result = await LabRequestEntryService.GetAsync(newAccessToken, id);

                if (result.record) {
                    setEntry(result.record);
                    setOriginalEntry(result.record);

                    if (result.record.labRequestTemplate.customWorkflowDataJson) {
                        const workflowdata = JSON.parse(result.record.labRequestTemplate.customWorkflowDataJson);
                        setWorkflow(workflowdata);
                    }
                }
                else {
                    globalContextRef.current.showErrorMessage("Unable to load Entry data");
                }

                globalContextRef.current.setLoading(false);
            })();
        }, [id, reload]);

    const deleteAsync = async () => {
        if (entry && window.confirm("Are you SURE you want to delete this?")) {
            globalContext.setLoading(true);
            await LabRequestEntryService.DeleteAsync(accessToken, entry.labRequestEntryId);
            globalContext.setLoading(false);
            navigate(`/FormSubmissions/Form/${entry.labRequestTemplate.formNumber}/1`);
        }
    }

    const uploadFileHandlerAsync = async (event: any) => {
        if (entry) {
            globalContext.showModal({ content: "Uploading, please wait..." });

            const formData = new FormData();
            const selectedFile = event.target.files[0];
            formData.append("file", selectedFile);
            const newUrl = await LabRequestEntryService.UploadFileAsync(accessToken, formData);

            if (newUrl) {
                setEntry({ ...entry, attachmentUrls: (entry.attachmentUrls + `${newUrl},`) });
                setFormNeedsSaving(true);
            }
            else {
                window.alert("There was a problem uploading your file, please try again or notify Jeff Goergen");
            }

            globalContext.hideModal();
        }

        if (uploadInputRef.current) {
            uploadInputRef.current.value = "";
        }

        return false;
    }

    const changeStatusAsync = async (newStatus: string, newStatusName: string) => {
        if (!entry) {
            return;
        }

        if (formNeedsSaving) {
            globalContext.showModal({
                title: "Save edits first.",
                content: <>Please save your edits before updating the status.</>
            });
            return;
        }

        let updateEntry: ILabRequestEntry = { ...entry };

        const statusType =
            (workflow && workflow.workflows && workflow.workflows.length > 0) ?
                workflow.workflows[parseInt(newStatus)].statusType :
                parseInt(newStatus);

        const formElements: IModalFormElement[] = [];

        if (statusType === 2) { // internal issue
            formElements.push({
                key: "labNotes",
                type: "textarea",
                title: "Please add some details about the issue to our internal notes",
                value: updateEntry.labNotes,
                style: { width: "100%", height: "200px" }
            });
        }
        else if (statusType === 3 && updateEntry.contactEmail) { // external issue
            formElements.push({
                key: "additionalExternalIssueContent",
                type: "textarea",
                title: `Message to be sent to ${updateEntry.contactEmail} describing the issue`,
                value: updateEntry.additionalExternalIssueContent,
                style: { width: "100%", height: "200px" }
            });
        }
        else if (statusType === 4 && updateEntry.contactEmail) { // close
            formElements.push({
                key: "additionalCompleteContent",
                type: "textarea",
                title: `Additional info to be sent to ${updateEntry.contactEmail} after closing this submission`,
                value: updateEntry.additionalCompleteContent,
                style: { width: "100%", height: "200px" }
            });
        }

        if (workflow && workflow.workflows[parseInt(newStatus)]) {
            if (workflow.workflows[parseInt(newStatus)].requiredFields) {
                workflow.workflows[parseInt(newStatus)].requiredFields?.forEach(
                    field => {
                        formElements.push({
                            // @ts-ignore
                            type: field.type,
                            key: field.title,
                            title: field.title + " (Required)",
                            required: true
                        })
                    });
            }
        }

        globalContext.showModal({
            title: `Change Status`,
            cancelButtonTitle: "Cancel",
            formElements: formElements,
            content: <><strong>Are you sure you want to update the status on this submission?</strong></>,
            size: "lg",
            okButtonTitle: "Change Status",
            cancelCallback: () => { },
            okCallback: (data: any) => {
                if (updateEntry) {
                    if (statusType === 2) {
                        updateEntry.labNotes = data[0].value;

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}' with a message of '${data[0].value}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);

                        data.shift();
                    }
                    else if (statusType === 3 && updateEntry.contactEmail) {
                        updateEntry.additionalExternalIssueContent = data[0].value;

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}' with a message of '${data[0].value}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);

                        data.shift();
                    }
                    else if (statusType === 4 && updateEntry.contactEmail) {
                        updateEntry.additionalCompleteContent = data[0].value;

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}' with a message of '${data[0].value}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);

                        data.shift();
                    }
                    else {
                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated status to '${newStatusName}'`,
                                globalContext.userEmail,
                                statusType,
                                updateEntry);
                    }

                    if (data && data.length > 0) {
                        if (!updateEntry.systemDataJson) {
                            updateEntry.systemDataJson = "{}";
                        }

                        const parsedSystemData = JSON.parse(updateEntry.systemDataJson);
                        let extraData = parsedSystemData?.extraData || {};

                        data.forEach(
                            (entry: any) => {
                                if (entry.value === null || entry.value === "") {
                                }
                                else {
                                    extraData[entry.key] = entry.value;
                                }
                            });

                        updateEntry.systemDataJson = JSON.stringify({ ...parsedSystemData, ...{ extraData } });

                        updateEntry =
                            addNewLogEntry(
                                (new Date()),
                                `${globalContext.userName} updated extra submission data: '${JSON.stringify(extraData)}'`,
                                globalContext.userEmail,
                                100,
                                updateEntry);
                    }

                    updateEntry.status = parseInt(newStatus);
                    saveEntryUpdateAsync(updateEntry);
                }
            }
        });
    };

    const saveEntryUpdateAsync = async (updateEntry: ILabRequestEntry) => {
        if (entry) {
            setFormNeedsSaving(false);
            globalContext.setLoading(true);

            let editData = "";

            if (updateEntry.labNotes !== originalEntry?.labNotes) {
                editData += `Changed the notes to '${updateEntry.labNotes}'`;
            }

            if (updateEntry.contactEmail !== originalEntry?.contactEmail) {
                editData += `${!!editData ? "<br>" : ""}Changed the contact email to '${updateEntry.contactEmail}'`;
            }

            if (updateEntry.assignedTo !== originalEntry?.assignedTo) {
                editData += `${!!editData ? "<br>" : ""}Changed the assigned to '${updateEntry.assignedTo}'${!!editData ? "<br>" : ""}`;
            }

            if (updateEntry.attachmentUrls !== originalEntry?.attachmentUrls) {
                editData += `${!!editData ? "<br>" : ""}Changed the attachments${!!editData ? "<br>" : ""}`;
            }

            if (editData) {
                updateEntry =
                    addNewLogEntry(
                        (new Date()),
                        `${globalContext.userName} saved changes.<br>${editData}`,
                        globalContext.userEmail,
                        101,
                        updateEntry);
            }

            const data: ILabRequestEntryCreateOrUpdate = {
                closeDate: updateEntry.closeDate != null ? updateEntry.closeDate : undefined,
                dataJson: updateEntry.dataJson,
                labRequestEntryId: updateEntry.labRequestEntryId,
                labRequestTemplateId: updateEntry.labRequestTemplateId,
                submissionDate: updateEntry.submissionDate,
                assignedTo: updateEntry.assignedTo,
                labNotes: updateEntry.labNotes,
                status: updateEntry.status,
                contactEmail: updateEntry.contactEmail,
                closedBy: updateEntry.closedBy,
                submissionCode: updateEntry.submissionCode,
                lastStatusContact: updateEntry.lastStatusContact,
                attachmentUrls: updateEntry.attachmentUrls,
                formAttachmentUrls: updateEntry.formAttachmentUrls,
                backfillOrigin: updateEntry.backfillOrigin,
                additionalCompleteContent: updateEntry.additionalCompleteContent,
                additionalExternalIssueContent: updateEntry.additionalExternalIssueContent,
                systemDataJson: updateEntry.systemDataJson,
                formNumber: updateEntry.formNumber,
                privateAttachmentUrls: updateEntry.privateAttachmentUrls,
                backFilledFrom: updateEntry.backFilledFrom,
                priority: updateEntry.priority
            };

            await LabRequestEntryService.CreateOrUpdateAsync(accessToken, data);
            globalContext.setLoading(false);
            setEntry(updateEntry);
            setOriginalEntry(updateEntry);
            setReload(new Date());
        }
    }

    if (entry) {
        let extraData =
            (entry.systemDataJson ?
                JSON.parse(entry.systemDataJson) :
                {})?.extraData || {};

        return (
            <div className={`lab-entry-result`}>
                <Row className="no-print">
                    <Col>
                        <Card className={`mb-2 mt-2 ${formNeedsSaving ? "needs-saving" : ""}`}>
                            <Card.Body>
                                <Card.Title>
                                    <strong>General Info</strong>
                                </Card.Title>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Currently assigned to</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setEntry({ ...entry, assignedTo: e.target.value });
                                                            setFormNeedsSaving(true);
                                                        }
                                                    }
                                                    value={entry.assignedTo}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Submission Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                readOnly={true}
                                                value={entry.submissionCode.trim()}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Contact Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={entry.contactEmail}
                                                onChange={
                                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setEntry({ ...entry, contactEmail: e.target.value });
                                                        setFormNeedsSaving(true);
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Priority</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setEntry({ ...entry, priority: parseInt(e.target.value) });
                                                            setFormNeedsSaving(true);
                                                        }
                                                    }
                                                    value={entry.priority || ""}>
                                                    {
                                                        priorities.map((p, index) => <option key={index} value={p.value}>{p.title}</option>)
                                                    }
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Status</Form.Label>
                                            {
                                                <Form.Select
                                                    value={entry.status}
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            //@ts-ignore
                                                            changeStatusAsync(e.target.value, e.target.selectedOptions[0].innerHTML || "")
                                                        }}>
                                                    {(workflow?.statuses || []).map((s, index) => <option key={index} value={index}>{s}</option>)}
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Submitted</Form.Label>
                                            <Form.Control
                                                type="text"
                                                readOnly={true}
                                                value={formatDate(new Date(entry.submissionDate))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="" >
                                            <Form.Label>Closed</Form.Label>
                                            <Form.Control
                                                type="text"
                                                readOnly={true}
                                                value={entry.closeDate ? formatDate(new Date(entry.closeDate)) : "Still Open"}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {
                                    extraData && Object.keys(extraData).length > 0 &&
                                    <Row className="align-items-end">
                                        {
                                            Object.keys(extraData).map(
                                                (key, index) =>
                                                    <Col key={index}>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>{key}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                readOnly={true}
                                                                value={extraData[key]}
                                                            />
                                                        </Form.Group>
                                                    </Col>)
                                        }
                                    </Row>
                                }

                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Internal Notes</Form.Label>
                                            {
                                                <Form.Control
                                                    as="textarea"
                                                    id="labnotes"
                                                    rows={5}
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setEntry({ ...entry, labNotes: e.target.value });
                                                            setFormNeedsSaving(true);
                                                        }
                                                    }
                                                    value={entry.labNotes || ""}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <h4>
                                                Attached Files:<br />
                                                <small>Click 'Attach file' below to add files</small>
                                            </h4>
                                            <input
                                                id="fileUpload"
                                                className="d-none"
                                                type="file"
                                                ref={uploadInputRef}
                                                onChange={uploadFileHandlerAsync} />

                                            <div className="attachment-container">
                                                {
                                                    entry.attachmentUrls &&
                                                    entry.attachmentUrls.split(",").filter(f => !!f).length > 0 &&
                                                    entry.attachmentUrls.split(",").filter(f => !!f).map(
                                                        (url, index) =>
                                                            <a
                                                                href="#"
                                                                key={index}
                                                                className="attachment"
                                                                target="_blank"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    downloadFile(url.split("-").splice(-1)[0], `///${getBaseDomain()}/api/LabRequestEntry/downloadFile?fileName=${url}`, accessToken);
                                                                    return false;
                                                                }}>
                                                                <FontAwesomeIcon icon={faFileCircleCheck as IconProp} />
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan as IconProp}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        let copy = entry.attachmentUrls?.split(",").filter(e => !!e);
                                                                        if (copy && copy?.length > 0) {
                                                                            copy.splice(index, 1);
                                                                            setEntry({
                                                                                ...entry,
                                                                                attachmentUrls: copy?.join(",") + ","
                                                                            });
                                                                            setFormNeedsSaving(true);
                                                                        }
                                                                        return false;
                                                                    }} />
                                                                <span className="attachment-filename">{limitLength(url.split("-").splice(-1)[0], 20, 6)}</span>
                                                            </a>
                                                    )
                                                }
                                                {
                                                    (entry?.attachmentUrls || "").split(",").filter(f => !!f).length < 1 &&
                                                    <h4 style={{ width: "100%" }} className="pt-2 text-center">No Attachments</h4>
                                                }
                                            </div>

                                            <Button
                                                style={{ width: "100%" }}
                                                className="mt-2"
                                                onClick={(e) => {
                                                    if (entry.contactEmail && !window.confirm("Please note: ALL internally attached files will be sent to " + entry.contactEmail + " upon completion!")) {
                                                        return;
                                                    }

                                                    if (uploadInputRef.current) {
                                                        uploadInputRef.current.click();
                                                    }
                                                }}>
                                                Attach file
                                            </Button>
                                            {entry.contactEmail &&
                                                <strong><i style={{ color: "red" }}><br />All files above will be emailed to {entry.contactEmail} upon completion!</i></strong>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end pb-4">
                                    <Col sm={2}>
                                        <Button
                                            style={{ width: "100%", height: "80px" }}
                                            variant="danger"
                                            onClick={
                                                () => deleteAsync()}>Delete</Button></Col>
                                    <Col>
                                        <Button
                                            style={{ width: "100%", height: "80px" }}
                                            onClick={
                                                async () => {
                                                    await saveEntryUpdateAsync(entry);
                                                    setReload(new Date());
                                                }}>Save Changes</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-end">
                    <Col>
                        <Card className="mb-2" style={{ backgroundColor: "#f5f5f5" }}>
                            <Card.Body>
                                <Card.Title>
                                    <h1>Submitted Data</h1>
                                </Card.Title>
                                {generateSubmissionResults(entry)}
                                <Row>
                                    <Col>
                                        <Form.Group className="mt-3" >
                                            <h4>User Submitted Files:</h4>

                                            <div className="attachment-container">
                                                {
                                                    entry.formAttachmentUrls &&
                                                    entry.formAttachmentUrls.split(",").filter(f => !!f).length > 0 &&
                                                    entry.formAttachmentUrls.split(",").filter(f => !!f).map(
                                                        (url, index) =>
                                                            <a
                                                                href="#"
                                                                key={index}
                                                                className="attachment"
                                                                target="_blank"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    downloadFile(url.split("-").splice(-1)[0], `///${getBaseDomain()}/api/LabRequestEntry/downloadFile?fileName=${url}`, accessToken);
                                                                    return false;
                                                                }}>
                                                                <FontAwesomeIcon icon={faFileCircleCheck as IconProp} />
                                                                <span className="attachment-filename">{limitLength(url.split("-").splice(-1)[0], 20, 6)}</span>
                                                            </a>
                                                    )
                                                }
                                                {
                                                    (entry?.formAttachmentUrls || "").split(",").filter(f => !!f).length < 1 &&
                                                    <h4 style={{ width: "100%" }} className="pt-2 text-center">No Form Attachments</h4>
                                                }
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-end no-print">
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Submission Log</strong><br />
                                </Card.Title>
                                {generateSubmissionLog(entry)}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    entry.backfillOrigin &&
                    <Row className="align-items-end no-print">
                        <Col>
                            <Card className="mb-2">
                                <Card.Body>
                                    <Card.Title>
                                        <a href={`https://${getBaseDomain()}/Public/Form?form=${entry.labRequestTemplate.formNumber}&bf=${entry.submissionCode}`} target="_blank" rel="noreferrer"><strong>Backfilled Form Link</strong></a> <br /><br />
                                        <p>
                                            A 'Backfilled Form' will use the same template as this, but have certain fields pre-filled out with data from this form.
                                        </p>
                                    </Card.Title>
                                    <Row>
                                        <Col className="text-center">
                                            <QRCode value={`https://${getBaseDomain()}/Public/Form?form=${entry.labRequestTemplate.formNumber}&bf=${entry.submissionCode}`} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
    else {
        return (
            <>
                <h1>There was an issue retrieving the entry, please try again.</h1>
            </>
        );
    }
}

export default LabRequestEntryDetail;
