import "./styles/TouchToggle.scss";

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export interface ITouchToggleProps {
    changeCallback: (data: string) => void;
    options: string[];
    default?: string;
}

const TouchToggle = (props: ITouchToggleProps) => {
    const [selected, setSelected] = useState(props.default || props.options[0]);

    useEffect(
        () => {
        }, []);

    return (
        <div className="touch-toggle">
            {
                props.options.map(
                    option =>
                        <Button
                            key={option}
                            className={`toggle-option ${option === selected ? "active" : ""}`}
                            onClick={() => {
                                setSelected(option);
                                props.changeCallback(option);
                            }}>
                            {option}
                        </Button>
                )
            }
        </div>
    );
}

export default TouchToggle;
