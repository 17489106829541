import { GlobalContext } from "components/GlobalContext";
import { useContext, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

const Directory = () => {
  const globalContext = useContext(GlobalContext);
  const globalContextRef = useRef(globalContext);
  globalContextRef.current = globalContext;

  useEffect(() => {
    globalContextRef.current.setSecondaryNav(<></>);
  }, []);

  return (
    <>
      <h1 className="mt-5"><strong>App Directory</strong></h1>
      <div className="d-grid gap-2">
        {
          globalContext.roles.some(r => r === "Web.CCCKiosk" || r === "Web.CCCAquisitionResults" || r === "Web.CCCPanelResults") &&
          <>
            <hr />
            <h2 className="text-center">CCC Panels</h2>
          </>
        }
        {
          globalContext.roles.some(r => r === "Web.CCCKiosk") &&
          <LinkContainer to="/CCCKiosk">
            <Button variant="primary" size="lg" className="mr-1">
              Aquisition Kiosk
            </Button>
          </LinkContainer>
        }
        {
          globalContext.roles.some(r => r === "Web.CCCAquisitionResults") &&
          <LinkContainer to="/CCCPanelAquisitionLookup">
            <Button variant="primary" size="lg" className="mr-1">
              Aquisition Lookup
            </Button>
          </LinkContainer>
        }
        {
          globalContext.roles.some(r => r === "Web.CCCPanelResults") &&
          <LinkContainer to="/CCCPanelLookup">
            <Button variant="primary" size="lg" className="mr-1">
              Panel Lookup
            </Button>
          </LinkContainer>
        }

        {
          globalContext.roles.some(r => r === "Web.EFormWorkflows" || r === "Web.EFormTemplates") &&
          <>
            <hr />
            <h2 className="text-center">Forms</h2>
          </>
        }
        {
          globalContext.roles.some(r => r === "Web.EFormWorkflows") &&
          <>
            <LinkContainer to="/FormDirectory">
              <Button variant="primary" size="lg" className="mr-1">
                Form Directory
              </Button>
            </LinkContainer>
            <LinkContainer to="/FormSubmissions/ChooseForm">
              <Button variant="primary" size="lg" className="mr-1">
                View Submissions
              </Button>
            </LinkContainer>
          </>
        }
        {
          globalContext.roles.some(r => r === "Web.EFormTemplates") &&
          <LinkContainer to="/FormTemplates">
            <Button variant="primary" size="lg" className="mr-1">
              Edit Templates
            </Button>
          </LinkContainer>
        }

        {
          globalContext.roles.some(r => r === "Web.ProductionDeviceLogging") &&
          <>
            <hr />
            <h2 className="text-center">Physical Devices</h2>
            <LinkContainer to="/DeviceLog">
              <Button variant="primary" size="lg" className="mr-1">
                Production Device Logs
              </Button>
            </LinkContainer>
          </>
        }

        {
          globalContext.roles.some(r => r === "Web.PurapikOrders") &&
          <>
            <hr />
            <h2 className="text-center">Purapik / Macola</h2>
            <LinkContainer to="/PurapikOrders">
              <Button variant="primary" size="lg" className="mr-1">
                Uploaded Purapik Orders
              </Button>
            </LinkContainer>
          </>
        }

        {
          (window.location.toString().indexOf("localhost") > -1 || window.location.toString().indexOf("pflits2-pflitsqa.azurewebsites.net") > -1) &&
          <></>
        }

        {
          globalContext.roles.some(r => r === "Web.SystemDataManagement") &&
          <>
            <hr />
            <h2 className="text-center">Global System Data</h2>
            <LinkContainer to="/SystemData">
              <Button variant="primary" size="lg" className="mr-1">
                Manage / View Global System Data
              </Button>
            </LinkContainer>
          </>
        }

        {
          globalContext.roles.some(r => r === "Web.ShortenedUrls") &&
          <>
            <hr />
            <h2 className="text-center">Shortened Urls</h2>
            <LinkContainer to="/ShortenedUrls">
              <Button variant="primary" size="lg" className="mr-1">
                Manage / View Shortened Urls
              </Button>
            </LinkContainer>
          </>
        }
      </div>
    </>
  );
}

export default Directory;
